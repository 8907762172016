import React from "react";

function SelectIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#B3B3B3"
        d="M8 3.813l2.693 2.693a.75.75 0 101.062-1.062L8.53 2.22a.75.75 0 00-1.06 0L4.245 5.444a.75.75 0 101.061 1.062L8 3.813zM11.755 9.494a.75.75 0 00-1.062 0L8 12.187 5.306 9.494a.75.75 0 00-1.061 1.062L7.47 13.78a.75.75 0 001.06 0l3.225-3.224a.75.75 0 000-1.062z"
      ></path>
    </svg>
  );
}

export default SelectIcon;