import { useSelector } from "react-redux";

import Input from "../../components/Input/Input";
import GeneralHeader from "../../components/Header/GeneralHeader";

import HelpHeaderIcon from "../../assets/svg/helpHeaderIcon.svg";
import SearchIcon from "../../assets/svg/searchIcon";
import QuestionMarkIcon from "../../assets/svg/questionMarkcon.svg";

function HelpHeader() {
  const { user } = useSelector((store) => store.auth);
  return (
    <div>
      <div className="mt-[-6rem]">
        <img src={HelpHeaderIcon} alt="" className="w-full" />
      </div>
      <div className="flex flex-col gap-8 px-[2rem] absolute top-0 w-full">
        <GeneralHeader
          title={"Support"}
          icon={QuestionMarkIcon}
          buttonText={user.company.companyName}
        />
        <div>
          <div className="flex flex-col items-center">
            <div className="text-[2rem] text-primary-white font-semibold">
              Welcome! How can we help you?
            </div>
            <div className="text-[1rem] text-primary-white">
              Search in our Help Centre for Quick Answers
            </div>
            <div className="w-[48rem] mt-[1rem] bg-primary-white rounded-full">
              <Input
                iconClass="absolute left-4 text-secondary-gray"
                Icon={SearchIcon}
                placeholder="Search for Questions or Topics"
                inputFieldClass="placeholder:text-primary-gray-light"
                extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpHeader;
