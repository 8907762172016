import React from "react";

export default function AddIcon({ className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.875 6.125h5.25a.875.875 0 010 1.75h-5.25v5.25a.875.875 0 01-1.75 0v-5.25H.875a.875.875 0 110-1.75h5.25V.875a.875.875 0 111.75 0v5.25z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function AddButtonIcon({ className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="38"
      fill="none"
      viewBox="0 0 40 38"
      className={`${className}`}
      {...rest}
    >
      <g filter="url(#filter0_dd_698_7297)">
        <rect width="30" height="30" x="5" y="3" fill="#237078" rx="15"></rect>
        <rect
          width="29"
          height="29"
          x="5.5"
          y="3.5"
          stroke="#F7F7F7"
          rx="14.5"
        ></rect>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M20.875 17.125h5.25a.875.875 0 010 1.75h-5.25v5.25a.875.875 0 01-1.75 0v-5.25h-5.25a.875.875 0 010-1.75h5.25v-5.25a.875.875 0 011.75 0v5.25z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_698_7297"
          width="40"
          height="40"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.235294 0 0 0 0 0.258824 0 0 0 0 0.341176 0 0 0 0.08 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_698_7297"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_698_7297"
            result="effect2_dropShadow_698_7297"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_698_7297"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
