import Button from "../Button/Button";
import LabelValueField from "../LabelValueField/LabelValueField";

export default function PropertyInfoCard({
  name,
  btnText,
  phone,
  email,
  mailingAddress,
}) {
  return (
    <div className=" border-[1px] border-gray shadow-inputShadow rounded-2xl p-2 space-y-2 mb-4">
      <Button
        extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
        innerText={btnText}
      />
      <div className="p-2 flex flex-col gap-2">
        <div className="flex">
          <div className="text-primary-teal font-semibold w-full text-sm">
            {name}
          </div>
          <LabelValueField extraClass="w-full" label="Phone" value={phone} />
          <LabelValueField extraClass="w-full" label="Email" value={email} />
        </div>

        <div className="w-full">
          <LabelValueField
            extraClass="w-full"
            label="Mailing Address"
            value={mailingAddress}
          />
        </div>
      </div>
    </div>
  );
}
