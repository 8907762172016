import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import LetterHistoryCard from "../../components/LetterHistoryCard/LetterHistoryCard";
import axios from "../../utils/helpers/axios";
import Pagination from "../../components/Pagination/Pagination";
import { setCommunityId } from "../../redux/features/communitySlice";

export default function LetterHistory() {
  const { id, pid } = useParams();
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [property, setProperty] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalLetterHistory, setTotalLetterHistory] = useState(0);
  const [letterHistories, setLetterHistories] = useState([]);
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPropertyDetails = async () => {
    try {
      setIsLoadingProperty(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&sortFields=streetName&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        setProperty(data.properties[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingProperty(false);
    }
  };

  const getLetterHistories = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/letterHistories?propertyId=${pid}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1`
      );

      if (data.success) {
        setTotalLetterHistory(data.letterHistoryCount);
        setLetterHistories(data.histories);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && pid) getPropertyDetails();
  }, [id, pid]);

  useEffect(() => {
    if (pid) getLetterHistories();
  }, [pid, rowsPerPage, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Letter History"
        buttonText={community.legalName}
        address={
          isLoadingProperty
            ? " "
            : `${property.streetNumber}, ${property.streetName}, ${property.city}, ${property.state}`
        }
      />
      <div className="relative overflow-auto flex-1 flex flex-col gap-[1.4rem] px-[2rem] py-[1rem]">
        {isLoading && (
          <div className="flex justify-center p-[2rem]">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading &&
          letterHistories.map((letter) => {
            return (
              <LetterHistoryCard
                letter={letter}
                getLetterHistories={getLetterHistories}
              />
            );
          })}

        {!isLoading && letterHistories.length === 0 && (
          <div className="text-primary-gray flex justify-center">
            No Letter History Found
          </div>
        )}
      </div>

      {letterHistories.length !== 0 && (
        <div className="relative bg-white z-10 w-full pt-[1rem] px-[2rem]">
          <div className="flex  gap-[1rem]">
            <Button
              innerText="Download All Letter History"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
            />
            <Button
              innerText="Download All Letters"
              extraClass="shadow-button border-[0.5px] border-gray bg-primary-white  rounded-full text-[0.8rem] text-primary-teal px-4 py-2"
            />
          </div>
          <Pagination
            isStatic={false}
            currentPage={currentPage}
            totalCount={totalLetterHistory}
            pageSize={rowsPerPage}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          />
          <Pagination
            isStatic={true}
            currentPage={rowsPerPage}
            onPageChange={(rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
            }}
          />
        </div>
      )}
    </div>
  );
}
