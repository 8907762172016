import SwitchInputv2 from "../../components/Input/SwitchInputv2";
import capitalizeInitials from "../../utils/helpers/capitalizeInitials";

function Wrapper({
  tableData,
  authorizedActivities = [],
  checkedActivities = [],
  onChange,
  userSelected,
}) {
  const isChecked = (id) => {
    if (authorizedActivities.includes("*")) return true;

    return checkedActivities.includes(id);
  };

  const isDisabled = () => {
    if (!userSelected) return true;

    return authorizedActivities.includes("*");
  };

  const groupedData = tableData.reduce((acc, item) => {
    if (!acc[item.group]) acc[item.group] = [];

    acc[item.group].push(item);
    return acc;
  }, {});

  return (
    <>
      <div className="sticky top-0 bg-white z-10">
        <div className="w-full border-b-[2px] border-gray">
          <div className="flex items-center py-3 px-3">
            <div className="grow w-2/5"></div>
            <div className="grid grid-cols-6">
              {["All", "Create", "Read", "Update", "Delete", "Archive"].map(
                (item) => (
                  <div
                    key={"header_" + item}
                    className="cursor-pointer text-primary-teal min-w-32"
                  >
                    <SwitchInputv2
                      label={item}
                      labelClass="text-primary-green"
                      onChange={onChange}
                      id={"header_" + item}
                      isChecked={isChecked("header_" + item)}
                      // isDisabled={isDisabled()}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {Object.keys(groupedData).map((group, groupIndex) => (
        <div key={groupIndex}>
          <h2 className="text-lg font-bold text-primary-teal border-b-[2px] border-gray py-3 px-3">
            {capitalizeInitials(group, "_").split(" ")[0]} Level Permissions
          </h2>
          {groupedData[group].map((data, index) => (
            <div
              className="w-full border-b-[2px] border-gray"
              key={data.alias + index}
            >
              <div className="flex items-center py-3 px-3">
                <div className="whitespace-nowrap grow text-primary-teal font-medium text-wrap w-2/5">
                  {data.alias}
                </div>
                <div className="grid grid-cols-6">
                  {["CRUDA", "C", "R", "U", "D", "A"].map((item) => {
                    if (data.ops.includes(item) || item === "CRUDA")
                      return (
                        <div
                          key={data.id + "_" + item}
                          className="cursor-pointer text-primary-teal min-w-32"
                        >
                          <SwitchInputv2
                            label={data.options ? item : ""}
                            labelClass="text-primary-green"
                            onChange={onChange}
                            id={data.id + "_" + item}
                            isChecked={isChecked(data.id + "_" + item)}
                            isDisabled={isDisabled()}
                          />
                        </div>
                      );

                    return (
                      <div
                        key={data.id + "_" + item}
                        className="text-primary-teal min-w-32 opacity-[0.32]"
                      >
                        <SwitchInputv2
                          label={data.options ? item : ""}
                          labelClass="text-primary-green"
                          onChange={onChange}
                          id={data.id + "_" + item}
                          isChecked={false}
                          isDisabled={true}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

export default Wrapper;
