import React from "react";

function AttorniesIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M7.992 2a.538.538 0 00-.369.152.487.487 0 00-.148.355v.924c-.445.151-.795.485-.954.908H3.975a.542.542 0 00-.34.113.496.496 0 00-.184.295l-2.418 6.248c-.002.004-.002.009-.003.013a.472.472 0 00-.03.16C1 12.56 2.113 14 3.975 14s2.975-1.44 2.975-2.832a.472.472 0 00-.03-.16c-.001-.004-.001-.009-.003-.013L4.727 5.34h1.794c.218.58.796.999 1.479.999a1.57 1.57 0 001.479-1h1.793l-2.19 5.657c0 .004 0 .009-.002.013a.472.472 0 00-.03.16C9.05 12.56 10.163 14 12.025 14S15 12.56 15 11.168a.472.472 0 00-.03-.16c-.002-.004-.001-.009-.003-.013l-2.42-6.252a.497.497 0 00-.184-.292.542.542 0 00-.338-.112H9.479a1.538 1.538 0 00-.954-.908v-.924a.479.479 0 00-.154-.36.528.528 0 00-.38-.147zM3.975 6.285l1.697 4.384H2.278l1.697-4.384zm8.05 0l1.697 4.384h-3.394l1.697-4.384z"
      ></path>
    </svg>
  );
}

export default AttorniesIcon;