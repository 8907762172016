import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import Header from "../../components/Header/Header";
import Input from "../../components/Input/Input";
import Select from "../../components/Input/SelectInput";
import Button from "../../components/Button/Button";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import CKEditorComponent from "../../components/CKEditor/CKEditor";
import MarginModel from "../../components/MarginModel/MarginModel";
import BasicTooltip from "../../components/Tooltip/EditorTooltip";

import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import PhotoRightArrow from "../../assets/svg/photoRightArrow";
import PhotoLeftArrow from "../../assets/svg/photoLeftArrow";
import MarginIcon from "../../assets/svg/marginIcon.svg";
import UploadFromDekstopIcon from "../../assets/svg/uploadFromDekstopIcon.svg";

function AdminHelp() {
  const imageUploadRef = useRef("");
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const navigate = useNavigate();

  const [margins, setMargins] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });
  const [filePreview, setFilePreview] = useState("");
  const [value, setValue] = useState("");
  const [showMarginModel, setShowMarginModel] = useState(false);
  const [deleteModel, setDeleteModal] = useState(false);
  const [saveChangesModel, setSaveChangesModal] = useState(false);
  const [goToArticleModel, setGoToArticleModel] = useState(false);

  return (
    <div className="w-full">
      <Header title="Support" buttonText={"Master Admin"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 py-4 gap-4"
      >
        <div className="w-full flex gap-4">
          <Input
            iconClass="absolute top-[22%] left-4 text-secondary-gray"
            Icon={SearchIcon}
            placeholder="Quick Search"
            inputFieldClass="placeholder:text-primary-gray"
            onChange={(e) => {}}
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
            outerClass={"w-full"}
          />
          <Select
            iconClass="absolute top-[22%] left-2 text-secondary-gray"
            placeholder="Getting Started"
            inputFieldClass="placeholder:text-primary-gray"
            dropdownData={[]}
            id="company"
            fieldName="companyName"
            value={() => {}}
            handleChange={() => {}}
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-8"
            onHandleInputChange={(e) => {}}
            outerClass={"w-full"}
          />

          <Button
            Icon={AddIcon}
            iconClass="w-[15px] h-[15px"
            extraClass="relative text-primary-white bg-primary-teal py-1 px-4 text-sm rounded-full w-[25%]"
            innerText="Add Article"
            onClick={() => navigate("/help/article/add")}
          />
        </div>

        <div className="flex justify-between items-center">
          <h2 className="text-secondary-gray-light text-[1rem] font-semibold">
            Add new Properties
          </h2>
          <div className="flex items-center gap-2">
            <span
              className="text-[1rem] underline text-primary-teal cursor-pointer"
              onClick={() => setGoToArticleModel(true)}
            >
              Go-To Article
            </span>
            <div className="flex">
              <PhotoLeftArrow
                onClick={() => {}}
                disabled={false}
                style={{ cursor: "pointer" }}
              />
              <PhotoRightArrow
                onClick={() => {}}
                disabled={true}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>

        <div className="relative grow pb-4">
          <div
            className="absolute right-0 p-[0.3rem]"
            style={{ zIndex: "100" }}
          >
            <div className="flex items-center">
              <BasicTooltip
                Icon={
                  <img
                    src={UploadFromDekstopIcon}
                    alt=""
                    onClick={() => {
                      imageUploadRef.current.click();
                    }}
                    className={`"cursor-pointer"`}
                  />
                }
                title="Upload image from computer"
              />
              <BasicTooltip
                Icon={
                  <img
                    src={MarginIcon}
                    alt=""
                    onClick={() => setShowMarginModel(!showMarginModel)}
                    className="cursor-pointer"
                  />
                }
                title="Adjust Margin"
              />
            </div>

            {showMarginModel && (
              <MarginModel
                margins={margins}
                // onChange={handleInputChange}
                setShowMarginModel={setShowMarginModel}
                setMargins={setMargins}
                // letterTemplate={letterTemplate}
                // edit={id}
              />
            )}
          </div>
          <CKEditorComponent
            setValue={setValue}
            value={value}
            filePreview={filePreview}
          />
        </div>

        <div className="flex gap-4 justify-between pb-4">
          <Button
            type="submit"
            innerText="Delete Article"
            extraClass="shadow-button bg-primary-whitepx-4 border-[1px] border-primary-red rounded-full py-2 px-4 text-sm captilize text-primary-red"
            onClick={() => setDeleteModal(true)}
          />
          <div className="flex gap-x-4">
            <Button
              type="submit"
              innerText="Cancel"
              extraClass="shadow-button bg-primary-whitepx-4 border-[1px] border-primary-red rounded-full py-2 px-4 text-sm captilize text-primary-red"
              onClick={() => {}}
            />
            <Button
              innerText={`Save Changes`}
              extraClass="shadow-button bg-primary-teal rounded-full text-sm text-primary-white py-2 px-4 min-w-fit"
              onClick={() => setSaveChangesModal(true)}
            />
          </div>
        </div>
      </div>
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={deleteModel}
        onClose={() => {
          setDeleteModal(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl py-4 px-6 flex flex-col">
          <div className="text-primary-teal text-lg">
            Are you sure you wish to delete this Article?
          </div>
          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => setDeleteModal(false)}
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-white rounded-full py-2 px-4 text-primary-teal"
            />
            <Button
              onClick={() => {}}
              innerText="Delete Article"
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
            />
          </div>
        </div>
      </Modal>
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={saveChangesModel}
        onClose={() => {
          setSaveChangesModal(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl py-4 px-6 flex flex-col gap-4">
          <div className="text-primary-teal text-lg">
            The article has some unsaved changes. Would you like to save them?
          </div>
          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => setSaveChangesModal(false)}
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-white rounded-full py-2 px-4 text-primary-teal"
            />
            <Button
              onClick={() => {}}
              innerText="Save Changes"
              extraClass="shadow-button bg-primary-teal rounded-full text-sm text-primary-white py-2 px-4 min-w-fit"
            />
          </div>
        </div>
      </Modal>
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={goToArticleModel}
        onClose={() => {
          setGoToArticleModel(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl py-4 px-6 flex flex-col gap-4"></div>
      </Modal>
    </div>
  );
}

export default AdminHelp;
