export default function List({ text, Icon, isActive, toggleSidebar }) {
  return (
    <div
      className={`${
        isActive
          ? `shadow-list bg-primary-white text-primary-teal font-400 w-full`
          : "text-primary-white w-full hover:bg-[rgba(246,246,246,0.5)]"
      } rounded-full text-sm py-2 px-4 flex items-center cursor-pointer font-normal text-nowrap`}
    >
      <div className="mr-2">{Icon && <Icon className="w-4 h-4" />}</div>
      <div>{text}</div>
    </div>
  );
}
