import { useNavigate } from "react-router-dom";

import Button from "../../components/Button/Button";

import ProCamLogo from "../../assets/svg/proCamLogo";
import LoginBackgroundImage from "../../assets/images/loginBackgroundImage.png";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="w-full  h-screen flex justify-center items-center relative">
      <ProCamLogo
        onClick={() => navigate("/")}
        className="cursor-pointer w-[150px] h-[80px] xl:w-[180px] xl:h-[100px] 5xl:w-[250px] 5xl:h-[180px] 6xl:w-[300px] 6xl:h-[200px] absolute sm:left-24 sm:-translate-x-2/4 top-5 sm:top-3 xl:left-10 6xl:left-16 xl:translate-x-0 text-primary-teal"
      />
      <div className="absolute bottom-0 left-0 w-full select-none">
        <img src={LoginBackgroundImage} alt="" />
      </div>
      <div className="z-10 text-primary-teal w-full flex flex-col items-center">
        <div className="text-secondary-gray text-[1rem] md:text-[1.5rem] lg:text-[2rem] font-normal">
          Oops !
        </div>
        <div className="text-[3rem] md:text-[4.5rem] lg:text-[6rem] font-bold md:leading-[5rem] lg:leading-[7.25rem]">
          Error 404
        </div>
        <div className="text-secondary-gray text-center text-[1rem] md:text-[1.5rem] lg:text-[2rem] font-light">
          The page you're looking for could not be found.
        </div>
        <Button
          onClick={() => navigate("/")}
          innerText="Back to home"
          extraClass="shadow-button bg-primary-teal w-2/3 md:1/3 lg:w-1/6 mt-5 md:mt-10 p-3 rounded-full text-[.9rem] md:text-[1.1rem] uppercase text-primary-white"
        />
      </div>
    </div>
  );
}
