import React from "react";

function calendarIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#666"
        d="M3 0v1.5h-.75A2.258 2.258 0 000 3.75v13.5a2.258 2.258 0 002.25 2.25h15a2.258 2.258 0 002.25-2.25V3.75a2.258 2.258 0 00-2.25-2.25h-.75V0H15v1.5H4.5V0H3zm-.75 3H3v1.5h1.5V3H15v1.5h1.5V3h.75a.74.74 0 01.75.75V6H1.5V3.75A.74.74 0 012.25 3zM1.5 7.5H18v9.75a.74.74 0 01-.75.75h-15a.74.74 0 01-.75-.75V7.5zm9 3V15H15v-4.5h-4.5zM12 12h1.5v1.5H12V12z"
      ></path>
    </svg>
  );
}

export default calendarIcon;