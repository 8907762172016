import { useEffect, useState } from "react";

const SwitchInput = ({
  className,
  label,
  description,
  descriptionClass,
  labelClass,
  id,
  handleChange = () => {},
  value,
  disabled,
}) => {
  console.log(value, "value");
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    handleChange(id, isChecked);
  }, [isChecked]);

  return (
    <div className={`space-x-3 flex ${className}`}>
      <label
        for={id}
        class="flex flex-col flex-wrap items-center cursor-pointer mb-4 md:mb-0 "
      >
        {/* <span class={`capitalize  my-4 block text-lg 3xl:text-xl ${status?('text-primary-green'):('text-primary-red')} `}>{status?"Active":"Inactive"}</span> */}
        <div class="relative mt-1">
          <input
            onClick={() => setIsChecked(!isChecked)}
            id={id}
            type="checkbox"
            class="hidden"
            checked={isChecked}
            disabled={disabled}
          />
          <div
            class={`toggle__line w-8 h-4 rounded-full shadow-inner ${
              isChecked ? "bg-primary-green" : "bg-primary-gray-light"
            }`}
          ></div>

          <div class="toggle__dot absolute w-3 h-3 bg-white rounded-full shadow inset-y-0 left-0"></div>
        </div>
      </label>
      <div className="">
        <div className={`text-base text-primary-black ${labelClass}`}>
          {label}
        </div>
        <span
          className={`text-sm text-secondary-gray-light  ${descriptionClass}`}
        >
          {description}
        </span>
      </div>
    </div>
  );
};
export default SwitchInput;
