import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useEffect } from "react";

function CKEditorComponent({ value, setValue, filePreview }) {
  useEffect(() => {
    if (filePreview) {
      setValue(
        (prev) =>
          `${prev} <figure class="image"><img style="aspect-ratio:800/824;" src="${filePreview}"></figure>`
      );
    }
  }, [filePreview, setValue]);

  return (
    <CKEditor
      editor={Editor}
      data={value}
      onChange={(event, editor) => {
        setValue(editor.getData());
      }}
    />
  );
}

export default CKEditorComponent;
