import React from "react";

function securityIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
      {...rest}
    >
      <path
        fill="#fff"
        d="M14.75 2.521C12.395 2 11.004 1.26 9.99.721 9.199.301 8.63 0 8 0c-.63 0-1.2.302-1.989.72C4.996 1.26 3.605 2 1.25 2.521L1 2.576v.259c0 9.785 6.797 13.068 6.867 13.1l.14.065.137-.07C8.213 15.893 15 12.296 15 2.835v-.259l-.25-.055zM9.273 10.12H6.727l.577-2.62a1.285 1.285 0 01-.577-1.074c0-.71.57-1.285 1.273-1.285s1.273.575 1.273 1.285c0 .45-.23.845-.577 1.074l.577 2.62z"
      ></path>
    </svg>
  );
}

export default securityIcon;