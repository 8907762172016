import { useEffect, useRef, useState } from "react";

import SelectIcon from "../../assets/svg/selectIcon";
import InfoIcon from "../../assets/svg/infoIcon.svg";
import CustomTooltip from "../Tooltip/Tooltip";
import capitalizeInitials from "../../utils/helpers/capitalizeInitials";

export default function Select({
  isStatic,
  placeholder,
  handleIconClick,
  Icon,
  type,
  name,
  id,
  error,
  label,
  extraClass,
  inputFieldClass,
  iconClass,
  labelClass,
  required,
  info,
  dropdownData,
  outerClass,
  fieldName,
  selector,
  value,
  handleChange,
  setCompany,
  disabled,
  smallHelperText,
  onHandleInputChange = () => {},
  ...rest
}) {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [text, setText] = useState(value);
  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggleDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`${outerClass}`}>
      <div
        className={`flex items-center py-0 font-semibold text-sm ${labelClass}`}
      >
        <span className="mx-1">
          {label} {required ? " *" : ""}
        </span>

        {info && (
          <CustomTooltip title={info} placement={"right"}>
            <img src={InfoIcon} alt="" className="cursor-pointer" />
          </CustomTooltip>
        )}
        <span className="ml-2 font-normal text-xs text-secondary-gray">
          {smallHelperText}
        </span>
      </div>
      <div
        onClick={() => setToggleDropdown(!toggleDropdown)}
        className={`relative flex items-center shadow-inputShadow hover:shadow-inputShadowActive ${extraClass}`}
      >
        <input
          className={`bg-transparent focus:outline-none w-full py-[.4rem] text-sm ${inputFieldClass}`}
          type={type}
          placeholder={placeholder}
          name={name}
          id={id}
          value={isStatic ? text : value}
          {...rest}
          disabled={disabled}
          autocomplete={"off"}
          onChange={onHandleInputChange}
        />
        {toggleDropdown && !disabled ? (
          <div
            className="z-20 absolute p-2 bg-primary-white border-gray border-[0.5px] w-full top-[120%] shadow-dropdown left-0 rounded-[1rem]"
            ref={dropdownRef}
          >
            <ul className="w-full h-fit max-h-[10rem] overflow-auto">
              {dropdownData.map((data) => {
                return (
                  <li className="p-1 w-full">
                    <div
                      onClick={() => {
                        setText(data[fieldName]);
                        handleChange(id, data);
                        setCompany?.(data);
                        setToggleDropdown(!toggleDropdown);
                      }}
                      className=" w-full cursor-pointer hover:bg-primary-teal hover:text-primary-white px-4 py-2 rounded-full truncate"
                    >
                      {fieldName.split(",").map((field) => {
                        if (field === "role") {
                          return data[field] === "company_admin"
                            ? "Company Administrator"
                            : "" + capitalizeInitials(data[field], "_");
                        } else return data[field] + " ";
                      })}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}

        {
          <SelectIcon
            className={`absolute right-3 text-secondary-gray cursor-pointer ${
              toggleDropdown ? "rotate-180" : ""
            }`}
          />
        }
      </div>

      {error && (
        <p className="mt-1.5 pl-1 font-normal text-xs text-primary-red">
          {error}
        </p>
      )}
    </div>
  );
}
