import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.6 2H2.4C1.6265 2 1 2.67125 1 3.5V12.5C1 13.3287 1.6265 14 2.4 14H13.6C14.3735 14 15 13.3287 15 12.5V3.5C15 2.67125 14.3735 2 13.6 2ZM2.4 4.25H5.2V5.75H2.4V4.25ZM9.4 11.75H2.4V10.25H9.4V11.75ZM12.2 8.75H2.4V7.25H12.2V8.75Z"
      fill="white"
    />
  </svg>
);
export default SVGComponent;
