import React from "react";

function DocumentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M3.688 1C2.757 1 2 1.707 2 2.575v10.85C2 14.293 2.757 15 3.688 15h8.624c.93 0 1.688-.707 1.688-1.575V6.6H9.687C8.758 6.6 8 5.893 8 5.025V1H3.687zm5.437.308v3.717c0 .29.252.525.563.525h3.982L9.125 1.308zM5.562 8.35h4.875c.311 0 .563.235.563.525 0 .29-.252.525-.563.525H5.564C5.252 9.4 5 9.165 5 8.875c0-.29.252-.525.563-.525zM5.56 10.8h3.373c.31 0 .563.235.563.525 0 .266-.211.486-.486.52l-.076.005H5.56c-.31 0-.563-.235-.563-.525 0-.266.211-.486.486-.52l.076-.005z"
      ></path>
    </svg>
  );
}

export default DocumentIcon;
