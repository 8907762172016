import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { referenceGuideColumn } from "../../constants/TableColumns/Columns";
import Table from "../../components/Table/Table";
import Header from "../../components/Header/Header";
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";
import PhotoHeader from "../../components/Header/PhotoHeader";
import Basic from "../../components/DocumentCard/ReactDragDropdown";

import AddIcon from "../../assets/svg/addIcon";
import DocumentIcon from "../../assets/svg/documentIcon";
import SearchIcon from "../../assets/svg/searchIcon";

export default function DocumentLibrary() {
  const { id } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [documents, setDocuments] = useState([]);
  const [quickRef, setQuickRef] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingQuickRef, setIsLoadingQuickRef] = useState(false);

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDocuments = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/community/documents?communityId=${id}`
      );

      if (data.success) {
        setDocuments(data.documents);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getQuickReference = async () => {
    try {
      setIsLoadingQuickRef(true);

      const { data } = await axios.get(
        `/api/community/quickReferences?communityId=${id}&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }`
      );

      if (data.success) {
        setQuickRef(data.quickReferences);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingQuickRef(false);
    }
  };

  const uploadDocument = async (file) => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.post("/api/media/uploadURL/document", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        put(data.s3PostPayload, data.mediaId, file);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingUpdate(false);
    }
  };

  function put(url, media_id, file) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", file.type);

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadMedia(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingUpdate(false);
      });
  }

  const ackUploadMedia = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/media/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        addDocument(media_id);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setIsLoadingUpdate(false);
    }
  };

  const addDocument = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/community/document`, {
        mediaId: media_id,
        communityId: id,
      });

      if (data.success) {
        toast.success(data.message);
        getDocuments();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
      getDocuments();
      getQuickReference();
    }
  }, [id]);

  useEffect(() => {
    if (searchQuery.length === 0) getQuickReference();
    if (searchQuery.length > 2) getQuickReference();
  }, [searchQuery]);

  return (
    <div className="w-full">
      <Header title="Document Library" buttonText={community?.legalName} />
      {isLoading && (
        <div className="flex flex-1 justify-center pt-[1rem]">
          <ClipLoader color={"#0080A2"} size={50} />
        </div>
      )}
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col pt-4 gap-4 pl-[1rem] pr-[2rem]"
      >
        {!isLoading && (
          <div className="grid grid-cols-3 gap-[1rem] overflow-auto min-h-[7rem] max-h-[15rem] px-[2rem]">
            <Basic
              uploadDocument={uploadDocument}
              isLoading={isLoadingUpdate}
            />
            {documents.map((document, index) => (
              <DocumentCard
                documentData={document}
                getDocuments={getDocuments}
              />
            ))}
          </div>
        )}
        <PhotoHeader
          Icon={DocumentIcon}
          title="Quick Reference Guide"
          extraClass={"pb-[0.8rem] border-b-2 border-primary-teal w-full"}
        />
        <div className="w-full flex flex-col px-4 gap-4 overflow-auto">
          <div className="w-full flex justify-between gap-[1rem]">
            <div className="w-full">
              <Input
                iconClass="absolute top-[22%] left-4 text-secondary-gray"
                Icon={SearchIcon}
                placeholder="Search Topic"
                inputFieldClass="placeholder:text-secondary-gray"
                outerClass="w-full"
                extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="min-w-fit">
              <Link to={`/community/${id}/quick-reference/add`}>
                <Button
                  Icon={AddIcon}
                  iconClass=" w-[0.8rem] h-[0.8rem]"
                  extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                  innerText="Add New Topic"
                />
              </Link>
            </div>
          </div>

          <div className="overflow-auto flex-1 px-[0.2rem]">
            {isLoadingQuickRef && (
              <div className="flex flex-1 justify-center pt-[1rem]">
                <ClipLoader color={"#0080A2"} size={50} />
              </div>
            )}
            {!isLoadingQuickRef && (
              <Table
                pageSize={1000}
                COLUMNS={referenceGuideColumn}
                tableData={quickRef}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
