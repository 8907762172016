import axios from "axios";
import { api } from "./url";
let token;

const axiosAdmin = axios.create({
  baseURL: api,
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
  },
});

axiosAdmin.interceptors.request.use((req) => {
  token = JSON.parse(localStorage.getItem("adminData")).token;

  if (token) req.headers.Authorization = `Bearer ${token}`;

  return req;
});

axiosAdmin.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAdmin;
