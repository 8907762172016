import React from "react";

function CloseIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="35"
      fill="none"
      viewBox="0 0 34 35"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M17 19.719l-6.89 6.89c-.258.258-.587.387-.985.387s-.727-.129-.984-.387c-.258-.257-.387-.586-.387-.984s.129-.727.387-.984l6.89-6.891-6.89-6.89c-.258-.258-.387-.587-.387-.985s.129-.727.387-.984c.257-.258.586-.387.984-.387s.727.129.984.387L17 15.78l6.89-6.89c.258-.258.587-.387.985-.387s.727.129.984.387c.258.257.387.586.387.984s-.129.727-.387.984l-6.89 6.891 6.89 6.89c.258.258.387.587.387.985s-.129.727-.387.984c-.257.258-.586.387-.984.387s-.727-.129-.984-.387L17 19.72z"
      ></path>
    </svg>
  );
}

export default CloseIcon;