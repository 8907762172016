export const roleDropdown = [
  {
    role: "Company Admin",
    value: "company_admin",
  },
  {
    role: "Community Admin",
    value: "community_admin",
  },
  {
    role: "Property Admin",
    value: "property_admin",
  },
  {
    role: "Inspector",
    value: "inspector",
  },
  {
    role: "Board User",
    value: "board_user",
  },
  {
    role: "Read Only User",
    value: "read_only_user",
  },
];
export const subscriptionTypeDropdown = [
  {
    subscriptionType: "Annual",
    value: "annual",
  },
  {
    subscriptionType: "Monthly",
    value: "monthly",
  },
  {
    subscriptionType: "Management",
    value: "management",
  },
];
export const companyDropdown = [
  {
    text: "Merit Inc",
    value: "merit_inc",
  },
  {
    text: "Kinetic Codes",
    value: "kinetic_codes",
  },
  {
    text: "Zomato. Inc",
    value: "zomato_inc",
  },
];
