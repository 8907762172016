import { Tooltip } from "@mui/material";

function CustomTooltip({ children, title, placement }) {
  return (
    <Tooltip
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            color: "#373737",
            borderRadius: "0.3rem",
            backgroundColor: "#fff",
            border: "2px solid #f6f6f6",
            boxShadow: "0 10px 10px rgba(0, 0, 0, 0.1)",
            fontSize: "12px",
            whiteSpace: "pre-wrap",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
}

export default CustomTooltip;
