import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";

import Header from "../../components/Header/Header";
import ViolationCard from "../../components/ViolationCard/ViolationCard";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";
import { useDispatch } from "react-redux";

function OpenViolation() {
  const { id, pid } = useParams();
  const dispatch = useDispatch();

  const [openViolations, setOpenViolations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOpenViolations = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/job/openViolations?propertyId=${pid}`
      );

      if (data.success) {
        setOpenViolations(data.violations);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (pid) getOpenViolations();
  }, [pid]);

  useEffect(() => {
    if (id) dispatch(setCommunityId(id));
  }, [id]);

  return (
    <div className="w-full">
      <Header title="Current Open Violations" />

      <div className="overflow-auto flex-1  px-2 py-5 space-y-4">
        {isLoading && (
          <div className="flex flex-1 justify-center">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading &&
          openViolations.map((violation) => {
            return <ViolationCard violation={violation} />;
          })}
      </div>
    </div>
  );
}

export default OpenViolation;
