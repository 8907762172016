import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { violationLibraryCommunityColumns } from "../../constants/TableColumns/Columns";
import Pagination from "../../components/Pagination/Pagination";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";
import CommunityViolationTable from "../../components/Table/CommunityViolationTable";

import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import ImportIcon from "../../assets/svg/importIcon";
import ArchiveIcon from "../../assets/svg/archiveIconWhite";

export default function ViolationLibraryCommunity() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const importFromCompanyAdmin = searchParams.get("importFromCompanyAdmin");
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [violations, setViolations] = useState([]);
  const [totalViolations, setTotalViolations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedViolations, setSelectedViolations] = useState([]);
  const [community, setCommunity] = useState({});
  const [filterFields, setFilterFields] = useState({
    filterField: "archieved",
    filterValue: "no",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [isLoadingArchieved, setIsLoadingArchieved] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectHandler = (e, row) => {
    if (e.target.checked) {
      setSelectedViolations([
        ...selectedViolations,
        { id: row._id, title: row.title, content: row.content },
      ]);
    } else {
      setSelectedViolations(
        selectedViolations.filter((item) => item.id !== row._id)
      );
    }
  };

  const onEditHandler = (row) => {
    navigate(`/community/${id}/violation-library/edit/${row._id}`);
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getViolations = async (param) => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/company/violation/${param}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }&${
          filterFields.filterField
            ? "filterFields=" +
              filterFields.filterField +
              "&filterValues=" +
              filterFields.filterValue +
              "&"
            : ""
        }`
      );

      if (data.success) {
        setViolations(data.violations);
        setTotalViolations(data.matchedViolationCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addViolation = async () => {
    try {
      setIsLoadingImport(true);

      const { data } = await axios.post("/api/community/violation", {
        communityId: id,
        violations: selectedViolations.map((violation) => ({
          title: violation.title,
          content: violation.content,
        })),
      });

      if (data.success) {
        toast.success("Selected Violation added successfully");
        navigate(`/community/violation-library/${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingImport(false);
    }
  };

  const archieveViolation = async () => {
    try {
      setIsLoadingArchieved(true);

      const selectedViolationsId = selectedViolations.map(
        (violation) => violation.id
      );

      const { data } = await axios.delete(
        `/api/community/violation?violationIds=${selectedViolationsId.join(
          ","
        )}&communityId=${id}`
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedViolations([]);
        getViolations(`community?communityId=${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchieved(false);
    }
  };

  const unarchievedViolations = async () => {
    try {
      setIsLoadingArchieved(true);

      const selectedViolationsId = selectedViolations.map(
        (violation) => violation.id
      );

      const { data } = await axios.patch(
        `/api/community/violation/unarchieve?violationIds=${selectedViolationsId.join(
          ","
        )}&communityId=${id}`,
        {
          archieved: "no",
        }
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedViolations([]);
        getViolations(`community?communityId=${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchieved(false);
    }
  };

  useEffect(() => {
    if (importFromCompanyAdmin) getViolations("company?");
    else getViolations(`community?communityId=${id}`);
  }, [currentPage, rowsPerPage, importFromCompanyAdmin, filterFields]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      if (importFromCompanyAdmin) getViolations("company?");
      else getViolations(`community?communityId=${id}`);
    }

    if (searchQuery.length > 2) {
      if (importFromCompanyAdmin) getViolations("company?");
      else getViolations(`community?communityId=${id}`);
    }
  }, [searchQuery, importFromCompanyAdmin]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header
        title={
          importFromCompanyAdmin
            ? "Master Violation Library"
            : "Violation Library"
        }
        buttonText={community.legalName}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full flex gap-4">
          <div className=" w-full">
            <Input
              iconClass="absolute left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search Violation"
              onChange={(e) => {
                onChange(e);
              }}
              inputFieldClass="placeholder:text-primary-gray-light"
              extraClass="relative w-full py-[0.1rem] rounded-full px-12 border-gray border-[1px]"
            />
          </div>
          {!importFromCompanyAdmin && (
            <div className="min-w-fit flex gap-4">
              <Link to={`/community/violation-library/add/${id}`}>
                <Button
                  Icon={AddIcon}
                  iconClass="w-[0.8rem] h-[0.8rem]"
                  extraClass="relative text-primary-white bg-primary-teal py-2 px-4 py-[.5rem] text-sm rounded-full"
                  innerText="Add New Violation"
                />
              </Link>
            </div>
          )}
        </div>

        <div className=" overflow-auto grow px-2 min-h-[6rem] flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <CommunityViolationTable
              pageSize={rowsPerPage}
              COLUMNS={violationLibraryCommunityColumns(
                onSelectHandler,
                selectedViolations,
                onEditHandler,
                violations,
                setSelectedViolations,
                importFromCompanyAdmin
              )}
              tableData={violations}
            />
          )}
        </div>
        <div className="flex items-center w-fit">
          {importFromCompanyAdmin ? (
            <Button
              Icon={ImportIcon}
              iconClass=" w-[20px] h-[20px] lg:h-4"
              extraClass="relative text-primary-white bg-primary-teal py-2  px-4 py-[.5rem] text-sm rounded-full"
              innerText="Import Selected"
              onClick={addViolation}
              spinnerLight={true}
              isLoading={isLoadingImport}
              disabled={isLoadingImport || selectedViolations.length === 0}
            />
          ) : (
            <div className="flex gap-[1rem]">
              <Button
                Icon={ArchiveIcon}
                iconClass=" w-[15px] h-[15px]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-xs rounded-full mb-4"
                innerText={
                  filterFields.filterValue === "no"
                    ? "Archive Selected"
                    : "Unarchive Selected"
                }
                spinnerLight={true}
                disabled={isLoadingArchieved || selectedViolations.length === 0}
                isLoading={isLoadingArchieved}
                onClick={() => {
                  if (filterFields.filterValue === "no") archieveViolation();
                  else if (filterFields.filterValue === "yes")
                    unarchievedViolations();
                }}
              />
              <div className="flex items-center px-2 pb-4">
                <SwitchInputv2
                  isChecked={filterFields.filterValue === "yes" ? true : false}
                  onChange={(id, value) => {
                    if (value === "add")
                      setFilterFields({
                        filterField: "archieved",
                        filterValue: "yes",
                      });
                    if (value === "remove")
                      setFilterFields({
                        filterField: "archieved",
                        filterValue: "no",
                      });
                  }}
                />
                <div className="text-primary-gray">Show Archive</div>
              </div>
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalViolations}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
