import { useContext, useEffect, useRef, useState } from "react";

import Button from "../Button/Button";
import { ToggleSidebarContext } from "../Layout/Layout";
import UserProfileModel from "../UserProfileModel/UserProfileModel";
import axiosInstance from "../../utils/helpers/axios";

import MenuIcon from "../../assets/svg/menuIcon";
import ProfileDefaultIcon from "../../assets/svg/profileDefaultIcon.svg";

export default function Header({ title, address, buttonText, userImg }) {
  const { toggleSidebar, setToggleSidebar } = useContext(ToggleSidebarContext);
  const model = useRef(null);

  const [userData, setUserData] = useState({});
  const [filePreview, setFilePreview] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUserInfo = async () => {
    try {
      setIsLoading(true);

      const { data } = await axiosInstance.get("/api/auth/profile");

      if (data.success) {
        setUserData(data.userProfile[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMediaURL = async () => {
    const { data } = await axiosInstance.get(
      `api/media/mediaURL?mediaId=${userData.avatar}`
    );

    if (data.success) {
      setFilePreview(data.media.s3PresignedURL);
    }
  };

  const getCompanyLogoMediaURL = async () => {
    const { data } = await axiosInstance.get(
      `api/media/mediaURL?mediaId=${userData.companyDetails.logo}`
    );

    if (data.success) {
      setCompanyLogo(data.media.s3PresignedURL);
    }
  };

  useEffect(() => {
    if (userImg) getUserInfo();
  }, [userImg]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (model.current && !model.current.contains(event.target)) {
        setOpenModel(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [model]);

  useEffect(() => {
    if (userData.avatar) getMediaURL();
    if (userData.companyDetails?.logo) getCompanyLogoMediaURL();
  }, [userData.avatar, userData.companyDetails?.logo]);

  return (
    <div className="h-[8%] flex-0 relative w-full bg-primary-white px-8">
      <div className="w-full h-full border-b-2 pb-6 pt-4 border-primary-teal flex justify-between items-center ">
        <div className="flex text-primary-teal text-xl">
          <div
            onClick={() => setToggleSidebar(!toggleSidebar)}
            className="bg-primary-teal flex justify-center items-center w-[30px] h-[30px] rounded-full mr-2 cursor-pointer"
          >
            <MenuIcon className="text-primary-white w-[14px]" />
          </div>
          <div className="text-[1.6rem]">{title}</div>

          {address && (
            <div className="ml-3 px-2 border-l-[2px] border-seconday-gray text-primary-gray-light text-base flex items-center">
              {address}
            </div>
          )}
        </div>
        <div className="flex space-x-4">
          <div ref={model} className="relative flex items-center w-12 h-12">
            {userImg && (
              <img
                src={filePreview || ProfileDefaultIcon}
                alt=""
                onClick={() => setOpenModel((prev) => !prev)}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            )}
            {openModel && (
              <UserProfileModel
                setOpenModel={setOpenModel}
                openModel={openModel}
                isLoading={isLoading}
                userData={userData}
                filePreview={filePreview}
                companyLogo={companyLogo}
              />
            )}
          </div>
          <div className="flex items-center">
            {buttonText && (
              <Button
                innerText={buttonText}
                extraClass={`shadow-button bg-primary-white w-fit px-4 border-[1px] border-gray rounded-full py-1 text-[0.9rem] text-primary-teal`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
