import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_dd_846_13464)">
      <rect x={5} y={3} width={40} height={40} rx={20} fill="white" />
      <rect x={5.5} y={3.5} width={39} height={39} rx={19.5} stroke="#F7F7F7" />
      <path
        d="M28.1859 15L28.1859 28.7089L25.2676 25.715L24.4914 26.557L28.7437 30.998L33 26.5569L32.2238 25.7149L29.3016 28.7089V15H28.1859Z"
        fill="#237078"
      />
      <path
        d="M20.5957 21.5602V16.4488L20.9148 16.8807L19.69 17.5286L19.4399 16.5028L21.0873 15.6119H21.7083V21.5602H20.5957ZM19.5865 22.0011V20.9212H22.7434V22.0011H19.5865Z"
        fill="#237078"
      />
      <path
        d="M20.6474 31L20.6129 30.91L22.4415 26.9325L22.545 27.6074C22.4587 27.7994 22.3524 27.9704 22.2259 28.1204C22.1051 28.2703 21.9642 28.4023 21.8032 28.5163C21.648 28.6243 21.487 28.7083 21.3202 28.7683C21.1535 28.8223 20.9867 28.8493 20.8199 28.8493C20.4922 28.8493 20.1903 28.7593 19.9143 28.5793C19.6383 28.3933 19.4169 28.1503 19.2501 27.8504C19.0834 27.5444 19 27.2115 19 26.8515C19 26.4256 19.0949 26.0416 19.2846 25.6996C19.4744 25.3517 19.7303 25.0787 20.0523 24.8807C20.38 24.6768 20.7481 24.5748 21.1563 24.5748C21.4496 24.5748 21.7256 24.6318 21.9844 24.7458C22.2489 24.8538 22.476 25.0067 22.6658 25.2047C22.8613 25.4027 23.0136 25.6307 23.1229 25.8886C23.2379 26.1466 23.2954 26.4196 23.2954 26.7075C23.2954 26.8815 23.2782 27.0615 23.2437 27.2475C23.2149 27.4274 23.166 27.6254 23.097 27.8414C23.028 28.0574 22.9389 28.2943 22.8296 28.5523C22.7261 28.8103 22.5996 29.1042 22.4501 29.4342L21.717 31H20.6474ZM21.0701 27.8414C21.2771 27.8414 21.464 27.7934 21.6307 27.6974C21.7975 27.5954 21.9269 27.4634 22.0189 27.3015C22.1109 27.1395 22.1569 26.9535 22.1569 26.7435C22.1569 26.5275 22.1109 26.3326 22.0189 26.1586C21.9269 25.9846 21.8004 25.8496 21.6393 25.7536C21.4783 25.6517 21.2943 25.6007 21.0873 25.6007C20.8918 25.6007 20.7193 25.6487 20.5698 25.7446C20.4203 25.8406 20.2995 25.9756 20.2075 26.1496C20.1213 26.3176 20.0782 26.5155 20.0782 26.7435C20.0782 26.9355 20.1213 27.1155 20.2075 27.2835C20.2995 27.4514 20.4203 27.5864 20.5698 27.6884C20.7251 27.7904 20.8918 27.8414 21.0701 27.8414Z"
        fill="#237078"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_846_13464"
        x={0}
        y={0}
        width={50}
        height={50}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.235294 0 0 0 0 0.258824 0 0 0 0 0.341176 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_846_13464"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_846_13464"
          result="effect2_dropShadow_846_13464"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_846_13464"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SVGComponent;
