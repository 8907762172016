import React from "react";

function AlphabetSortIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
>
      <path
        fill="#237078"
        d="M1 6.943L3.335.603H4.38l2.318 6.34H5.542L4.26 3.34a6.856 6.856 0 01-.12-.345 24.025 24.025 0 00-.153-.507 41.89 41.89 0 01-.257-.87l.214-.008a24.587 24.587 0 01-.265.878c-.052.163-.103.32-.154.471-.046.151-.089.287-.129.408L2.112 6.943H1zm1.06-1.449l.386-1.023h2.729l.41 1.023H2.061zM1.376 16v-.824l3.345-4.728.265.317H1.6V9.66h4.405v.815l-3.327 4.737-.274-.299h3.695V16H1.376zM11.066 0v13.71l-2.99-2.994-.796.842L11.638 16 16 11.558l-.795-.842-2.995 2.995V0h-1.144z"
      ></path>
    </svg>
  );
}

export default AlphabetSortIcon;