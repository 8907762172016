import React from "react";

function PhotoIcon({ className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <g clipPath="url(#clip0_1385_3942)">
        <path
          fill="#fff"
          d="M1.714 1C.772 1 0 1.788 0 2.75v8.167c0 .961.772 1.75 1.714 1.75h.572v.583c0 .962.772 1.75 1.714 1.75h10.286c.942 0 1.714-.788 1.714-1.75V5.083c0-.961-.772-1.75-1.714-1.75h-.572V2.75C13.714 1.788 12.942 1 12 1H1.714zm0 1.167H12a.57.57 0 01.571.583v6.143L9.701 5.7 7.446 8.383l-3.46-3.851-2.843 3.377V2.75a.57.57 0 01.571-.583zm8 1.166a.576.576 0 00-.571.584c0 .323.254.583.571.583.317 0 .572-.26.572-.583a.576.576 0 00-.572-.584zm4 1.167h.572a.57.57 0 01.571.583v8.167a.57.57 0 01-.571.583H4a.57.57 0 01-.571-.583v-.583H12c.942 0 1.714-.789 1.714-1.75V4.5zm-9.7 1.791L8.691 11.5H1.714a.57.57 0 01-.571-.583V9.704l2.87-3.413zm5.714 1.167l2.843 3.167v.292A.57.57 0 0112 11.5h-1.759L8.223 9.249l1.505-1.791z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1385_3942">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PhotoIcon;
