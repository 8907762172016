import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";

import CheckboxInput from "../Input/CheckboxInput";

import EditIcon from "../../assets/svg/editIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";

const Table = ({ pageSize, tableData = [], COLUMNS, sortable = true }) => {
  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      disableSortRemove: true,
      initialState: {
        pageSize: pageSize,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
  } = tableInstance;

  return (
    <div className="w-full">
      <table
        {...getTableProps()}
        className="w-full -mt-[0.3px] border-collapse border-none"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="w-full border-b-[2px] border-gray px-2 text-[1rem]"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <th
                    className={
                      index === 0
                        ? "whitespace-nowrap text-start rounded-tl-lg py-3 px-3 text-primary-teal font-normal"
                        : index === headerGroup.headers.length - 1
                        ? "whitespace-nowrap text-start rounded-tr-lg py-3 px-3 text-primary-teal font-normal"
                        : "whitespace-nowrap text-start  py-3 px-3 text-primary-teal font-normal"
                    }
                    {...(sortable
                      ? {
                          ...column.getHeaderProps(
                            column.getSortByToggleProps()
                          ),
                        }
                      : { ...column.getHeaderProps() })}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="text-start px-3">
                {row.cells.map((cell, index) => {
                  console.log(cell?.row?.cells[index].column, "cell");

                  return (
                    <td
                      className={
                        index === 0
                          ? " border-b-[0.5px] whitespace-nowrap text-secondary-gray border-gray py-3 px-3 text-sm font-normal "
                          : index === row.cells.length - 1
                          ? " border-b-[0.5px] whitespace-nowrap text-secondary-gray border-gray py-3 px-3 text-sm font-light "
                          : " border-b-[0.5px] whitespace-nowrap text-secondary-gray border-gray py-3 px-3 text-sm font-light"
                      }
                      {...cell.getCellProps()}
                    >
                      {cell?.row?.cells[index].column.id === "action" && (
                        <div>
                          <div className="flex cursor-pointer items-center">
                            <EditIcon className="mr-1" />
                            <span>Edit</span>
                          </div>
                          <div className="text-primary-red flex cursor-pointer items-center">
                            <DeleteIcon className="mr-1" />
                            <span>Delete</span>
                          </div>
                        </div>
                      )}
                      {cell?.row?.cells[index].column.type === "checkbox" && (
                        <div className="flex justify-start mt-1 ml-2 px-4">
                          <CheckboxInput id={row.id} />
                        </div>
                      )}

                      {cell?.row?.cells[index].column.date === true && (
                        <div className="flex justify-start mt-1">
                          <div>
                            <div className="flex items-center">
                              <span>
                                {
                                  cell?.row?.cells[index].row.original.opened[0]
                                    .date
                                }
                              </span>
                            </div>
                            <div className="flex items-center">
                              <span>
                                {
                                  cell?.row?.cells[index].row.original.opened[0]
                                    .time
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {cell?.row.cells[index].column.text !== false && (
                        <div
                          className={`truncate ${
                            cell?.row.cells[index].column.link === true
                              ? "text-primary-teal cursor-pointer"
                              : ""
                          } ${
                            cell?.row.cells[index].column.status === true
                              ? ` `
                              : ""
                          }`}
                        >
                          {cell.render("Cell")}
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
