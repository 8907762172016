import React, { useMemo, useState, useCallback } from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CheckboxInput from "../Input/CheckboxInput";
import EditIcon from "../../assets/svg/editIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";
import HamburgerIcon from "../../assets/images/hamburgerIcon.png";

const Table = ({ pageSize, tableData = [], COLUMNS, sortable = true }) => {
  const [data, setData] = useState(tableData);

  const columns = useMemo(() => COLUMNS, [COLUMNS]);

  const tableInstance = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: { pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      if (!destination) return;

      const updatedData = Array.from(data);
      const [movedItem] = updatedData.splice(source.index, 1);
      updatedData.splice(destination.index, 0, movedItem);

      setData(updatedData);
    },
    [data]
  );

  return (
    <div className="w-full">
      <DragDropContext onDragEnd={onDragEnd}>
        <table
          {...getTableProps()}
          className="w-full -mt-[0.3px] border-collapse border-none"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="border-b-[2px] border-gray px-2 text-[1rem] w-full"
                {...headerGroup.getHeaderGroupProps()}
              >
                <th></th>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      className={
                        index === 0
                          ? "whitespace-nowrap text-start rounded-tl-lg py-3 px-3 text-primary-teal font-normal"
                          : index === headerGroup.headers.length - 1
                          ? "whitespace-nowrap text-start rounded-tr-lg py-3 px-3 text-primary-teal font-normal"
                          : "whitespace-nowrap text-start  py-3 px-3 text-primary-teal font-normal"
                      }
                      {...(sortable
                        ? {
                            ...column.getHeaderProps(
                              column.getSortByToggleProps()
                            ),
                          }
                        : { ...column.getHeaderProps() })}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <Droppable droppableId="droppable-rows">
            {(provided) => (
              <tbody
                {...getTableBodyProps()}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Draggable key={row.id} draggableId={row.id} index={index}>
                      {(provided) => (
                        <tr
                          {...row.getRowProps()}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="text-start px-3"
                        >
                          <td
                            className="border-b-[0.5px] whitespace-nowrap text-secondary-gray border-gray py-3 px-[1rem] text-sm font-normal"
                            {...provided.dragHandleProps}
                          >
                            <img
                              src={HamburgerIcon}
                              alt=""
                              className="w-4 h-4"
                            />
                          </td>

                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                className={
                                  index === 0
                                    ? "border-b-[0.5px] whitespace-nowrap text-secondary-gray border-gray py-3 px-3 text-sm font-normal"
                                    : index === row.cells.length - 1
                                    ? "border-b-[0.5px] whitespace-nowrap text-secondary-gray border-gray py-3 px-3 text-sm font-light"
                                    : "border-b-[0.5px] whitespace-nowrap text-secondary-gray border-gray py-3 px-3 text-sm font-light"
                                }
                                {...cell.getCellProps()}
                              >
                                {cell?.row?.cells[index].column.id ===
                                  "action" && (
                                  <div>
                                    <div className="flex cursor-pointer items-center">
                                      <EditIcon className="mr-1" />
                                      <span>Edit</span>
                                    </div>
                                    <div className="text-primary-red flex cursor-pointer items-center">
                                      <DeleteIcon className="mr-1" />
                                      <span>Delete</span>
                                    </div>
                                  </div>
                                )}
                                {cell?.row?.cells[index].column.type ===
                                  "checkbox" && (
                                  <div className="flex justify-start mt-1 ml-2 px-4">
                                    <CheckboxInput id={row.id} />
                                  </div>
                                )}
                                {cell?.row?.cells[index].column.date ===
                                  true && (
                                  <div className="flex justify-start mt-1">
                                    <div>
                                      <div className="flex items-center">
                                        <span>
                                          {
                                            cell?.row?.cells[index].row.original
                                              .opened[0].date
                                          }
                                        </span>
                                      </div>
                                      <div className="flex items-center">
                                        <span>
                                          {
                                            cell?.row?.cells[index].row.original
                                              .opened[0].time
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {cell?.row.cells[index].column.text !==
                                  false && (
                                  <div
                                    className={`truncate ${
                                      cell?.row.cells[index].column.link ===
                                      true
                                        ? "text-primary-teal cursor-pointer"
                                        : ""
                                    } ${
                                      cell?.row.cells[index].column.status ===
                                      true
                                        ? ` `
                                        : ""
                                    }`}
                                  >
                                    {cell.render("Cell")}
                                  </div>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
};

export default Table;
