import React from "react";

function EditIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M0 12V9.167L8.8.383c.133-.122.28-.216.442-.283a1.38 1.38 0 011.025 0c.166.067.31.167.433.3l.917.933c.133.123.23.267.291.434a1.444 1.444 0 010 1.008 1.25 1.25 0 01-.291.442L2.833 12H0zm9.733-8.8l.934-.933-.934-.934-.933.934.933.933z"
      ></path>
    </svg>
  );
}

export default EditIcon;