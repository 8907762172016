import React from "react";

function InfoIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#B3B3B3"
        fillRule="evenodd"
        d="M8 15A7 7 0 108 1a7 7 0 000 14zm-.875-6.923a.879.879 0 01.878-.96 1.28 1.28 0 00.92-.382A1.305 1.305 0 107.077 4.89a1.25 1.25 0 00-.382.914.879.879 0 11-1.757.017 3.007 3.007 0 01.896-2.174 3.069 3.069 0 012.164-.897 3.062 3.062 0 01.813 6.018.875.875 0 01-1.686-.33v-.36zm-.438 3.86a1.312 1.312 0 102.625 0 1.312 1.312 0 00-2.624 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InfoIcon;