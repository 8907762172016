import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "../../utils/helpers/axios";
import { login } from "../../redux/features/auth-slice";
import { login as masterLogin } from "../../redux/features/masterAuthSlice";

import LoginBackgroundImage from "../../assets/images/loginBackgroundImage.png";
import ProCamLogo from "../../assets/svg/proCamLogo";

export default function Login({ url, adminLogin }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const loginAPI = async (email, password) => {
    try {
      setIsLoading(true);

      const { data } = await axios.post(url, {
        email: email,
        password: password,
      });

      if (data.success) {
        const user = data.userInfo;
        const token = data.token;

        if (adminLogin) dispatch(masterLogin({ token, user }));
        else dispatch(login({ token: token, user: user }));
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err.response?.data?.message
          : "Something went wrong, please try again later"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-[100%] flex flex-col sm:space-y-0 sm:flex-row justify-center items-center sm:relative sm:px-5 sm:py-3">
      <ProCamLogo
        onClick={() => navigate("/")}
        className="cursor-pointer w-[150px] h-[80px] xl:w-[180px] xl:h-[100px] 5xl:w-[250px] 5xl:h-[180px] 6xl:w-[250px] 6xl:h-[250px] absolute sm:left-24 sm:-translate-x-2/4 top-2 sm:top-2 xl:left-10 6xl:left-16 xl:translate-x-0 text-primary-teal"
      />
      <div className="absolute bottom-0 left-0 w-full select-none">
        <img src={LoginBackgroundImage} alt="" />
      </div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
          password: Yup.string().required("Password is required"),
        })}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const { email, password } = values;
          loginAPI(email, password);
          setSubmitting(false);
          resetForm();
        }}
      >
        {(formik) => {
          return (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <div className="z-10 sm:mt-10 w-[90%] xs:w-[80%] sm:w-[45%] lg:w-[35%] 2xl:w-[30%] 5xl:w-[25%] 6xl:w-[28%] p-5 sm:py-7 xl:p-10 6xl:p-12 h-fit bg-gradient-primary rounded-2xl flex flex-col items-center space-y-5 5xl:space-y-8 6xl:space-y-10">
                <div className="text-center">
                  <div className="text-white text-[1.5rem] sm:text-[1.7rem] xl:text-[2rem] 5xl:text-[2rem] 6xl:text-[3rem]">
                    Welcome Back !
                  </div>
                  <div className="text-white text-[0.8rem] sm:text-[1.2rem] xl:text-[1.25rem] 5xl:text-[1.75rem] 6xl:text-[2.5rem]">
                    {adminLogin ? "Admin Login" : "Login"}
                  </div>
                </div>
                <form
                  className="w-full flex flex-col space-y-4 5xl:space-y-8 6xl:space-y-8"
                  onSubmit={formik.handleSubmit}
                >
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    error={formik.touched.email && formik.errors.email}
                    placeholder="Username"
                    extraClass="w-full"
                    inputFieldClass="w-full px-5 border-white border-[1px] rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-7 text-white text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] placeholder:text-white placeholder:font-light font-light "
                    {...formik.getFieldProps("email")}
                  />
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    error={formik.touched.password && formik.errors.password}
                    placeholder="Password"
                    extraClass="w-full"
                    inputFieldClass="w-full px-5 border-white border-[1px] rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-7 text-white text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] placeholder:text-white placeholder:font-light font-light "
                    {...formik.getFieldProps("password")}
                  />
                  <div className="w-full flex justify-center">
                    <Button
                      type="submit"
                      innerText="Login"
                      isLoading={isLoading}
                      disabled={isLoading}
                      extraClass="shadow-button bg-primary-white w-full rounded-full py-2 sm:py-3.5 xl:py-4  6xl:py-5 text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] uppercase text-primary-teal"
                    />
                  </div>
                </form>

                <Link
                  to={`/forgot-password/${
                    adminLogin ? "master-admin" : "user"
                  }`}
                  className="text-sm xl:text-base 5xl:text-2xl 6xl:text-3xl text-white cursor-pointer hover:underline font-light"
                >
                  Forgot Password ?
                </Link>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
