import React from "react";

function MenuIcon({className, ...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M2.167 5.418v2.167h21.667V5.418H2.167zm0 6.5v2.167h21.667v-2.167H2.167zm0 6.5v2.167h21.667v-2.167H2.167z"
      ></path>
    </svg>
  );
}

export default MenuIcon;