import { useEffect, useState } from "react";

import Select from "../../components/Input/SelectInput";
import LabelValueField from "../../components/LabelValueField/LabelValueField";
import PhotoViewCard from "../../components/PhotoViewCard/PhotoViewCard";
import Table from "../../components/Table/Table";
import { reviewJobColumn } from "../../constants/TableColumns/Columns";
import PhotoHeader from "../../components/Header/PhotoHeader";
import ReactMultiCarousal from "../../components/ReactMultiCarousal/ReactMultiCarousal";

import PhotoIcon from "../../assets/svg/photoIcon";

function Job({
  propertyDetails,
  propertyJobViolations,
  propertySelectedPhotos,
  propertySelectedLetter,
  letterLevelDropdown,
  setReviewJobUpdate,
  reviewJobUpdate,
  deletePhotoId,
  setDeletePhotoId,
}) {
  const [letterLevel, setLetterLevel] = useState({});

  const allIds = propertyJobViolations.map((violation) => violation._id);

  const onApprovHandler = (e, row) => {
    if (!reviewJobUpdate.approveJobViolations.includes(row._id))
      setReviewJobUpdate((prev) => ({
        ...prev,
        approveJobViolations: [...prev.approveJobViolations, row._id],
      }));

    setReviewJobUpdate((prev) => ({
      ...prev,
      rejectJobViolations: prev.rejectJobViolations.filter(
        (id) => id !== row._id
      ),
    }));
  };

  const onRejectHandler = (e, row) => {
    if (!reviewJobUpdate.rejectJobViolations.includes(row._id))
      setReviewJobUpdate((prev) => ({
        ...prev,
        rejectJobViolations: [...prev.rejectJobViolations, row._id],
      }));

    setReviewJobUpdate((prev) => ({
      ...prev,
      approveJobViolations: prev.approveJobViolations.filter(
        (id) => id !== row._id
      ),
    }));
  };

  const onAllClickHandler = (reviewStatus) => {
    if (reviewStatus === "approv")
      setReviewJobUpdate((prev) => ({
        ...prev,
        rejectJobViolations: prev.rejectJobViolations.filter(
          (id) => !allIds.includes(id)
        ),
        approveJobViolations: [...prev.approveJobViolations, ...allIds],
      }));
    else if (reviewStatus === "reject")
      setReviewJobUpdate((prev) => ({
        ...prev,
        rejectJobViolations: [...prev.rejectJobViolations, ...allIds],
        approveJobViolations: prev.approveJobViolations.filter(
          (id) => !allIds.includes(id)
        ),
      }));
  };

  const editLevelHandler = (id, data) => {
    setLetterLevel(data);
    setReviewJobUpdate((prev) => {
      const existingIndex = prev.updateLetterTemplates.findIndex(
        (template) =>
          template.jobPropertyLetterId === propertySelectedLetter._id
      );

      const updatedTemplates =
        existingIndex !== -1
          ? prev.updateLetterTemplates.map((template, index) =>
              index === existingIndex
                ? { ...template, letterTemplateId: data._id }
                : template
            )
          : [
              ...prev.updateLetterTemplates,
              {
                jobPropertyLetterId: propertySelectedLetter._id,
                letterTemplateId: data._id,
              },
            ];

      return { ...prev, updateLetterTemplates: updatedTemplates };
    });
  };

  useEffect(() => {
    setLetterLevel(
      letterLevelDropdown.find(
        (letter) => letter._id === propertySelectedLetter?.letterTemplate._id
      )
    );
  }, [propertySelectedLetter?.letterTemplate]);

  return (
    <div className="border-b-2 border-primary-teal">
      <div className="flex justify-center border-[1px] my-4 border-gray rounded-[10px] shadow-md p-4">
        <div className="w-full flex flex-col">
          <div className="p-4">
            <LabelValueField
              label="Property Address"
              value={
                propertyDetails?.streetNumber +
                ", " +
                propertyDetails?.streetName
              }
            />
            <div className="flex mt-4">
              <LabelValueField
                label="Letter Level"
                value={letterLevel?.levelContent?.title}
              />
              <Select
                type="select"
                dropdownData={letterLevelDropdown}
                placeholder="Edit Level"
                inputFieldClass="px-4 w-full"
                extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
                fieldName={"levelTitle"}
                handleChange={editLevelHandler}
              />
            </div>
          </div>
          <div className="p-4">
            <Table
              pageSize={10}
              COLUMNS={reviewJobColumn(
                onApprovHandler,
                onRejectHandler,
                reviewJobUpdate,
                onAllClickHandler,
                allIds
              )}
              tableData={propertyJobViolations}
            />
          </div>
          {propertySelectedPhotos.length > 0 && (
            <div className="w-full relative ">
              <PhotoHeader Icon={PhotoIcon} title="Photos" />
              <ReactMultiCarousal>
                {propertySelectedPhotos
                  .filter((photo) => !deletePhotoId.includes(photo._id))
                  .map((photo) => {
                    return (
                      <PhotoViewCard
                        photoHistory={photo}
                        deletePhoto={(id) =>
                          setDeletePhotoId((prev) => [...prev, id])
                        }
                      />
                    );
                  })}
              </ReactMultiCarousal>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Job;
