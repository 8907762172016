import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import Button from "../Button/Button";

import ProfileDashBoardSmallIcon from "../../assets/svg/profileDashBoardSmallIcon.svg";
import ProfileDefaultSmallIcon from "../../assets/svg/profileDeafultSmallIcon.svg";
import { capitalizeInitialsv3 } from "../../utils/helpers/capitalizeInitials";

function UserProfileModel({
  setOpenModel,
  openModel,
  isLoading,
  userData,
  filePreview,
  companyLogo,
}) {
  const navigate = useNavigate();

  return (
    <div className="w-[30rem] absolute right-0 top-[3rem] z-10 rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg p-2 ">
      {isLoading && (
        <div className="flex flex-1 justify-center p-[2rem]">
          <ClipLoader color={"#0080A2"} size={50} />
        </div>
      )}
      {!isLoading && (
        <>
          <div>
            <div className="h-[8rem] w-full rounded-[1rem]  relative mb-1">
              <img
                src={companyLogo || ProfileDashBoardSmallIcon}
                alt="Default Dashboard"
                style={{
                  objectFit: "fit",
                  width: "100%",
                  height: "100%",
                  borderRadius: "1rem",
                }}
              />

              <div className="h-[6.4rem] w-[6.4rem] rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg absolute left-4 bottom-0 translate-y-1/2">
                <img
                  src={filePreview || ProfileDefaultSmallIcon}
                  alt="Default Profile"
                  style={{
                    objectFit: "fit",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                  }}
                />
              </div>
            </div>

            <div className="ml-[8rem]">
              <div className="text-[1.2rem] text-primary-teal font-semibold">
                {userData.firstName + " " + userData.lastName}
              </div>
              <div className="text-primary-gray-light font-semibold">
                {userData.role === "company_admin"
                  ? "COMPANY ADMINISTRATOR"
                  : capitalizeInitialsv3(userData.role, "_")}
              </div>
            </div>
          </div>
          <div className="mt-[1rem] flex w-full justify-end">
            <Button
              extraClass="relative text-primary-white bg-primary-teal py-1 px-4 text-md rounded-full"
              innerText="Edit Profile"
              onClick={() => {
                setOpenModel(false);
                navigate("/company/user-profile");
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default UserProfileModel;
