export const convertFileImageIntoBase64 = (file) => {
  return new Promise((res, rej) => {
    const fileReaderObj = new FileReader();

    fileReaderObj.onload = (e) => {
      let base64 = e.target.result;
      // base64 = `data:image/png;base64,${base64}`;
      res(base64);
    };
    fileReaderObj.onerror = (err) => {
      rej(err);
    };

    fileReaderObj.readAsDataURL(file);
  });
};
