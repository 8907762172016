import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { UserColumnsCommunity } from "../../constants/TableColumns/Columns";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";

import SearchIcon from "../../assets/svg/searchIcon";
import AddIcon from "../../assets/svg/addIcon";
import { useDispatch } from "react-redux";

export default function UserCommunity() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [community, setCommunity] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/api/company/users?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&filterFields=communitiesAllowed&filterValues=${id}&sortFields=updatedAt&sortOrders=-1&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }`
      );

      if (data.success) {
        setUsers(data.users);
        setTotalUsers(data.matchedUserCount);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (searchQuery.length === 0) getUsers();
    if (searchQuery.length > 2) getUsers();
  }, [searchQuery]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header title="Users" buttonText={community.legalName} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full flex justify-between gap-[1rem]">
          <div className="w-full">
            <Input
              iconClass="absolute top-[22%] left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search User by Name or Email"
              inputFieldClass="placeholder:text-secondary-gray"
              outerClass="w-full"
              extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="min-w-fit">
            <Link to={`/community/${id}/user/add`}>
              <Button
                Icon={AddIcon}
                iconClass=" w-[0.8rem] h-[0.8rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Add New User"
              />
            </Link>
          </div>
        </div>

        <div className="overflow-auto min-h-[6rem] px-2 flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={UserColumnsCommunity(id)}
              tableData={users}
            />
          )}
          {!isLoading && users.length === 0 && (
            <div className="flex justify-center text-primary-gray">
              No User found
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalUsers}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />

        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowPerPage) => {
            setRowsPerPage(rowPerPage);
          }}
        />
      </div>
    </div>
  );
}
