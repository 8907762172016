import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={45}
    height={50}
    viewBox="0 0 45 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_dd_1202_7427)">
      <rect x={5} y={3} width={40} height={40} rx={20} fill="#237078" />
      <rect x={5.5} y={3.5} width={39} height={39} rx={19.5} stroke="#F7F7F7" />
      <path
        d="M21 27.2252L23.0545 29L30 23L23.0545 17L21 18.7748L25.891 23L21 27.2252Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_1202_7427"
        x={0}
        y={0}
        width={50}
        height={50}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.235294 0 0 0 0 0.258824 0 0 0 0 0.341176 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1202_7427"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1202_7427"
          result="effect2_dropShadow_1202_7427"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1202_7427"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SVGComponent;
