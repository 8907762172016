import React from "react";

function CommunitiesIcon({className,...rest}) {
  return (
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 17 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M12 2L8.5 4.667 5 2 1.5 4.667v8c0 .735.628 1.333 1.4 1.333h11.2c.772 0 1.4-.598 1.4-1.333V4.67L12 2zM5 6c.774 0 1.4.597 1.4 1.333 0 .737-.627 1.334-1.4 1.334-.774 0-1.4-.597-1.4-1.334C3.6 6.597 4.226 6 5 6zm2.1 5.333H2.9v-.466C2.9 9.981 4.066 9.5 5 9.5c.934 0 2.1.48 2.1 1.367v.466zM12 6c.774 0 1.4.597 1.4 1.333 0 .737-.627 1.334-1.4 1.334-.774 0-1.4-.597-1.4-1.334C10.6 6.597 11.227 6 12 6zm2.1 5.333H9.9v-.466C9.9 9.981 11.065 9.5 12 9.5c.934 0 2.1.48 2.1 1.367v.466z"
      ></path>
    </svg>
  );
}



export default CommunitiesIcon;