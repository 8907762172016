import { useContext } from "react";

import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import TextArea from "../../components/Input/TextArea";
import { ToggleSidebarContext } from "../../components/Layout/Layout";

export default function EmailTemplate() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  return (
    <div className="w-full">
      <Header />
      <div
        onClick={() => setToggleSidebar(false)}
        className="relative px-2 w-full lg:py-0 h-[94%] lg:h-[92%] xs:px-8 lg:px-8 flex flex-col py-6 lg:space-y-6"
      >
        <div className="flex w-full flex-col lg:flex-col  justify-between space-y-1">
          <div className="text-primary-teal text-[18px] px-6 ">
            Click on Email Template to Edit
          </div>
          <div className="w-full lg:w-full px-6">
            <TextArea
              inputFieldClass=" px-[1rem] h-[20rem] w-full text-secondary-gray font-light text-xl overflow-auto"
              outerClass="w-full"
              label="Text"
              placeholder="Placeholder"
              labelClass="text-primary-teal text-xl lg:text-2xl xl:text-2xl 3xl:text-3xl"
              extraClass="px-[.5rem] py-[.5rem]  shadow-textArea border-primary-teal w-full border-[0.5px] rounded-2xl"
            />
          </div>
          <div className="flex w-full lg:w-full p-6 flex-col lg:flex-row space-y-4 lg:space-y-0">
            <div className="flex flex-col md:flex-row w-full lg:w-5/6 space-y-4 md:space-y-0 md:space-x-4">
              <Button
                type=""
                innerText="Forget Password"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full  lg:w-1/8 px-4 py-[.5rem] 3xl:py-[0.7rem] text-sm lg:text-md 3xl:text-xl uppercase text-primary-white"
              />
              <Button
                type=""
                innerText="Job Close"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-white rounded-full  lg:w-1/8 px-4 py-[.5rem] 3xl:py-[0.7rem] text-sm lg:text-md 3xl:text-xl uppercase text-primary-teal"
              />
              <Button
                type=""
                innerText="Create User"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-white rounded-full  lg:w-1/8 px-4 py-[.5rem] 3xl:py-[0.7rem] text-sm lg:text-md 3xl:text-xl uppercase text-primary-teal"
              />
            </div>

            <div className="w-full lg:w-1/6 flex lg:justify-end">
              <Button
                type="submit"
                innerText="Submit"
                extraClass="shadow-button bg-primary-teal w-1/3 lg:w-1/2 rounded-full px-2 py-2  py-1 6xl:py-2 text-[0.9rem] xl:text-[1.1rem] 5xl:text-[1.3rem] 6xl:text-[1.5rem] uppercase text-primary-white"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
