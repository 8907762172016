import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import Pagination from "../../components/Pagination/Pagination";
import Header from "../../components/Header/Header";
import Input from "../../components/Input/Input";
import { jobColumnCommunity } from "../../constants/TableColumns/Columns";
import Table from "../../components/Table/Table";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";

import SearchIcon from "../../assets/svg/searchIcon";
import { useDispatch } from "react-redux";

export default function CommunityJobs() {
  const { id } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterFields, setFilterFields] = useState({
    field: "",
    value: "",
  });
  const [community, setCommunity] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getJobs = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/job/getListing?communityId=${id}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          filterFields.field
            ? "filterFields=" +
              filterFields.field +
              "&filterValues=" +
              filterFields.value +
              "&"
            : ""
        }${searchQuery ? "searchQuery=" + searchQuery : ""}`
      );

      if (data.success) {
        setJobs(data.jobs);
        setTotalJobs(data.totalJobs);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, [currentPage, rowsPerPage, filterFields.value]);

  useEffect(() => {
    if (searchQuery.length === 0) getJobs();
    if (searchQuery.length > 2) getJobs();
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header title="Jobs" buttonText={community.legalName} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full">
          <Input
            iconClass="absolute top-[22%] left-4 text-secondary-gray "
            Icon={SearchIcon}
            placeholder="Search Job"
            inputFieldClass="placeholder:text-primary-gray"
            outerClass="w-full"
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
            onChange={(e) => onChange(e)}
          />
        </div>

        <div className="overflow-auto min-h-[6rem] px-2 flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && jobs.length !== 0 && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={jobColumnCommunity}
              tableData={jobs}
            />
          )}
          {!isLoading && jobs.length === 0 && (
            <div className="flex justify-center text-primary-gray">
              No Job found
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalJobs}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
