import React from "react";

function LogoutIcon({className,...rest}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    className={`${className}`}
    {...rest}
  >
    <path
      fill="currentColor"
      d="M6.99 0C3.137 0 0 3.14 0 7s3.136 7 6.99 7a6.98 6.98 0 005.717-2.971l-.946-.675a5.815 5.815 0 01-4.77 2.48A5.82 5.82 0 011.165 7a5.82 5.82 0 015.826-5.833c1.975 0 3.714.98 4.77 2.479l.946-.675A6.98 6.98 0 006.991 0zm4.28 4.247l-.838.839 1.329 1.33h-7.1v1.167h7.1l-1.33 1.331.838.839 2.33-2.334L14 7l-.4-.42-2.33-2.333z"
    ></path>
  </svg>
  );
}

export default LogoutIcon;
