import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";

import Button from "../Button/Button";
import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";

import ImportIcon from "../../assets/svg/importIcon";

export default function AddViolationCompany() {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [violations, setViolations] = useState({
    title: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setViolations((prev) => ({ ...prev, [name]: value }));
  };

  const getViolationId = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/company/violation/company?pageNum=1&rowsPerPage=30&sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        const violation = data.violations[0];
        setViolations({
          title: violation.title,
          content: violation.content,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addViolation = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.post("/api/company/violation", [violations]);

      if (data.success) {
        toast.success("Violation added successfully");
        navigate("/company/violation-library");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const editViolation = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.patch("/api/company/violation", {
        violationId: id,
        updateFields: violations,
      });

      if (data.success) {
        toast.success(data.message);
        navigate("/company/violation-library");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  useEffect(() => {
    if (id) getViolationId();
  }, [id]);

  return (
    <div className="w-full">
      <Header
        title={id ? "Edit Violation" : "Add Violation"}
        buttonText={user.company.companyName}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        {isLoading && (
          <div className="flex justify-center items-center w-full mt-2">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}

        {!isLoading && (
          <>
            {!id && (
              <div className="flex items-center gap-4">
                <Button
                  Icon={ImportIcon}
                  iconClass=" w-[1rem] h-[1rem]"
                  extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-[0.75rem] text-primary-white w-fit"
                  innerText="Import from the ProCAM Violation Library"
                  onClick={() => {
                    navigate(
                      "/company/violation-library?importFromMasterAdmin=true"
                    );
                  }}
                />
                <span className="text-primary-gray-light text-[0.8rem]">
                  Type a new Violation or save some time by selecting from
                  ProCAM's Violation Library. All violations are editable after
                  import.
                </span>
              </div>
            )}

            <div className="flex gap-4 grow mt-[0.5rem]">
              <Input
                label="Violation Title"
                placeholder="Enter Violation Title"
                type="text"
                labelClass="text-primary-teal"
                inputFieldClass="px-4"
                required={true}
                onChange={onChange}
                name={"title"}
                outerClass="w-1/3"
                extraClass="border-primary-gray border-[1px] rounded-full "
                value={violations.title}
                info={`Violation Title is the headline text that will show in the application to quickly identify the violation. This text will not appear in the letter.  We recommend keeping it short. Examples: "Garbage Cans" or "Dead Turfgrass"`}
              />
              <Input
                label="Violation"
                labelClass="text-primary-teal"
                placeholder="Enter Violation Content"
                inputFieldClass="px-4"
                required={true}
                type="text"
                name={"content"}
                onChange={onChange}
                outerClass="w-2/3"
                extraClass="border-primary-gray border-[1px] rounded-full "
                value={violations.content}
                info={`Violation Text is the actual violation language that will appear in letters sent to owners.`}
              />
            </div>

            <div className="flex justify-end gap-4 mt-auto mb-2">
              <Button
                type="submit"
                innerText="Cancel"
                extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white py-2 px-4 w-fit text-sm rounded-full uppercase text-primary-red"
                onClick={() => navigate("/company/violation-library")}
              />
              {id ? (
                <Button
                  type="submit"
                  innerText="Edit violation"
                  extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-sm text-primary-white w-fit"
                  isLoading={isLoadingUpdate}
                  disabled={
                    isLoadingUpdate || !violations.title || !violations.content
                  }
                  onClick={editViolation}
                  spinnerLight={true}
                />
              ) : (
                <Button
                  type="submit"
                  innerText="Add violation"
                  extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-sm text-primary-white w-fit"
                  isLoading={isLoadingUpdate}
                  disabled={
                    isLoadingUpdate || !violations.title || !violations.content
                  }
                  onClick={addViolation}
                  spinnerLight={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
