import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import Button from "../Button/Button";
import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";

import AddIcon from "../../assets/svg/addIcon";

export default function AddUserRole() {
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [role, setRole] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const addRole = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/company/role", { roles: [role] });

      if (data.success) {
        toast.success(data.message);
        navigate("/company/manage-role");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <Header Icon={AddIcon} title={"Add New Role"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="relative w-full h-[94%] flex flex-col py-2 px-4"
      >
        <div className="flex w-full flex-col justify-between px-4 py-2">
          <Input
            label="Role"
            placeholder="Add New User Role"
            type="text"
            labelClass="text-primary-teal"
            inputFieldClass="px-4"
            required={true}
            outerClass="w-1/3"
            extraClass="border-primary-gray border-2 rounded-full"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />
        </div>

        <div className="absolute flex flex-col right-0 md:flex-row md:right-8 bottom-4 flex w-full md:w-1/2 space-y-4 md:space-y-0 md:space-x-4 lg:w-1/4">
          <Button
            type="submit"
            innerText="Add Role"
            extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal w-full md:w-2/3 rounded-full py-2 3xl:py-[0.4rem] 6xl:px-8 6xl:py-2 text-[0.8rem] xl:text-[.9rem] 5xl:text-[1rem] 6xl:text-[1.2rem] uppercase text-primary-white"
            onClick={addRole}
            isLoading={isLoading}
            disabled={isLoading || !role}
            spinnerLight={true}
          />

          <Button
            type="submit"
            innerText="Cancel"
            extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white w-full md:w-1/3 py-2 rounded-full 3xl:py-[0.4rem] 6xl:px-8 6xl:py-2 text-[0.8rem] xl:text-[.9rem] 5xl:text-[1rem] 6xl:text-[1.2rem] uppercase text-primary-red"
            onClick={() => navigate("/company/manage-role")}
          />
        </div>
      </div>
    </div>
  );
}
