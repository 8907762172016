import React from "react";

function ReportIcon({ className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M10.444 1h-6C3.644 1 3 1.7 3 2.569v10.862C3 14.3 3.644 15 4.444 15h7.112c.8 0 1.444-.7 1.444-1.569V3.776c0-.736-.267-1.436-.744-1.967A2.421 2.421 0 0010.444 1zM6.222 11.38c0 .204-.144.361-.333.361H5c-.189 0-.333-.156-.333-.362V9.207c0-.205.144-.362.333-.362h.889c.189 0 .333.157.333.362v2.172zm2.556 0c0 .204-.145.361-.334.361h-.888c-.19 0-.334-.156-.334-.362V6.793c0-.205.145-.362.334-.362h.888c.19 0 .334.157.334.362v4.586zm2.555 0c0 .204-.144.361-.333.361h-.889c-.189 0-.333-.156-.333-.362V8c0-.205.144-.362.333-.362H11c.189 0 .333.157.333.362v3.38zm.223-7.966c-.434 0-.778-.374-.778-.845V1.76c.378.073.722.266 1 .568.278.301.455.675.522 1.086h-.744z"
      ></path>
    </svg>
  );
}

export default ReportIcon;
