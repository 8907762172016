import React from "react";

function ErrorIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#FE4B4B"
        fillRule="evenodd"
        d="M8 15A7 7 0 118 1a7 7 0 010 14zm0-2.188a1.312 1.312 0 100-2.624 1.312 1.312 0 000 2.624zM8 2.75a.875.875 0 00-.875.875V8a.875.875 0 101.75 0V3.625A.875.875 0 008 2.75z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ErrorIcon;