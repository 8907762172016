import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function BasicTooltip({ Icon, title }) {
  return (
    <Tooltip title={title}>
      <IconButton>{Icon}</IconButton>
    </Tooltip>
  );
}
