import React from "react";

function SearchIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`3xl:w-[24px] 3xl:h-[24px] ${className}`}
      {...rest}
    >
      <path
        fill="#B3B3B3"
        fillRule="evenodd"
        d="M12.118 10.88a.89.89 0 00-.093-.08 6.125 6.125 0 10-1.225 1.225.89.89 0 00.08.093l2.626 2.626a.875.875 0 101.238-1.238l-2.626-2.625zM11.5 7.126a4.375 4.375 0 11-8.75 0 4.375 4.375 0 018.75 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SearchIcon;