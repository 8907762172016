import React from "react";

function ExpandIcon({ className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#237078"
        d="M13.44 2c-.021 0-.041.003-.061.006H9.636a.545.545 0 100 1.09h2.499l-9.038 9.036V9.634a.545.545 0 10-1.09 0v3.73a.545.545 0 00.628.628h3.732a.546.546 0 100-1.09h-2.5l9.038-9.036v2.498a.546.546 0 101.09 0V2.62a.547.547 0 00-.556-.62z"
      ></path>
    </svg>
  );
}

export default ExpandIcon;
