import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.6875 1C2.75713 1 2 1.70665 2 2.575V13.425C2 14.2934 2.75713 15 3.6875 15H12.3125C13.2429 15 14 14.2934 14 13.425V6.6H9.6875C8.75712 6.6 8 5.89335 8 5.025V1H3.6875ZM9.125 1.30762V5.025C9.125 5.31445 9.37738 5.55 9.6875 5.55H13.6704L9.125 1.30762ZM5.5625 8.35H10.4375C10.748 8.35 11 8.5852 11 8.875C11 9.1648 10.748 9.4 10.4375 9.4H5.5625C5.252 9.4 5 9.1648 5 8.875C5 8.5852 5.252 8.35 5.5625 8.35ZM5.55884 10.8H8.93237C9.24287 10.8 9.49487 11.0349 9.49487 11.325C9.49525 11.5906 9.28378 11.8106 9.00928 11.8452L8.93311 11.85H5.55957C5.24907 11.85 4.99707 11.6148 4.99707 11.325C4.99707 11.0594 5.20817 10.8394 5.48267 10.8048L5.55884 10.8Z"
      fill="white"
    />
  </svg>
);
export default SVGComponent;
