import React from "react";

function DashboardIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M10 6V0h8v6h-8zM0 10V0h8v10H0zm10 8V8h8v10h-8zM0 18v-6h8v6H0zM2 8h4V2H2v6zm10 8h4v-6h-4v6zm0-12h4V2h-4v2zM2 16h4v-2H2v2z"
      ></path>
    </svg>
  );
}

export default DashboardIcon;