import React from "react";

function FlagIcon({ className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#666"
        d="M5.202 0C1.793 0 .587.59.24.825a.383.383 0 00-.113.088H.115L.102.936a.213.213 0 00-.037.038l-.063.05v.1a.301.301 0 000 .075v18.4a.403.403 0 00.199.352.404.404 0 00.403 0 .403.403 0 00.198-.352v-7.338c.468-.189 1.882-.662 4.4-.662 1.414 0 2.486.316 3.725.675 1.504.436 3.205.925 5.875.925 2.764 0 4.91-1.198 5-1.25l.2-.112V.925l-.587.325c-.5.27-2.311 1.15-4.613 1.15-1.91 0-3.24-.573-4.65-1.175C8.734.62 7.265 0 5.202 0z"
      ></path>
    </svg>
  );
}

export default FlagIcon;
