import { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import Input from "../../components/Input/Input";
import Pagination from "../../components/Pagination/Pagination";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Table from "../../components/Table/Table";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { letterLibraryCompanyColumn } from "../../constants/TableColumns/Columns";
import axios from "../../utils/helpers/axios";

import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";
import ImportIcon from "../../assets/svg/importIcon";

export default function LetterLibraryCompany() {
  const navigate = useNavigate();
  const { user } = useSelector((store) => store.auth);
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const [searchParams] = useSearchParams();
  const importFromMasterAdmin = searchParams.get("importFromMasterAdmin");

  const [letterLibraries, setLetterLibraries] = useState([]);
  const [totalLetterLibrary, setTotalLetterLibrary] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLetterLibraries, setSelectedLetterLibraries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectHandler = (e, row) => {
    if (e.target.checked)
      setSelectedLetterLibraries((prev) => [
        ...prev,
        {
          id: row._id,
          title: row.title,
          tags: row.tags,
          content: row.content,
          margins: row.margins,
        },
      ]);
    else {
      setSelectedLetterLibraries(
        selectedLetterLibraries.filter((item) => item.id !== row._id)
      );
    }
  };

  const onEditHandler = (row) => {
    navigate(`/company/letter-template/edit/${row._id}`);
  };

  const getLetterLibrary = async (param) => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/company/letterTemplate/${param}?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }`
      );

      if (data.success) {
        setTotalLetterLibrary(data.matchedLetterTemplatesCount);
        setLetterLibraries(data.letterTemplates);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addLetterTemplate = async () => {
    try {
      setIsLoadingImport(true);

      const { data } = await axios.post(
        "/api/company/letterTemplate",
        selectedLetterLibraries.map((letterLibraries) => ({
          title: letterLibraries.title,
          content: letterLibraries.content,
          tags: letterLibraries.tags,
          margins: letterLibraries.margins,
        }))
      );

      if (data.success) {
        toast.success(data.message);
        navigate("/company/letter-library");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingImport(false);
    }
  };

  const deleteLetterLibrary = async () => {
    try {
      setIsLoadingDelete(true);
      const selectedLetterLibrariesId = selectedLetterLibraries.map(
        (letterLibraries) => letterLibraries.id
      );

      const { data } = await axios.delete(
        `/api/company/letterTemplate?letterTemplateIds=${selectedLetterLibrariesId.join(
          ","
        )}`
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedLetterLibraries([]);
        getLetterLibrary("company");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete letter template");
    } finally {
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    if (importFromMasterAdmin) getLetterLibrary("master_admin");
    else getLetterLibrary("company");
  }, [currentPage, rowsPerPage, importFromMasterAdmin]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      if (importFromMasterAdmin) getLetterLibrary("master_admin");
      else getLetterLibrary("company");
    }
    if (searchQuery.length > 2) {
      if (importFromMasterAdmin) getLetterLibrary("master_admin");
      else getLetterLibrary("company");
    }
  }, [searchQuery, importFromMasterAdmin]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  return (
    <div className="w-full">
      <Header
        title={
          importFromMasterAdmin
            ? "ProCAM Letter Library"
            : "Company Master Letter Library"
        }
        buttonText={user.company.companyName}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="flex gap-4">
          <div className="grow">
            <Input
              iconClass="absolute left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search Letter Template"
              inputFieldClass="placeholder:text-primary-gray"
              extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
              onChange={(e) => onChange(e)}
            />
          </div>
          {!importFromMasterAdmin && (
            <Link to="/company/letter-template">
              <Button
                Icon={AddIcon}
                iconClass=" w-[1rem] h-[1rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Add New Letter Template"
              />
            </Link>
          )}
        </div>

        <div className=" overflow-auto grow flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={letterLibraryCompanyColumn(
                selectedLetterLibraries,
                onSelectHandler,
                onEditHandler,
                letterLibraries,
                setSelectedLetterLibraries,
                importFromMasterAdmin
              )}
              tableData={letterLibraries}
            />
          )}
        </div>

        <div>
          {importFromMasterAdmin ? (
            <Button
              Icon={ImportIcon}
              iconClass=" w-[1rem] h-[1rem]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full mb-4"
              innerText="Import Selected"
              spinnerLight={true}
              isLoading={isLoadingImport}
              onClick={addLetterTemplate}
              disabled={isLoadingImport || selectedLetterLibraries.length === 0}
            />
          ) : (
            <Button
              Icon={DeleteIcon}
              iconClass=" w-[1rem] h-[1rem]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full mb-4"
              innerText="Delete Selected"
              onClick={deleteLetterLibrary}
              spinnerLight={true}
              isLoading={isLoadingDelete}
              disabled={isLoadingDelete || selectedLetterLibraries.length === 0}
            />
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalLetterLibrary}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />

        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
