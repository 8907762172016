import { useContext } from "react";

import Header from "../../components/Header/Header";
import CustomTooltip from "../../components/Tooltip/Tooltip";
import LabelValueField from "../../components/LabelValueField/LabelValueField";
import Button from "../../components/Button/Button";
import { ToggleSidebarContext } from "../../components/Layout/Layout";

import PropertiesIcon from "../../assets/svg/propertiesIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import InfoIcon from "../../assets/svg/infoIcon.svg";

const violations = [
  {
    content: "Landscape maintenance Needed (mow edge, line trim, etc)",
    openedOn: "02/11/2024 04:36:57",
  },
  {
    content: "Landscape maintenance Needed (mow edge, line trim, etc)",
    openedOn: "02/11/2024 04:36:57",
  },
  {
    content: "Landscape maintenance Needed (mow edge, line trim, etc)",
    openedOn: "02/11/2024 04:36:57",
  },
];

function OwnersView() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  return (
    <div className="w-full">
      <Header title="Owner's View" address="123, Bluff Drive" />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex px-8 pt-4 gap-4"
      >
        <div className="flex flex-col min-w-[52%]">
          <div>
            <div className="flex items-center gap-[0.5rem] text-primary-teal border-b-[1px] border-primary-gray-light ">
              <PropertiesIcon />
              <div className="text-lg">Property Address</div>
            </div>
            <div className="flex flex-col gap-[0.4rem] py-[1rem]">
              <LabelValueField label="Street No." value={"123"} />
              <LabelValueField label="Street Name" value={"choclate bluff"} />
              <LabelValueField label="Address Line 2" value={"123,test"} />
              <div className="flex justify-between">
                <LabelValueField label="City" value={"Agra"} />
                <LabelValueField label="State" value={"UP"} />
                <LabelValueField label="Zip" value={"123456"} />
              </div>
              <div className=" flex items-center gap-[1rem]">
                <div className={"text-sm text-primary-teal font-semibold"}>
                  Tenant Occupied :
                </div>
                <input
                  type="checkbox"
                  checked={"property.tenantOccupied" === "yes" ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-[0.5rem] text-primary-teal border-b-[1px] border-primary-gray-light">
              <ViolationIcon />
              <div className="text-lg">Current Open Maintenance Concerns</div>
            </div>
            <div className="flex flex-col ">
              {violations.map((violation) => {
                return (
                  <div className="flex flex-col gap-[0.2rem] border-b-[1px] border-primary-gray-light py-[0.6rem]">
                    <div className="text-primary-black">
                      {violation.content}
                    </div>
                    <div className="text-primary-gray-light text-sm">
                      <span>{violation.openedOn}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center text-secondary-gray-light my-[1rem]">
              View All
            </div>
            <div className="flex gap-[0.5rem]">
              <Button
                type="submit"
                innerText="Photo History"
                extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
              />
              <Button
                type="submit"
                innerText="Maintenance Concern History"
                extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
              />
              <Button
                type="submit"
                innerText="Letter History"
                extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex items-center gap-[6px] text-primary-teal font-semibold">
            Notes *
            <CustomTooltip title={"Tooltip"} placement={"right-start"}>
              <img
                src={InfoIcon}
                alt=""
                className="cursor-pointer h-[1.2rem]"
              />
            </CustomTooltip>
          </div>
          <div className="flex flex-col grow gap-[1rem]">
            <div className="flex flex-col gap-[0.4rem]">
              <h3 className="text-secondary-gray">
                Would you like to leave a note for the community inspector
                regarding open maintenance concerns?
              </h3>
              <p className="text-primary-gray text-[0.8rem]">
                (These notes are not seen by anyone else within the Association,
                nor are they monitored. The inspector will see them during the
                next inspection. For any other comments/concerns, please contact
                the association directly)
              </p>
            </div>
            <textarea
              placeholder="Start Typing..."
              style={{
                height: "18rem",
                borderRadius: "12px",
                backgroundColor: "#fff",
                outline: "1px solid #f6f6f6",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                color: "#666666",
                padding: "1rem",
                fontSize: "0.8rem",
              }}
            />
            <div className="flex justify-end mt-[1.6rem]">
              <Button
                type="submit"
                innerText="Add Note"
                extraClass="shadow-button bg-primary-teal rounded-full text-[0.8rem] uppercase text-primary-white px-[1rem] py-[0.5rem]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwnersView;
