export default function Indicator({
  indicatorText,
  Icon,
  value,
  iconClass,
  textClass,
}) {
  return (
    <div
      className={`w-full flex flex-col border-l-[2.2px] border-primary-teal pl-[1rem]`}
    >
      <div className="flex items-center gap-[0.6rem]">
        {Icon && <Icon className={`text-primary-teal w-5 h-5 ${iconClass}`} />}
        <span className={`text-primary-teal text-[1rem] ${textClass}`}>
          {indicatorText}
        </span>
      </div>
      <div className="flex text-secondary-gray text-[1.8rem]">{value}</div>
    </div>
  );
}
