import InfoIcon from "../../assets/svg/infoIcon.svg";
import SwitchInputv2 from "../Input/SwitchInputv2";
import CustomTooltip from "../Tooltip/Tooltip";

export default function TextToggleComponent({
  label,
  required,
  info,
  labelClass,
  isChecked,
  onChange,
}) {
  return (
    <div className="flex justify-between">
      <div
        className={`flex items-center py-0  text-primary-teal font-[300] text-[0.9rem] ${labelClass}`}
      >
        {label}
        <span className="ml-1">{required ? "*" : ""}</span>
        {info && (
          <CustomTooltip title={info} placement="right">
            <img src={InfoIcon} alt="" className="cursor-pointer" />
          </CustomTooltip>
        )}
      </div>
      <div>
        <SwitchInputv2 {...{ isChecked, onChange }} />
      </div>
    </div>
  );
}
