import React from "react";

function TitleShadow({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="3"
      fill="none"
      viewBox="0 0 8 3"
      className={`${className}`}
      {...rest}
    >
      <path fill="#A2A2A2" d="M8 0L0 3h8V0z"></path>
    </svg>
  );
}

export default TitleShadow;