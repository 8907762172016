export default function PhotoHeader({ title, Icon, extraClass }) {
  return (
    <div className={`flex justify-between mb-4 `}>
      <div className={`flex text-primary-teal text-xl mt-2 ml-4 ${extraClass}`}>
        <div
          className={`bg-primary-teal flex justify-center items-center w-[30px] h-[30px] rounded-full mr-2 cursor-pointer `}
        >
          {Icon && <Icon className="text-primary-white w-[14px]" />}
        </div>
        <div className="text-[1.6rem]">{title}</div>
      </div>
    </div>
  );
}
