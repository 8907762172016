import { useState } from "react";
import { format } from "date-fns";

import DatePicker from "../DatePicker/DatePicker";
import CustomTooltip from "../Tooltip/Tooltip";

import InfoIcon from "../../assets/svg/infoIcon.svg";
import CalendarIcon from "../../assets/svg/calendar";

const DatePickerInput = ({
  placeholder,
  handleIconClick,
  Icon,
  type,
  name,
  id,
  error,
  label,
  extraClass,
  inputFieldClass,
  iconClass,
  labelClass,
  message,
  required,
  setValue,
  value,
  outerClass,
  info,
  handleChange,
  ...rest
}) => {
  const [activeCalendar, setActiveCalendar] = useState(false);

  const handleDatePicker = (date) => {
    handleChange(id, format(date, "MMMM d, yyyy"));
  };

  return (
    <div className={`${outerClass}`}>
      <div
        className={`flex items-center py-0 font-semibold text-sm ${labelClass}`}
      >
        {label}
        <span className="mx-1">{required ? "*" : ""}</span>
        {info && (
          <CustomTooltip title={info} placement="right">
            <img src={InfoIcon} alt="" className="cursor-pointer" />
          </CustomTooltip>
        )}
      </div>
      <div
        className={` relative flex items-center shadow-inputShadow hover:shadow-inputShadowActive ${extraClass}`}
      >
        <input
          className={`bg-transparent focus:outline-none w-full py-[.4rem] text-sm ${inputFieldClass}`}
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          {...rest}
        />
        <CalendarIcon
          onClick={() => setActiveCalendar(!activeCalendar)}
          className={`absolute right-4 top-[50%] -translate-y-[50%] ${iconClass}`}
        />

        {activeCalendar ? (
          <div className=" z-40 absolute top-[2.4rem] right-[1rem] border-[0.5px] border-secondary-gray rounded-2xl h-fit overflow-hidden">
            <DatePicker
              onClick={() => setActiveCalendar(!activeCalendar)}
              handleDatePicker={handleDatePicker}
              setActiveCalendar={setActiveCalendar}
              value={value}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {message && (
        <p className=" absolute pl-4 font-normal text-xs text-secondary-gray">
          {message}
        </p>
      )}
      {error && (
        <p className="mt-1.5 pl-1 font-normal text-xs text-primary-red">
          {error}
        </p>
      )}
    </div>
  );
};

export default DatePickerInput;
