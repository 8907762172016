import React from "react";

function ArchiveIcon({ className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#fff"
        d="M1 2.54v11.374h13v-9.75H5.875L4.91 2.54H1zm1.083 2.707h10.834v7.584H2.083V5.247z"
      ></path>
      <g clipPath="url(#clip0_1327_8288)">
        <path
          fill="#fff"
          d="M9.416 9.212H8.358V6.641H6.644v2.571H5.586l1.915 2.98 1.915-2.98z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1327_8288">
          <path
            fill="#fff"
            d="M0 0H5.571V5.571H0z"
            transform="rotate(90 1.822 8.463)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ArchiveIcon;
