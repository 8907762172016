import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={14}
    height={10}
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.09375 2C0.489689 2 0 1.55228 0 1C0 0.447715 0.489689 0 1.09375 0C1.69781 0 2.1875 0.447715 2.1875 1C2.1875 1.55228 1.69781 2 1.09375 2ZM1.09375 6C0.489689 6 0 5.55228 0 5C0 4.44772 0.489689 4 1.09375 4C1.69781 4 2.1875 4.44772 2.1875 5C2.1875 5.55228 1.69781 6 1.09375 6ZM1.09375 10C0.489689 10 0 9.55229 0 9C0 8.44771 0.489689 8 1.09375 8C1.69781 8 2.1875 8.44771 2.1875 9C2.1875 9.55229 1.69781 10 1.09375 10ZM4.375 0.2H13.125C13.6082 0.2 14 0.558172 14 1C14 1.44183 13.6082 1.8 13.125 1.8H4.375C3.89175 1.8 3.5 1.44183 3.5 1C3.5 0.558172 3.89175 0.2 4.375 0.2ZM4.375 4.2H13.125C13.6082 4.2 14 4.55817 14 5C14 5.44183 13.6082 5.8 13.125 5.8H4.375C3.89175 5.8 3.5 5.44183 3.5 5C3.5 4.55817 3.89175 4.2 4.375 4.2ZM4.375 8.2H13.125C13.6082 8.2 14 8.55817 14 9C14 9.44183 13.6082 9.8 13.125 9.8H4.375C3.89175 9.8 3.5 9.44183 3.5 9C3.5 8.55817 3.89175 8.2 4.375 8.2Z"
      fill="white"
    />
  </svg>
);
export default SVGComponent;
