import InfoIcon from "../../assets/svg/infoIcon";

const TextArea = ({
  placeholder,
  handleIconClick,
  Icon,
  type,
  name,
  id,
  error,
  label,
  extraClass,
  inputFieldClass,
  iconClass,
  labelClass,
  row,
  required,
  outerClass,
  info,
  ...rest
}) => {
  return (
    <div className={`${outerClass}`}>
      <div
        className={`flex items-center py-0 3xl:py-2 font-semibold text-sm lg:text-md xl:text-lg 3xl:text-2xl ${labelClass}`}
      >
        {label}
        <span className="ml-1">{required ? "*" : ""}</span>
        {info && (
          <div className="ml-1">
            <InfoIcon />
          </div>
        )}
      </div>
      <div
        className={`flex rounded-[0.8rem] items-center shadow-inputShadow hover:shadow-inputShadowActive ${extraClass}`}
      >
        <textarea
          className={`resize-none text-secondary-gray bg-transparent focus:outline-none w-full py-[.4rem] 3xl:py-[0.5rem] text-sm lg:text-md xl:text-lg 3xl:text-2xl overflow-auto ${inputFieldClass}`}
          type={type}
          placeholder={placeholder}
          name={name}
          rows={row}
          id={id}
          {...rest}
        />
        {Icon && <Icon onClick={handleIconClick} className={iconClass} />}
      </div>

      {error && (
        <p className="mt-1.5 pl-1 font-normal text-xs lg:text-[.9rem] 5xl:text-base 6xl:text-base text-primary-red">
          {error}
        </p>
      )}
    </div>
  );
};

export default TextArea;
