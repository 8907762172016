import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Button from "../Button/Button";
import axios from "../../utils/helpers/axios";
import axiosRawClient from "axios";

import DeleteIcon from "../../assets/svg/deleteIcon";
import PdfIcon from "../../assets/svg/pdfIcon.svg";
import DocxIcon from "../../assets/svg/docxIcon.svg";

export default function DocumentCard({ documentData, getDocuments }) {
  const [imageData, setImageData] = useState({});
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getPropertyJobLetter = async (pdfurl) => {
    try {
      setIsLoading(true);

      const response = await axiosRawClient.get(pdfurl, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = imageData.fileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getImage = async () => {
    try {
      const { data } = await axios.get(
        `/api/media/mediaURL?mediaId=${documentData.mediaId}`
      );

      if (data.success) {
        setImageData(data.media);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDocuments = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/community/document?documentId=${documentData._id}`
      );

      if (data.success) {
        toast.success(data.message);
        getDocuments();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    if (documentData.mediaId) getImage();
  }, [documentData.mediaId]);

  return (
    <div
      onClick={() => {
        if (imageData.s3PresignedURL)
          getPropertyJobLetter(imageData.s3PresignedURL);
      }}
      className="relative h-[6.4rem] rounded-xl shadow-inputShadow flex border-[1px] border-offWhite p-1 cursor-pointer"
    >
      {imageData.fileName?.includes(".pdf") && (
        <div className="w-1/5 h-full p-1">
          <img src={PdfIcon} className="h-full object-contain" alt="" />
        </div>
      )}
      {imageData.fileName?.includes(".docx") && (
        <div className="w-1/5 h-full p-1">
          <img src={DocxIcon} className="h-full object-contain" alt="" />
        </div>
      )}

      <div className="text-primary-black ml-[1rem] text-wrap">
        {imageData.fileName}
      </div>
      <div className="absolute right-2 bottom-2">
        <Button
          Icon={DeleteIcon}
          iconClass={"w-[15px] h-[15px]"}
          type="submit"
          innerText="Delete"
          extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-1 px-2 text-primary-red"
          onClick={(e) => {
            e.stopPropagation();
            deleteDocuments();
          }}
          isLoading={isLoadingDelete}
          disabled={isLoadingDelete}
        />
      </div>
    </div>
  );
}
