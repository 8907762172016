import React from "react";

function ViolationIcon({className,...rest}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    className={`${className}`}
    {...rest}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.851 2.145l4.93 9.86A2.07 2.07 0 0112.93 15H3.07a2.07 2.07 0 01-1.851-2.995l4.93-9.86a2.07 2.07 0 013.702 0zM8 10.188a1.312 1.312 0 100 2.624 1.312 1.312 0 000-2.624zm0-1.313c.483 0 .875-.28.875-.625V5.125c0-.345-.392-.625-.875-.625s-.875.28-.875.625V8.25c0 .345.392.625.875.625z"
      clipRule="evenodd"
    ></path>
  </svg>
  
  );
}

export default ViolationIcon;