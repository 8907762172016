import React from "react";

function csvIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      fill="none"
      viewBox="0 0 26 25"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#237078"
        d="M21.09 8.16l-5.418-5.416a.833.833 0 00-.589-.244h-8.75c-.92 0-1.666.746-1.666 1.667v17.5c0 .92.745 1.666 1.666 1.666h13.334c.92 0 1.666-.745 1.666-1.666V8.75a.832.832 0 00-.244-.59zM9.47 17.597c.192 0 .41-.044.55-.088l.114.963c-.166.079-.49.14-.883.14-1.514 0-2.231-1.233-2.231-2.914 0-2.221 1.233-3.088 2.38-3.088.402 0 .682.079.796.158l-.193.962a1.148 1.148 0 00-.534-.113c-.647 0-1.233.56-1.233 2.02 0 1.409.533 1.96 1.233 1.96zm2.87 1.015c-.472 0-.927-.123-1.128-.236l.166-.99c.236.132.647.228.971.228.534 0 .805-.28.805-.665 0-.428-.262-.638-.76-.962-.806-.49-1.111-1.111-1.111-1.645 0-.945.63-1.733 1.854-1.733.394 0 .762.105.928.21l-.184.989c-.166-.105-.42-.201-.744-.201-.49 0-.726.297-.726.612 0 .35.175.534.805.919.787.472 1.067 1.067 1.067 1.688 0 1.077-.797 1.786-1.943 1.786zm5.293-.053h-1.285l-1.313-5.896h1.268l.42 2.545c.096.64.219 1.356.297 2.048h.018c.078-.7.174-1.408.28-2.073l.401-2.52h1.26l-1.346 5.896zM15.5 9.167a.834.834 0 01-.833-.834V4.087l5.08 5.08H15.5z"
      ></path>
    </svg>
  );
}

export default csvIcon;