import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  token: null,
  expirationDate: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      const token = action.payload.token;
      const user = action.payload.user;
      const expirationDate = action.payload.expirationDate;

      const tokenExpirationDate = expirationDate
        ? expirationDate
        : Date.parse(new Date(new Date().getTime() + 60 * 60 * 24 * 1000));

      state.isLoggedIn = true;
      state.token = token;
      state.user = user;
      state.expirationDate = tokenExpirationDate;

      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: token,
          user: user,
          expirationDate: tokenExpirationDate,
        })
      );
    },

    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
      localStorage.removeItem("userData");
    },

    updateUserDetails: (state, action) => {
      const user = action.payload.user;
      state.user = user;
      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: state.token,
          user: user,
          expirationDate: state.expirationDate,
        })
      );
    },
  },
});

export const { login, logout, updateUserDetails } = authSlice.actions;