import { useContext } from "react";

import Indicator from "../../components/Indicator/Indicator";
import Activitylist from "../../components/ActivityList/ActivityList";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";

import UserIcon from "../../assets/svg/userIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import PropertiesIcon from "../../assets/svg/propertiesIcon";
import CommunitiesIcon from "../../assets/svg/communitiesIcon";
import CompanyIcon from "../../assets/svg/companyIcon";

export default function Dashboard() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  return (
    <div className="w-full">
      <Header title={"Dashboard"} buttonText={"Master Admin"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-2 h-[92%]"
      >
        <div className="flex my-4 border-r-[2px] border-primary-teal">
          <Indicator indicatorText="Companies" value="21" Icon={CompanyIcon} />
          <Indicator
            indicatorText="Communities"
            value="21"
            Icon={CommunitiesIcon}
          />
          <Indicator
            indicatorText="Properties"
            value="5998"
            Icon={PropertiesIcon}
          />
          <Indicator indicatorText="Users" value="10" Icon={UserIcon} />
          <Indicator
            indicatorText="Open Violations"
            value="300"
            Icon={ViolationIcon}
          />
        </div>
        <div className="px-4 flex flex-col h-[80%]">
          <div className="text-primary-teal text-[2rem] border-b-2 border-secondary-gray pb-2">
            Activity
          </div>
          <div className="overflow-auto flex flex-col pr-4 mt-2">
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
          </div>
        </div>
      </div>
    </div>
  );
}
