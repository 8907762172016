import { useState } from "react";
import toast from "react-hot-toast";

import Button from "../Button/Button";
import LabelValueField from "../LabelValueField/LabelValueField";
import { formatTimestamp } from "../../utils/helpers/dateFormat";
import axios from "../../utils/helpers/axios";

import DeleteIcon from "../../assets/svg/deleteIcon";
import EditIcon from "../../assets/svg/editIcon";
import ImportIcon from "../../assets/svg/importIconGreen";

export default function LetterHistoryCard({ letter, getLetterHistories }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const getPropertyJobLetter = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `/api/job/downloadPropertyJobLetter?generatedLetterId=${letter._id}`,
        { responseType: "arraybuffer" }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloaded.pdf";
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteLetter = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/property/letterHistory?generatedLetterId=${letter._id}`
      );

      if (data.success) {
        toast.success(data.message);
        getLetterHistories();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <div className="relative border-[1px] border-gray shadow-inputShadow rounded-2xl p-4 flex flex-col gap-[0.6rem]">
      <LabelValueField label="Job Name" value={letter.jobDetails?.jobName} />
      <div className="flex gap-[16rem]">
        <LabelValueField label="Level" value={letter.levelDetails?.title} />
        <div className="flex gap-[1.4rem] text-primary-red">
          <span>Attorney :</span>
          <span>{letter.levelDetails?.flagForAttorney ? "Yes" : "No"}</span>
        </div>
        <LabelValueField
          label="Letter Date"
          value={formatTimestamp(letter.createdAt).split("\n")[0]}
        />
      </div>

      {letter.violationsAssociated?.map((violation) => {
        return (
          <div className="border-b-[1px] border-gray px-[1rem] py-[0.7rem] text-secondary-gray">
            {violation.issues}
          </div>
        );
      })}

      <div className="w-full flex justify-between mt-[0.5rem]">
        <Button
          innerText="Delete"
          Icon={DeleteIcon}
          iconClass="w-[15px] h-[15px]"
          extraClass={`shadow-button border-[0.5px] bg-primary-white w-fit px-4 border-primary-red  rounded-full py-2 px-4 text-[0.8rem] text-primary-red`}
          isLoading={isLoadingDelete}
          disabled={isLoadingDelete}
          onClick={deleteLetter}
        />
        <div className="flex gap-[1.4rem]">
          <Button
            innerText="Download"
            Icon={ImportIcon}
            iconClass="w-[15px] h-[15px]"
            extraClass="shadow-button border-[0.5px] border-gray bg-primary-white rounded-full text-[0.8rem] text-primary-teal px-4 py-2"
            onClick={getPropertyJobLetter}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            innerText="Edit"
            Icon={EditIcon}
            iconClass="w-[15px] h-[15px]"
            extraClass="shadow-button border-[0.5px] border-gray bg-primary-white  rounded-full text-[0.8rem] text-primary-teal px-4 py-2"
          />
        </div>
      </div>
    </div>
  );
}
