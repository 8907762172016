import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Table from "../../components/Table/Table";
import { ManageDropdownOptionColumn } from "../../constants/TableColumns/Columns";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/axios";

import AddIcon from "../../assets/svg/addIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";
import MenuIcon from "../../assets/svg/menuIcon";

export default function ManageUserRole() {
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedroles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const onSelectHandler = (e, row) => {
    if (e.target.checked) setSelectedroles((prev) => [...prev, row._id]);
    else {
      setSelectedroles(selectedRoles.filter((item) => item !== row._id));
    }
  };

  const getRoles = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get("/api/company/role");

      if (data.success) {
        setRoles([
          { role: "Company Administrator", disabled: true },
          { role: "Board User", disabled: true },

          ...data.roles,
        ]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSelectedRoles = async () => {
    try {
      setIsLoadingDelete(true);
      const { data } = await axios.delete(
        `/api/company/role?roleIds=${selectedRoles.join(",")}`
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedroles([]);
        getRoles();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div className="w-full">
      <Header Icon={MenuIcon} title="Manage User Roles Dropdown Options" />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="grow ">
          <div className="flex space-x-4">
            <Button
              type="submit"
              Icon={AddIcon}
              iconClass="h-[1rem]"
              innerText="New"
              extraClass="shadow-button bg-primary-white rounded-full px-4 py-2 text-sm uppercase text-primary-teal border-[0.5px] border-primary-teal"
              onClick={() => navigate("/company/manage-role/add")}
            />
          </div>

          <div className="overflow-auto flex justify-center min-h-[6rem] pr-2">
            {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
            {!isLoading && (
              <Table
                pageSize={roles.length}
                COLUMNS={ManageDropdownOptionColumn(
                  selectedRoles,
                  onSelectHandler
                )}
                tableData={roles}
              />
            )}
            {!isLoading && roles.length === 0 && (
              <div className="flex flex-1 justify-center text-primary-gray">
                No roles found
              </div>
            )}
          </div>
        </div>
        <div>
          <Button
            type="submit"
            Icon={DeleteIcon}
            iconClass="w-[0.9rem] h-[0.9rem]"
            extraClass="relative text-primary-white bg-primary-teal py-2 px-4 py-[.5rem] text-[0.9rem] rounded-full "
            innerText="Delete"
            disabled={selectedRoles.length === 0 || isLoadingDelete}
            isLoading={isLoadingDelete}
            onClick={deleteSelectedRoles}
          />
        </div>
      </div>
    </div>
  );
}
