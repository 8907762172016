import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1775_885)">
      <path
        d="M8.49076 1.00007C8.33425 1.00259 8.1851 1.06894 8.07605 1.18455C7.96699 1.30016 7.90695 1.45559 7.90909 1.61672V7.70003H6.13638C5.97967 7.70003 5.82937 7.76412 5.71855 7.87821C5.60773 7.99229 5.54548 8.14702 5.54548 8.30836C5.54558 8.39175 5.56233 8.47422 5.59469 8.55067C5.62705 8.62711 5.67433 8.69589 5.7336 8.75273L8.03028 11.1183C8.0855 11.1929 8.1567 11.2532 8.23833 11.2948C8.31996 11.3363 8.40981 11.3578 8.50086 11.3577C8.59191 11.3576 8.68171 11.3358 8.76324 11.2941C8.84477 11.2524 8.91583 11.1918 8.97087 11.1171L11.2664 8.75273L11.2676 8.75154C11.3265 8.69473 11.3735 8.6261 11.4056 8.54988C11.4378 8.47366 11.4544 8.39146 11.4545 8.30836C11.4545 8.14702 11.3923 7.99229 11.2814 7.87821C11.1706 7.76412 11.0203 7.70003 10.8636 7.70003H9.0909V1.61672C9.09198 1.53533 9.07719 1.45455 9.0474 1.37915C9.01761 1.30376 8.97343 1.23528 8.91747 1.17778C8.86151 1.12028 8.79491 1.07492 8.72161 1.0444C8.64832 1.01388 8.56982 0.998803 8.49076 1.00007ZM2.58173 7.08338C2.42521 7.0859 2.27606 7.15225 2.16701 7.26786C2.05796 7.38346 1.99791 7.53889 2.00006 7.70003V13.7833C2.00006 14.4483 2.53597 15 3.18186 15H13.8181C14.464 15 14.9999 14.4483 14.9999 13.7833V7.70003C15.001 7.61942 14.9866 7.53938 14.9574 7.46458C14.9282 7.38977 14.8848 7.32169 14.8299 7.26428C14.7749 7.20687 14.7094 7.16129 14.6371 7.13017C14.5649 7.09906 14.4873 7.08303 14.409 7.08303C14.3307 7.08303 14.2532 7.09906 14.181 7.13017C14.1087 7.16129 14.0432 7.20687 13.9882 7.26428C13.9332 7.32169 13.8899 7.38977 13.8607 7.46458C13.8315 7.53938 13.817 7.61942 13.8181 7.70003V13.7833H3.18186V7.70003C3.18295 7.61864 3.16815 7.53786 3.13836 7.46246C3.10857 7.38706 3.06439 7.31859 3.00843 7.26109C2.95247 7.20359 2.88587 7.15823 2.81257 7.12771C2.73928 7.09718 2.66078 7.08211 2.58173 7.08338Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="clip0_1775_885">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
