import React from "react";
import classnames from "classnames";

import { usePagination, DOTS } from "../../customHooks/usePagination";

import LeftArrow from "../../assets/svg/leftArrow";
import RightArrow from "../../assets/svg/rightArrow";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount = 10,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    isStatic,
  } = props;

  let paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (isStatic) {
    paginationRange = [25, 50, 75, 100];
  }
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div
      className={`pagination-container ${
        isStatic ? "pagination-container-static" : ""
      } shadow-paginationShadow items-center `}
    >
      <div className="h-full flex items-center px-3 text-primary-gray-light">
        {isStatic ? "# of Rows per page" : "Page No."}
      </div>
      <ul className={classnames("flex", { [className]: className })}>
        {!isStatic && (
          <li
            className={classnames("pagination-item", {
              disabled: currentPage === 1,
            })}
            onClick={onPrevious}
          >
            <LeftArrow />
          </li>
        )}
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return <li className="pagination-item dots">&#8230;</li>;
          }

          return (
            <li
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        {!isStatic && (
          <li
            className={classnames("pagination-item", {
              disabled: currentPage === lastPage,
            })}
            onClick={onNext}
          >
            <RightArrow />
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
