import React from "react";

function DeleteIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M4.833.168L4.25.751H.75v1.167h10.5V.751h-3.5L7.167.168H4.833zM1.546 3.085l.891 7.737a1.17 1.17 0 001.157 1.013h4.811c.582 0 1.08-.435 1.158-1.018l.89-7.732H1.547z"
      ></path>
    </svg>
  );
}

export default DeleteIcon;