import Button from "../Button/Button";

import ArchiveIcon from "../../assets/svg/archiveIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";

export default function WarnNoteCard({
  note,
  description,
  date,
  onClickHandler,
  onClickStatusHandler,
  wid,
  status,
  isLoadingArchive,
  isLoadingDelete,
}) {
  return (
    <div className="relative border-[1px] border-gray shadow-inputShadow rounded-2xl p-4 space-y-2 ">
      <div className="text-primary-teal font-semibold">{note}</div>
      <div className="text-primary-black text-sm">{description}</div>
      <div className="flex gap-[2rem] text-primary-gray-light text-sm">
        <span>{date.split("\n")[0]}</span>
        <span>{date.split("\n")[1]}</span>
      </div>
      <div className="absolute right-2 bottom-2 flex space-x-4">
        <Button
          innerText="Delete"
          Icon={DeleteIcon}
          iconClass="w-[1rem] h-[1rem]"
          extraClass={`shadow-button bg-primary-white w-fit px-4 border-[1px] border-primary-red  rounded-full py-1 text-[0.9rem] text-primary-red`}
          onClick={() => {
            onClickHandler(wid);
          }}
          disabled={isLoadingDelete}
        />
        {
          <Button
            innerText={status === "active" ? "Archive" : "UnArchive"}
            Icon={ArchiveIcon}
            iconClass="w-[1.2rem] h-[1.2rem]"
            extraClass={`shadow-button bg-primary-white w-fit px-4 rounded-full py-1 text-[0.9rem] text-primary-teal`}
            onClick={() => {
              onClickStatusHandler(wid);
            }}
            disabled={isLoadingArchive}
          />
        }
      </div>
    </div>
  );
}
