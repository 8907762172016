import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "../../utils/helpers/axios";
import { OAUTH_FALLBACK_URL } from "../../utils/helpers/url";

import ProCamLogo from "../../assets/svg/proCamLogo";
import LoginBackgroundImage from "../../assets/images/loginBackgroundImage.png";

export default function Login() {
  const { role } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const forgotPasswordAPI = async (email) => {
    const url = `${OAUTH_FALLBACK_URL}/${role}`;

    setIsLoading(true);
    try {
      const response = await axios.post(`/api/auth/forgotPassword`, {
        email: email,
        fallBackURL: url,
      });
      if (response?.data?.success) {
        console.log(response, "forgot password api response");
        if (response.data?.message) {
          toast.success(response?.data?.message);
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err.response?.data?.message
          : "Something went wrong, please try again later"
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col sm:space-y-0 sm:flex-row justify-center items-center sm:relative sm:px-5 sm:py-3 ">
      <ProCamLogo
        onClick={() => navigate("/")}
        className="cursor-pointer w-[150px] h-[80px] xl:w-[180px] xl:h-[100px] 5xl:w-[250px] 5xl:h-[180px] 6xl:w-[250px] 6xl:h-[250px] absolute sm:left-24 sm:-translate-x-2/4 top-2 sm:top-3 xl:left-10 6xl:left-16 xl:translate-x-0 text-primary-teal"
      />
      <div className="absolute bottom-0 left-0 w-full select-none">
        <img src={LoginBackgroundImage} alt="" />
      </div>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const { email } = values;
          console.log(email, "email");
          forgotPasswordAPI(email);
          setSubmitting(false);
          resetForm({ email: "" });
        }}
      >
        {(formik) => {
          return (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <div className="z-10 sm:mt-10 w-[90%] xs:w-[75%] sm:w-[45%] lg:w-[40%] 2xl:w-[35%] 5xl:w-[30%] 6xl:w-[28%] p-5 sm:py-7 xl:p-10 6xl:p-12 h-fit bg-gradient-primary rounded-2xl flex flex-col items-center space-y-7 5xl:space-y-8 6xl:space-y-10">
                <div className="text-center">
                  <div className="text-white text-[1.1rem] xxs:text-[1.35rem] sm:text-[1.45rem] lg:text-[1rem]  2xl:text-[1.5rem] 5xl:text-[2rem] 6xl:text-[3rem]">
                    Forgot your Password ?
                  </div>
                  <div className="w-[80%] mx-auto text-white font-light text-[0.6rem] xxs:text-[0.7rem] sm:text-[0.75rem] lg:text-[1rem]  xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem]">
                    Please Enter your registered Email Id to receive reset link
                  </div>
                </div>
                <form
                  className="w-full flex flex-col space-y-7 5xl:space-y-8 6xl:space-y-12"
                  onSubmit={formik.handleSubmit}
                >
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    error={formik.touched.email && formik.errors.email}
                    placeholder="Enter your Registered Email"
                    extraClass="w-full"
                    inputFieldClass="w-full px-5 border-white border-[1px] rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-5 text-white text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] placeholder:text-white placeholder:font-light font-light"
                    {...formik.getFieldProps("email")}
                  />

                  <div className="w-full flex justify-center">
                    <Button
                      type="submit"
                      innerText="Submit"
                      isLoading={isLoading}
                      disabled={isLoading}
                      extraClass="shadow-button bg-white w-full rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-4 text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] uppercase text-[#0080A2]"
                    />
                  </div>
                </form>

                <div className="text-sm xl:text-base 5xl:text-xl 6xl:text-2xl text-white cursor-pointer font-light">
                  Remember Password?{" "}
                  <Link
                    to={`${role === "master-admin" ? "/login-admin" : "/"}`}
                    className="text-[#186095] underline"
                  >
                    Sign in!
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
