import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import Header from "../Header/Header";
import LabelValueField from "../LabelValueField/LabelValueField";
import Button from "../Button/Button";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";

import PropertiesIcon from "../../assets/svg/propertiesIcon";
import UserIcon from "../../assets/svg/userIcon";
import Modal from "react-responsive-modal";
import { useDispatch } from "react-redux";

export default function AddProperty() {
  const { id, pid, oid } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const [searchParam] = useSearchParams();
  const companyAdmin = searchParam.get("companyAdmin");
  const editPropertyDetails = searchParam.get("editPropertyDetails");
  const dispatch = useDispatch();

  const [community, setCommunity] = useState();
  const [propertyData, setPropertyData] = useState({
    streetNumber: "",
    streetName: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    tenantOccupied: "no",
  });
  const [ownerData, setOwnerData] = useState({
    firstOwnerFirstName: "",
    firstOwnerLastName: "",
    secondOwnerFirstName: "",
    secondOwnerLastName: "",
    mobileNumber: "",
    email: "",
    isMailingAddressDifferent: false,
  });
  const [alternateAddressData, setAlternateAddressData] = useState({
    firstOwnerFirstName: "",
    firstOwnerLastName: "",
    secondOwnerFirstName: "",
    secondOwnerLastName: "",
    streetNumber: "",
    streetName: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [open, setOpen] = useState(false);

  const onChangePropertyData = (e) => {
    const { name, value } = e.target;

    setPropertyData((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeOwnerData = (e) => {
    const { name, value } = e.target;

    setOwnerData((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeAlternateData = (e) => {
    const { name, value } = e.target;

    setAlternateAddressData((prev) => ({ ...prev, [name]: value }));
  };

  const getMailingAddress = () => {
    if (ownerData.isMailingAddressDifferent) {
      const mailingData = [
        alternateAddressData.firstOwnerFirstName,
        alternateAddressData.firstOwnerLastName,
        alternateAddressData.streetNumber,
        alternateAddressData.streetName,
        alternateAddressData.city,
        alternateAddressData.state,
        alternateAddressData.zip,
      ];

      const check = mailingData.every((item) => item);

      if (check)
        return `${alternateAddressData.firstOwnerFirstName} ${
          alternateAddressData.firstOwnerLastName
        }  ${
          alternateAddressData.secondOwnerFirstName
            ? `& ${alternateAddressData.secondOwnerFirstName} ${alternateAddressData.secondOwnerLastName}`
            : ""
        } ${
          ", " +
          alternateAddressData.streetNumber +
          " " +
          alternateAddressData.streetName
        }
      ${", " + alternateAddressData.city}
      ${", " + alternateAddressData.state}
      ${", " + alternateAddressData.zip}`;
    } else {
      const mailingData = [
        ownerData.firstOwnerFirstName,
        ownerData.firstOwnerLastName,
        propertyData.streetNumber,
        propertyData.streetName,
        propertyData.city,
        propertyData.state,
        propertyData.zip,
      ];
      const check = mailingData.every((item) => item);

      if (check)
        return `${ownerData.firstOwnerFirstName} ${
          ownerData.firstOwnerLastName
        } ${
          ownerData.secondOwnerFirstName
            ? ` &  ${ownerData.secondOwnerFirstName}`
            : ""
        } ${ownerData.secondOwnerLastName} ${
          ", " + propertyData.streetNumber + " " + propertyData.streetName
        }
      ${", " + propertyData.city}
      ${", " + propertyData.state}
      ${", " + propertyData.zip} `;
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPropertyDetails = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&sortFields=streetName&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        const property = data.properties[0];
        const owner = property.propertyOwnerShip[0];

        setPropertyData(
          Object.keys(propertyData).reduce((acc, key) => {
            if (key in property) acc[key] = property[key];
            return acc;
          }, {})
        );

        setOwnerData(
          Object.keys(ownerData).reduce((acc, key) => {
            if (key in owner) acc[key] = owner[key];
            return acc;
          }, {})
        );

        if (owner.alternateAddress)
          setAlternateAddressData(owner.alternateAddress);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addProperty = async () => {
    const filteredOwnerData = Object.entries(ownerData).reduce(
      (acc, [key, val]) => {
        if (val !== "") acc[key] = val;
        return acc;
      },
      {}
    );

    const payload = {
      communityId: id,
      propertyDetails: Object.entries(propertyData).reduce(
        (acc, [key, val]) => {
          if (val) acc[key] = val;
          return acc;
        },
        {}
      ),
      ownerDetails: {
        ...filteredOwnerData,
        ...(ownerData.isMailingAddressDifferent && {
          alternateAddress: Object.entries(alternateAddressData).reduce(
            (acc, [key, val]) => {
              if (val) acc[key] = val;
              return acc;
            },
            {}
          ),
        }),
      },
    };

    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/property/add", payload);

      if (data.success) {
        toast.success(data.message);
        if (companyAdmin) navigate("/company/properties");
        else navigate(`/community/${id}/properties`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteproperty = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/property/delete?propertyId=${pid}`
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/properties`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const updatePropertyDetails = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.patch(
        "/api/property/updatePropertyDetails",
        { propertyId: pid, propertyDetails: propertyData }
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/property-details/${pid}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const updateOwnerDetails = async () => {
    try {
      setIsLoadingUpdate(true);
      const filteredOwnerData = Object.entries(ownerData).reduce(
        (acc, [key, val]) => {
          if (val !== "") acc[key] = val;
          return acc;
        },
        {}
      );

      const { data } = await axios.patch("/api/property/updateOwnerDetails", {
        propertyId: pid,
        propertyOwnerShipId: oid,
        ownerDetails: {
          ...filteredOwnerData,
          ...(ownerData.isMailingAddressDifferent && {
            alternateAddress: Object.entries(alternateAddressData).reduce(
              (acc, [key, val]) => {
                if (val) acc[key] = val;
                return acc;
              },
              {}
            ),
          }),
        },
      });

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/property-details/${pid}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (pid && id) getPropertyDetails();
  }, [id, pid]);

  return (
    <div className="w-full">
      <Header
        title={pid ? "Edit Property Details" : "Add Property"}
        buttonText={community?.legalName}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 gap-4"
      >
        <div className="flex grow">
          <div className="w-full lg:w-1/2 p-4 pb-0">
            <div className={oid ? "opacity-[0.5]" : ""}>
              <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                <PropertiesIcon />
                <span className="ml-1">Property Address</span>
              </div>
              <div className="p-1 pt-4">
                <LabelValueField
                  onChange={onChangePropertyData}
                  label="Street No."
                  value={propertyData.streetNumber}
                  isEditable={!oid}
                  name={"streetNumber"}
                />
                <LabelValueField
                  onChange={onChangePropertyData}
                  label="Street Name"
                  value={propertyData.streetName}
                  isEditable={!oid}
                  name={"streetName"}
                />

                <LabelValueField
                  onChange={onChangePropertyData}
                  label="Address Line 2"
                  value={propertyData.addressLine2}
                  isEditable={!oid}
                  name={"addressLine2"}
                />

                <div className="flex justify-between">
                  <LabelValueField
                    onChange={onChangePropertyData}
                    label="City"
                    value={propertyData.city}
                    isEditable={!oid}
                    name={"city"}
                  />
                  <LabelValueField
                    onChange={onChangePropertyData}
                    label="State"
                    value={propertyData.state}
                    isEditable={!oid}
                    name={"state"}
                  />
                  <LabelValueField
                    onChange={onChangePropertyData}
                    label="Zip"
                    value={propertyData.zip}
                    isEditable={!oid}
                    name={"zip"}
                  />
                </div>
                <div className=" flex items-center gap-x-4">
                  <div className={`text-sm text-primary-teal font-semibold `}>
                    Tenant Occupied
                  </div>

                  <input
                    type="checkbox"
                    checked={
                      propertyData.tenantOccupied === "yes" ? true : false
                    }
                    onChange={(e) =>
                      setPropertyData((prev) => ({
                        ...prev,
                        tenantOccupied: e.target.checked ? "yes" : "no",
                      }))
                    }
                  />
                </div>
              </div>
            </div>
            <div className={editPropertyDetails ? "opacity-[0.5]" : ""}>
              <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1 pt-2">
                <UserIcon />
                <span className="ml-1">Owner's Details</span>
              </div>
              <div className="p-1 py-4 3xl:space-y-2 ">
                {pid && (
                  <div className="flex gap-[1rem] justify-between ">
                    <LabelValueField
                      label="Owner's Token ID"
                      value={propertyData.ownershipAccessCode}
                      isEditable={!editPropertyDetails}
                      extraClass={"w-1/2"}
                    />
                    <Button
                      innerText={`Generate New Token ID`}
                      extraClass={`shadow-button bg-primary-white px-4 py-1 border-primary-teal rounded-full text-[0.9rem] text-primary-teal`}
                      disabled={editPropertyDetails}
                    />
                  </div>
                )}
                <div className="flex space-x-2">
                  <div className="w-1/2 -ml-2">
                    <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                      <span className="ml-1">First Owner</span>
                    </div>
                    <div className="p-2 3xl:space-y-2">
                      <LabelValueField
                        onChange={onChangeOwnerData}
                        label="First Name"
                        value={ownerData.firstOwnerFirstName}
                        isEditable={!editPropertyDetails}
                        name={"firstOwnerFirstName"}
                      />
                      <LabelValueField
                        onChange={onChangeOwnerData}
                        label="Last Name"
                        value={ownerData.firstOwnerLastName}
                        isEditable={!editPropertyDetails}
                        name={"firstOwnerLastName"}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 ">
                    <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                      <span className="ml-1">Second Owner</span>
                    </div>
                    <div className="p-2 3xl:space-y-2">
                      <LabelValueField
                        onChange={onChangeOwnerData}
                        label="First Name"
                        value={ownerData.secondOwnerFirstName}
                        isEditable={!editPropertyDetails}
                        name={"secondOwnerFirstName"}
                      />
                      <LabelValueField
                        onChange={onChangeOwnerData}
                        label="Last Name"
                        value={ownerData.secondOwnerLastName}
                        isEditable={!editPropertyDetails}
                        name={"secondOwnerLastName"}
                      />
                    </div>
                  </div>
                </div>
                <div className="-ml-2">
                  <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                    <span className="ml-1">Details</span>
                  </div>
                  <div className="p-2 3xl:space-y-2">
                    <LabelValueField
                      onChange={onChangeOwnerData}
                      label="Mobile No."
                      value={ownerData.mobileNumber}
                      isEditable={!editPropertyDetails}
                      name={"mobileNumber"}
                    />
                    <LabelValueField
                      onChange={onChangeOwnerData}
                      label="Email"
                      value={ownerData.email}
                      isEditable={!editPropertyDetails}
                      name={"email"}
                    />
                    {pid && (
                      <LabelValueField
                        label="Mailing Address"
                        value={getMailingAddress()}
                      />
                    )}

                    <div className="mt-4">
                      <input
                        type="checkbox"
                        checked={ownerData.isMailingAddressDifferent}
                        onChange={(e) =>
                          setOwnerData((prev) => ({
                            ...prev,
                            isMailingAddressDifferent: e.target.checked,
                          }))
                        }
                        disabled={editPropertyDetails}
                      />
                      <span className="text-secondary-gray pl-2">
                        Check this, if Owner’s Mailing Address is different from
                        Property Address
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 p-4 pb-0">
            <div
              className={
                !ownerData.isMailingAddressDifferent
                  ? "opacity-0"
                  : editPropertyDetails
                  ? "opacity-[0.5]"
                  : ""
              }
            >
              <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                <UserIcon />
                <span className="ml-1">Alternate Address Details</span>
              </div>
              <div className="p-1 py-4 3xl:space-y-2 ">
                <div className="flex space-x-2">
                  <div className="w-1/2 -ml-2">
                    <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                      <span className="ml-1">First Owner</span>
                    </div>
                    <div className="p-2 3xl:space-y-2">
                      <LabelValueField
                        onChange={onChangeAlternateData}
                        label="First Name"
                        value={alternateAddressData.firstOwnerFirstName}
                        isEditable={!editPropertyDetails}
                        name={"firstOwnerFirstName"}
                        disabled={!ownerData.isMailingAddressDifferent}
                      />
                      <LabelValueField
                        onChange={onChangeAlternateData}
                        label="Last Name"
                        value={alternateAddressData.firstOwnerLastName}
                        isEditable={!editPropertyDetails}
                        name={"firstOwnerLastName"}
                        disabled={!ownerData.isMailingAddressDifferent}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 ">
                    <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                      <span className="ml-1">Second Owner</span>
                    </div>
                    <div className="p-2 3xl:space-y-2">
                      <LabelValueField
                        onChange={onChangeAlternateData}
                        label="First Name"
                        value={alternateAddressData.secondOwnerFirstName}
                        isEditable={!editPropertyDetails}
                        name={"secondOwnerFirstName"}
                        disabled={!ownerData.isMailingAddressDifferent}
                      />
                      <LabelValueField
                        onChange={onChangeAlternateData}
                        label="Last Name"
                        value={alternateAddressData.secondOwnerLastName}
                        isEditable={!editPropertyDetails}
                        name={"secondOwnerLastName"}
                        disabled={!ownerData.isMailingAddressDifferent}
                      />
                    </div>
                  </div>
                </div>
                <div className="-ml-2">
                  <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light p-1">
                    <span className="ml-1">Details</span>
                  </div>
                  <div className="p-2 3xl:space-y-2">
                    <LabelValueField
                      onChange={onChangeAlternateData}
                      label="Street No."
                      value={alternateAddressData.streetNumber}
                      isEditable={!editPropertyDetails}
                      name={"streetNumber"}
                      disabled={!ownerData.isMailingAddressDifferent}
                    />
                    <LabelValueField
                      onChange={onChangeAlternateData}
                      label="Street Name"
                      value={alternateAddressData.streetName}
                      isEditable={!editPropertyDetails}
                      name={"streetName"}
                      disabled={!ownerData.isMailingAddressDifferent}
                    />
                    <LabelValueField
                      onChange={onChangeAlternateData}
                      label="Address Line 2"
                      value={alternateAddressData.addressLine2}
                      isEditable={!editPropertyDetails}
                      name={"addressLine2"}
                      disabled={!ownerData.isMailingAddressDifferent}
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <LabelValueField
                    onChange={onChangeAlternateData}
                    label="City"
                    value={alternateAddressData.city}
                    isEditable={!editPropertyDetails}
                    name={"city"}
                    disabled={!ownerData.isMailingAddressDifferent}
                  />
                  <LabelValueField
                    onChange={onChangeAlternateData}
                    label="State"
                    value={alternateAddressData.state}
                    isEditable={!editPropertyDetails}
                    name={"state"}
                    disabled={!ownerData.isMailingAddressDifferent}
                  />
                  <LabelValueField
                    onChange={onChangeAlternateData}
                    label="Zip"
                    value={alternateAddressData.zip}
                    isEditable={!editPropertyDetails}
                    name={"zip"}
                    disabled={!ownerData.isMailingAddressDifferent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 justify-between ">
          <div>
            {pid && (
              <Button
                innerText={"Delete Property"}
                extraClass={`shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full text-[0.8rem] uppercase text-primary-red px-4 py-2`}
                onClick={() => setOpen(true)}
                isLoading={isLoadingDelete}
              />
            )}
          </div>
          <div className="flex gap-4 justify-end pb-[0.5rem]">
            <Button
              innerText={`Cancel`}
              extraClass={`shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full text-[0.8rem] uppercase text-primary-red px-4 py-2`}
              onClick={() => {
                if (companyAdmin) navigate("/company/properties");
                else navigate(`/community/${id}/properties`);
              }}
            />
            {pid ? (
              <Button
                type="submit"
                innerText={"Save Changes"}
                isLoading={isLoadingUpdate}
                spinnerLight={true}
                onClick={
                  editPropertyDetails
                    ? updatePropertyDetails
                    : updateOwnerDetails
                }
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
              />
            ) : (
              <Button
                type="submit"
                innerText={"Submit"}
                isLoading={isLoading}
                spinnerLight={true}
                onClick={addProperty}
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl p-4 flex flex-col">
          <div className="text-secondary-gray text-sm">
            Deleting a property will permanently remove all associated data from
            the system. This action cannot be undone.
          </div>
          <div className="text-primary-teal text-sm">
            Do you wish to continue?
          </div>
          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => setOpen(false)}
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
            />
            <Button
              onClick={() => {
                deleteproperty();
              }}
              isLoading={isLoadingDelete}
              disabled={isLoadingDelete}
              innerText="Yes"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
