import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "../features/auth-slice";
import { authSlice as masterAuthSlice } from "../features/masterAuthSlice";
import { communitySlice } from "../features/communitySlice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    adminAuth: masterAuthSlice.reducer,
    community: communitySlice.reducer,
  },
});
