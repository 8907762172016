import React from "react";

function EmailIcon({className,...rest}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    className={`${className}`}
    {...rest}
  >
    <path
      fill="currentColor"
      d="M8 1C4.142 1 1 4.142 1 8s3.142 7 7 7h3.5v-1.4H8A5.589 5.589 0 012.4 8c0-3.101 2.499-5.6 5.6-5.6 3.101 0 5.6 2.499 5.6 5.6v1.05a1.04 1.04 0 01-1.05 1.05 1.04 1.04 0 01-1.05-1.05V8A3.51 3.51 0 008 4.5 3.51 3.51 0 004.5 8 3.51 3.51 0 008 11.5c.99 0 1.882-.42 2.52-1.087a2.454 2.454 0 002.03 1.087A2.46 2.46 0 0015 9.05V8c0-3.858-3.142-7-7-7zm0 4.9c1.168 0 2.1.932 2.1 2.1A2.09 2.09 0 018 10.1 2.09 2.09 0 015.9 8c0-1.168.932-2.1 2.1-2.1z"
    ></path>
  </svg>
  );
}

export default EmailIcon;