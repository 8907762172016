import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

import Input from "../../components/Input/Input";
import Pagination from "../../components/Pagination/Pagination";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Table from "../../components/Table/Table";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { letterLibraryColumn } from "../../constants/TableColumns/Columns";
import axios from "../../utils/helpers/adminAxios";

import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";

export default function LetterLibrary() {
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [letterLibraries, setLetterLibraries] = useState([]);
  const [totalLetterLibrary, setTotalLetterLibrary] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLetterLibraries, setSelectedLetterLibraries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectHandler = (e, row) => {
    if (e.target.checked)
      setSelectedLetterLibraries((prev) => [
        ...prev,
        { id: row._id, title: row.title, tags: row.tags, content: row.content },
      ]);
    else {
      setSelectedLetterLibraries(
        selectedLetterLibraries.filter((item) => item.id !== row._id)
      );
    }
  };

  const onEditHandler = (row) => {
    navigate(`/letter-template/edit/${row._id}`);
  };

  const getLetterLibrary = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/admin/getLetterTemplate?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }`
      );

      if (data.success) {
        setLetterLibraries(data.letterTemplates);
        setTotalLetterLibrary(data.matchedLetterTemplatesCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSelectedLetter = async () => {
    try {
      setIsLoadingDelete(true);

      const selectedLetterLibrariesId = selectedLetterLibraries.map(
        (letterLibrary) => letterLibrary.id
      );

      const { data } = await axios.delete(
        `/admin/deleteLetterTemplate?letterTemplateIds=${selectedLetterLibrariesId.join(
          ","
        )}`
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedLetterLibraries([]);
        getLetterLibrary();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    getLetterLibrary();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    if (searchQuery.length === 0) getLetterLibrary();
    if (searchQuery.length > 2) getLetterLibrary();
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  return (
    <div className="w-full">
      <Header title="ProCAM Letter Library" />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="flex gap-4">
          <div className="grow">
            <Input
              iconClass="absolute left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search Letter Template"
              inputFieldClass="placeholder:text-primary-gray-light"
              extraClass="relative w-full py-[0.1rem] rounded-full px-12 border-gray border-[1px]"
              onChange={(e) => onChange(e)}
            />
          </div>

          <div className="min-w-fit">
            <Link to="/letter-template">
              <Button
                Icon={AddIcon}
                iconClass=" w-[0.8rem] h-[0.8rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Create New Letter Template"
              />
            </Link>
          </div>
        </div>

        <div className="overflow-auto grow px-2 min-h-[6rem] flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={letterLibraryColumn(
                selectedLetterLibraries,
                onSelectHandler,
                onEditHandler,
                letterLibraries,
                setSelectedLetterLibraries
              )}
              tableData={letterLibraries}
            />
          )}
        </div>

        <div>
          <Button
            Icon={DeleteIcon}
            iconClass="w-[0.8rem] h-[0.8rem]"
            extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
            innerText="Delete Selected"
            isLoading={isLoadingDelete}
            spinnerLight={true}
            disabled={isLoadingDelete || selectedLetterLibraries.length === 0}
            onClick={deleteSelectedLetter}
          />
        </div>
        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalLetterLibrary}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
