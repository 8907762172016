import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const DatePicker = ({ handleDatePicker, setActiveCalendar, value }) => {
  const [date, setDate] = useState(value || new Date());

  function onChange(date) {
    setDate(date);
    handleDatePicker(date);
    setActiveCalendar(false);
  }

  return (
    <Calendar date={date} onChange={onChange} minDate={new Date(Date.now())} />
  );
};

export default DatePicker;
