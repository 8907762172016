import PhotoRightArrow from "../../assets/svg/photoRightArrow";
import PhotoLeftArrow from "../../assets/svg/photoLeftArrow";

const CustomButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  return (
    <div className="flex absolute top-0 right-0">
      <PhotoLeftArrow
        onClick={() => previous()}
        disabled={currentSlide === 0}
        style={{ cursor: "pointer" }}
      />
      <PhotoRightArrow
        onClick={() => next()}
        disabled={
          currentSlide ===
          rest.carouselState.totalItems - rest.carouselState.slidesToShow
        }
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default CustomButtonGroup;
