import React from "react";

function JobIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M7.552 1c-.557 0-1.009.464-1.009 1.037H2.51c-.558 0-1.009.464-1.009 1.037v4.667c0 .573.451 1.037 1.009 1.037h7.463a1.516 1.516 0 011.157-.548c.107 0 .215.013.32.036a1.52 1.52 0 011.146-.525c.44 0 .854.19 1.146.525.105-.023.213-.036.32-.036.137 0 .269.025.396.06a1.05 1.05 0 00.156-.55V3.075c0-.573-.452-1.037-1.01-1.037H9.57C9.57 1.464 9.118 1 8.561 1H7.552zm.504 5.704c.279 0 .505.232.505.518a.512.512 0 01-.505.519.512.512 0 01-.504-.519c0-.286.226-.518.504-.518zm4.54 2.074a.524.524 0 00-.412.2l-.365.455-.556-.148a.535.535 0 00-.665.497l-.035.59-.535.216a.553.553 0 00-.254.803l.309.498-.309.498a.554.554 0 00.254.804l.535.216.035.59c.02.344.341.582.665.496l.556-.148.365.456a.523.523 0 00.822 0l.365-.456.556.148a.536.536 0 00.666-.496l.034-.59.535-.216a.553.553 0 00.254-.804l-.308-.498.308-.498a.555.555 0 00-.254-.804l-.535-.217-.034-.588a.536.536 0 00-.666-.498l-.556.148-.365-.454a.524.524 0 00-.41-.2zM1.5 9.526v1.845c0 .573.451 1.037 1.009 1.037h6.204c.031-.18.084-.356.176-.519a1.619 1.619 0 01.43-2.074h-6.81c-.37 0-.71-.11-1.009-.289z"
      ></path>
    </svg>
  );
}

export default JobIcon;