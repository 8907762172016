import LabelValueField from "../LabelValueField/LabelValueField";

function OwnerHistoryCard({ ownerHistory }) {
  return (
    <div className="rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg p-[1.4rem] flex flex-col gap-[0.8rem]">
      <LabelValueField
        label="Name"
        value={
          ownerHistory.firstOwnerFirstName +
          " " +
          ownerHistory.firstOwnerLastName
        }
      />
      <LabelValueField label="Phone" value={ownerHistory.mobileNumber} />

      <LabelValueField label="Email" value={ownerHistory.email} />

      <LabelValueField
        label="Mailing Address"
        value={
          ownerHistory.alternateAddress
            ? `${ownerHistory.alternateAddress.firstOwnerFirstName} ${
                ownerHistory.alternateAddress.firstOwnerLastName
              } & ${ownerHistory.alternateAddress.secondOwnerFirstName} ${
                ownerHistory.alternateAddress.secondOwnerLastName
              } ${
                ownerHistory.alternateAddress.addressLine2
                  ? `, ${ownerHistory.alternateAddress.addressLine2}`
                  : ""
              }`
            : `${ownerHistory.firstOwnerFirstName} ${
                ownerHistory.firstOwnerLastName
              } & ${ownerHistory.secondOwnerFirstName} ${
                ownerHistory.secondOwnerLastName
              } ${
                ownerHistory.addressLine2
                  ? `, ${ownerHistory.addressLine2}`
                  : ""
              }`
        }
      />
    </div>
  );
}

export default OwnerHistoryCard;
