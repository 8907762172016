const SwitchInputv2 = ({
  id,
  label,
  isChecked,
  isDisabled,
  onChange,
  className = "",
  labelClass = "",
  description = "",
  descriptionClass = "",
}) => {
  return (
    <div className={`space-x-3 flex items-center ${className}`}>
      <label
        htmlFor={id}
        className="flex flex-col flex-wrap items-center cursor-pointer mb-4 md:mb-0 "
      >
        <div className="relative">
          <input
            id={id}
            type="checkbox"
            className="hidden"
            checked={isChecked}
            disabled={isDisabled}
            onChange={(e) => {
              onChange(id, e.target.checked ? "add" : "remove");
            }}
          />
          <div
            className={`toggle__line w-8 h-4 rounded-full shadow-inner ${
              isChecked ? "bg-primary-green" : "bg-primary-gray-light"
            }`}
          ></div>

          <div className="toggle__dot absolute w-3 h-3 bg-white rounded-full shadow inset-y-0 left-0"></div>
        </div>
      </label>
      <div className="">
        <div className={`text-sm text-primary-black ${labelClass}`}>
          {label}
        </div>
        <span
          className={`text-sm text-secondary-gray-light ${descriptionClass}`}
        >
          {description}
        </span>
      </div>
    </div>
  );
};
export default SwitchInputv2;
