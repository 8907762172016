import React from "react";

function SortingIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="#237078"
        fillRule="evenodd"
        d="M15 8A7 7 0 111 8a7 7 0 0114 0zM9.591 9.543a1 1 0 111.416 1.416l-2.3 2.3a1 1 0 01-1.414 0l-2.3-2.3A1.001 1.001 0 016.41 9.543L8 11.134l1.591-1.59zM8 4.917l1.591 1.591a1 1 0 101.416-1.415l-2.3-2.3a1 1 0 00-1.414 0l-2.3 2.3A1 1 0 006.41 6.508L8 4.917z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SortingIcon;