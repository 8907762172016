export default function Activitylist() {
  return (
    <div className="w-full flex justify-between border-b-[0.5px] border-gray py-4 text-primary-black text-[1rem]">
      <div className="flex flex-col ">
        <span className="font-semibold ">Northdale Civic Association</span>
        <span className="font-light ">Aditya has 4002 Dellbrooke</span>
      </div>
      <div className="flex flex-col items-end font-light">
        <span>02/11/2024</span>
        <span>04:36:57</span>
      </div>
    </div>
  );
}
