import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.47787 13.7504C5.9325 14.2836 5 13.906 5 13.1518L5 2.84822C5 2.09405 5.9325 1.71636 6.47787 2.24964L11.7464 7.40142C12.0845 7.732 12.0845 8.268 11.7464 8.59858L6.47787 13.7504Z"
      fill="#B3B3B3"
    />
  </svg>
);
export default SVGComponent;
