import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Formik } from "formik";
import * as Yup from "yup";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "../../utils/helpers/axios";

import ProCamLogo from "../../assets/svg/proCamLogo";
import LoginBackgroundImage from "../../assets/images/loginBackgroundImage.png";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { resetToken, email, role } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const resetPasswordAPI = async (password) => {
    setIsLoading(true);
    const url =
      role === "master-admin"
        ? "/admin/resetPassword"
        : "/api/auth/resetPassword";

    try {
      const response = await axios.post(url, {
        email: email,
        password: password,
        token: resetToken,
      });

      if (response?.data?.success) {
        if (response?.data?.message) {
          toast.success(response?.data?.message);
        }

        setIsLoading(false);
        if (role === "master-admin") {
          navigate("/login-admin");
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err.response?.data?.message
          : "Something went wrong, please try again later"
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col sm:space-y-0 sm:flex-row justify-center items-center sm:relative sm:px-5 sm:py-3">
      <ProCamLogo
        onClick={() => navigate("/")}
        className="cursor-pointer w-[150px] h-[80px] xl:w-[180px] xl:h-[100px] 5xl:w-[250px] 5xl:h-[180px] 6xl:w-[250px] 6xl:h-[250px] absolute sm:left-24 sm:-translate-x-2/4 top-2 sm:top-3 xl:left-10 6xl:left-16 xl:translate-x-0 text-primary-teal"
      />
      <div className="absolute bottom-0 left-0 w-full select-none">
        <img src={LoginBackgroundImage} alt="" />
      </div>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required("New password is required")
            .min(8, "Password must contain 8 characters")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
          confirmPassword: Yup.string()
            .required("Confirm password is required")
            .test("password-match", "Passwords must match", function (value) {
              return this.parent.password === value;
            }),
        })}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const { password } = values;
          resetPasswordAPI(password);
          setSubmitting(false);
          resetForm();
        }}
      >
        {(formik) => {
          return (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <div className="z-10 mt-16 sm:mt-10 w-[90%] xs:w-[80%] sm:w-[50%] lg:w-[40%] 2xl:w-[35%] 4xl:w-[30%] 6xl:w-[33%] p-5 sm:py-7 xl:p-10 6xl:p-12 h-fit bg-gradient-primary rounded-2xl flex flex-col items-center space-y-4 5xl:space-y-8 6xl:space-y-10">
                <div className="text-center">
                  <div className="text-white text-[1.3rem] xs:text-[1.5rem] sm:text-[1rem] xl:text-[1.5rem] 5xl:text-[2.5rem] 6xl:text-[3rem]">
                    Reset your Password
                  </div>
                  <div className="text-white text-[0.8rem] sm:text-[1.2rem] xl:text-[1.25rem] 5xl:text-[1.75rem] 6xl:text-[2.5rem]">
                    Almost done !
                  </div>
                </div>
                <form
                  className="w-full flex flex-col space-y-4 5xl:space-y-6 6xl:space-y-8"
                  onSubmit={formik.handleSubmit}
                >
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    error={formik.touched.email && formik.errors.email}
                    placeholder={email ? email : "Email address"}
                    extraClass="w-full"
                    readOnly={true}
                    inputFieldClass="w-full px-5 border-white border-[1px] rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-5 text-primary-gray text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] placeholder:text-primary-gray placeholder:font-light font-light "
                    {...formik.getFieldProps("email")}
                  />
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    error={formik.touched.password && formik.errors.password}
                    placeholder="New Password"
                    extraClass="w-full"
                    inputFieldClass="w-full px-5 border-white border-[1px] rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-5 text-white text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] placeholder:text-white placeholder:font-light font-light "
                    {...formik.getFieldProps("password")}
                  />
                  <Input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    error={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    placeholder="Confirm Password"
                    extraClass="w-full"
                    inputFieldClass="w-full px-5 border-white border-[1px] rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-5 text-white text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] placeholder:text-white placeholder:font-light font-light "
                    {...formik.getFieldProps("confirmPassword")}
                  />
                  <div className="w-full flex justify-center">
                    <Button
                      type="submit"
                      innerText="Reset Password"
                      isLoading={isLoading}
                      disabled={isLoading}
                      extraClass="shadow-button bg-white w-full rounded-full py-2 sm:py-3.5 xl:py-4 6xl:px-8 6xl:py-4 text-[0.9rem] xl:text-[1rem] 5xl:text-[1.1rem] 6xl:text-[1.5rem] uppercase text-[#0080A2]"
                    />
                  </div>
                </form>

                <Link
                  to="/forgot-password"
                  className="text-sm xl:text-base 5xl:text-xl 6xl:text-2xl text-white cursor-pointer hover:underline font-light"
                >
                  Forgot Password ?
                </Link>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResetPassword;
