import Button from "../Button/Button";

function GeneralHeader({ title, icon, buttonText }) {
  return (
    <div className="flex justify-between pb-[0.5rem] border-b-2 border-primary-white">
      <div className="flex gap-[0.5rem] ">
        <div className="h-[2.2rem] w-[2.2rem] ">
          <img src={icon} alt="" className="w-full" />
        </div>
        <div className="text-[1.6rem] text-primary-white">{title}</div>
      </div>
      {buttonText && (
        <Button
          innerText={buttonText}
          extraClass={`shadow-button bg-primary-white w-fit px-4 border-[1px] border-gray rounded-full py-1 text-[0.9rem] text-primary-teal`}
        />
      )}
    </div>
  );
}

export default GeneralHeader;
