
const CheckboxInput = ({
  placeholder,
  handleIconClick,
  Icon,
  type,
  name,
  id,
  error,
  label,
  extraClass,
  inputFieldClass,
  iconClass,
  labelClass,
  message,
  required,
  info,
  outerClass,
  ...rest
}) => {
  return (
    <div className={`${outerClass}`} >
      {/* <div className={`form-group relative ${extraClass}`}>
        <input
          className={`bg-transparent focus:outline-none w-full py-[.4rem] 3xl:py-[0.5rem] text-sm lg:text-md 3xl:text-xl ${inputFieldClass} ${error?"border-primary-red":""}`}
          type="checkbox"
          placeholder={placeholder}
          name={name}
          id={id}
          // onfocus="(this.type='date')"
          {...rest}
        />
         <label for={id}>{label}</label>
      </div> */}
      <div className="relative">
      <label class="check">
        <input type="checkbox"/>
        <div class="box"></div>
      </label>
      </div>
      
    
    </div>
  );
};

export default CheckboxInput;
