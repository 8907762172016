import React from "react";

function LetterIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M1.4 0C.749 0 .205.482.05 1.128L7 5.795l6.955-4.654C13.804.488 13.255 0 12.6 0H1.4zM0 2.8v7.7c0 .827.628 1.5 1.4 1.5h11.2c.772 0 1.4-.673 1.4-1.5V2.817L7 7.5 0 2.8z"
      ></path>
    </svg>
  );
}

export default LetterIcon;