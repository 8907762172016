import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

function PropertyDropDown({ properties, isLoading, searchQuery }) {
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  const HighlightedText = ({ text }) => {
    const lowerCaseText = text.toLowerCase();
    const lowerCaseQuery = searchQuery.toLowerCase();

    return (
      <span
        className={
          lowerCaseText.includes(lowerCaseQuery) ? "text-primary-teal" : ""
        }
      >
        {text}
      </span>
    );
  };

  useEffect(() => {
    if (searchQuery.length > 2) setShowDropdown(true);
    else setShowDropdown(false);
  }, [searchQuery]);

  return (
    <>
      {showDropdown && (
        <div className="rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg absolute z-10 bg-primary-white w-full max-h-[25rem] min-h-fit overflow-auto flex flex-col mt-[1rem]">
          {isLoading && (
            <div className="flex justify-center items-center h-full p-[2rem]">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          {!isLoading &&
            properties.map((property) => {
              return (
                <div
                  className="flex gap-[4rem] items-center text-primary-gray border-b-2 py-[1rem] px-[2rem] mx-[1rem] hover:bg-primary-light-gray cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/community/${property.community}/property-details/${property._id}`
                    )
                  }
                >
                  <div className="text-wrap w-[15%]">
                    {property.communityDetail?.letterName}
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <HighlightedText
                        text={
                          property.propertyOwnerShip[0]?.firstOwnerFirstName
                        }
                      />{" "}
                      <HighlightedText
                        text={property.propertyOwnerShip[0]?.firstOwnerLastName}
                      />
                      {" & "}
                      <HighlightedText
                        text={
                          property.propertyOwnerShip[0]?.secondOwnerFirstName
                        }
                      />{" "}
                      <HighlightedText
                        text={
                          property.propertyOwnerShip[0]?.secondOwnerLastName
                        }
                      />
                    </div>
                    <div>
                      <HighlightedText text={property.streetNumber} />{" "}
                      <HighlightedText text={property.streetName} />
                    </div>
                    <div>
                      {property.city +
                        " " +
                        property.state +
                        " " +
                        property.zip}
                    </div>
                  </div>
                </div>
              );
            })}

          {!isLoading && properties.length === 0 && (
            <div className="text-primary-gray flex justify-center p-[2rem]">
              No Properties Found
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PropertyDropDown;
