import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import Table from "../../components/Table/Table";
import { companyColumns } from "../../constants/TableColumns/Columns";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Pagination from "../../components/Pagination/Pagination";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { getCompany } from "../../utils/helpers/commonFunctions";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";

import SearchIcon from "../../assets/svg/searchIcon";
import AddIcon from "../../assets/svg/addIcon";

export default function Company() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [companies, setCompanies] = useState();
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterCompanies, setFilterCompanies] = useState({
    filterFields: "active",
    filterValue: "true",
  });
  const [sortParam, setSortParam] = useState({
    sortFields: "updatedAt",
    sortOrder: "-1",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [pageConfig, setPageConfig] = useState({
    pageNum: 1,
    rowsPerPage: 25,
  });

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getCompanies = async () => {
    setIsLoading(true);

    const companiesResponse = await getCompany({
      sortParam,
      searchQuery,
      pageConfig,
      filterCompanies,
    });
    if (companiesResponse) {
      setCompanies(companiesResponse.companies);
      setTotalCompanies(companiesResponse.matchedCompaniesCount);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery.length === 0) getCompanies();
    if (searchQuery.length > 2) getCompanies();
  }, [searchQuery]);

  useEffect(() => {
    setIsLoading(true);
    try {
      getCompanies();
    } catch (error) {
      setIsLoading(false);
    }
  }, [filterCompanies, pageConfig]);

  useEffect(() => {
    setPageConfig((prev) => ({
      ...prev,
      pageNum: 1,
    }));
  }, [rowsPerPage]);

  return (
    <div className="w-full">
      <Header title="Companies" buttonText={"Master Admin"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full flex gap-4 items-center">
          <div className={"flex grow gap-4 items-center"}>
            <div className="grow">
              <Input
                onChange={(e) => onChange(e)}
                iconClass="absolute left-4 text-secondary-gray"
                Icon={SearchIcon}
                placeholder="Search Company"
                inputFieldClass="placeholder:text-primary-gray-light"
                extraClass="relative py-[0.1rem] rounded-full px-12 border-gray border-[1px]"
              />
            </div>
            <div className="flex items-center">
              <SwitchInputv2
                isChecked={filterCompanies.filterValue === "false"}
                onChange={(id, value) => {
                  if (value === "add")
                    setFilterCompanies({
                      filterFields: "active",
                      filterValue: "false",
                    });

                  if (value === "remove")
                    setFilterCompanies({
                      filterFields: "active",
                      filterValue: "true",
                    });
                }}
              />
              <div className="text-primary-gray text-[1rem]">Show Inactive</div>
            </div>
          </div>
          <div className="min-w-fit">
            <Link to="/companies/add">
              <Button
                Icon={AddIcon}
                iconClass="w-[0.8rem] h-[0.8rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 py-[.5rem] text-sm rounded-full"
                innerText="Add New Company"
              />
            </Link>
          </div>
        </div>

        <div className="overflow-auto px-2 min-h-[6rem] flex  justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <Table
              pageSize={pageConfig.rowsPerPage}
              COLUMNS={companyColumns}
              tableData={companies}
            />
          )}
          {!isLoading && companies?.length === 0 && (
            <div className="text-primary-gray flex justify-center pt-4">
              {" "}
              No Companies Found
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalCompanies}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
            setPageConfig({
              pageNum: page,
              rowsPerPage: rowsPerPage,
            });
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
            setPageConfig({
              pageNum: currentPage,
              rowsPerPage: rowsPerPage,
            });
          }}
        />
      </div>
    </div>
  );
}
