import React from "react";

function CommunitiesInspectionIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M3.62 1C2.72 1 2 1.7 2 2.575v10.85C2 14.3 2.72 15 3.62 15h8.76c.9 0 1.62-.7 1.62-1.575V2.575C14 1.7 13.28 1 12.38 1H9.8v1.167H6.2V1H3.62zm.18 2.333h8.4v9.917H3.8V3.333zm6.926 2.359L7.4 8.925 5.874 7.442l-.848.825L7.4 10.575l4.174-4.058-.848-.825z"
      ></path>
    </svg>
  );
}

export default CommunitiesInspectionIcon;