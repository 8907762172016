import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // communityId: "" || localStorage.getItem("selectedCommunityId"),
  communityId: "",
};

export const communitySlice = createSlice({
  name: "community",
  initialState: initialState,
  reducers: {
    setCommunityId: (state, action) => {
      state.communityId = action.payload;
    },
  },
});

export const { setCommunityId } = communitySlice.actions;

export default communitySlice.reducer;
