import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.52213 13.7504C10.0675 14.2836 11 13.906 11 13.1518L11 2.84822C11 2.09405 10.0675 1.71636 9.52213 2.24964L4.25356 7.40142C3.91548 7.732 3.91548 8.268 4.25356 8.59858L9.52213 13.7504Z"
      fill="#B3B3B3"
    />
  </svg>
);
export default SVGComponent;
