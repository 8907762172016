import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import Header from "../../components/Header/Header";
import LabelValueField from "../../components/LabelValueField/LabelValueField";
import axios from "../../utils/helpers/axios";
import { formatTimestamp } from "../../utils/helpers/dateFormat";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import Pagination from "../../components/Pagination/Pagination";
import OwnerHistoryCard from "../../components/OwnerHistoryCard/OwnerHistoryCard";
import { setCommunityId } from "../../redux/features/communitySlice";

export default function OwnersHistory() {
  const { id, pid } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [property, setProperty] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalOwnerHistory, setTotalOwnerHistory] = useState(0);
  const [ownerHistories, setOwnerHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOwnerHistory, setIsLoadingOwnerHistory] = useState(false);

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPropertyDetails = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&sortFields=streetName&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        setProperty(data.properties[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOwnerHistory = async () => {
    try {
      setIsLoadingOwnerHistory(true);

      const { data } = await axios.get(
        `/api/property/ownerHistories?propertyId=${pid}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1`
      );

      if (data.success) {
        setOwnerHistories(data.histories);
        setTotalOwnerHistory(data.matchedOwnerHistoryCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingOwnerHistory(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && pid) getPropertyDetails();
  }, [id, pid]);

  useEffect(() => {
    if (pid) getOwnerHistory();
  }, [pid, rowsPerPage, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Owner's History"
        address={
          isLoading
            ? " "
            : `${property.streetNumber} ${property.streetName},  ${
                property.addressLine2 ? property.addressLine2 + "," : ""
              }${property.city}, ${property.state}`
        }
        buttonText={community.legalName}
      />

      <div
        onClick={() => setToggleSidebar(false)}
        className="overflow-auto relative flex-1 flex flex-col py-[1rem] px-[2rem]"
      >
        {isLoadingOwnerHistory && (
          <div className="flex justify-center p-[2rem]">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}

        {!isLoadingOwnerHistory && (
          <div className="flex flex-col gap-[1.4rem]">
            {ownerHistories.map((ownerHistory) => {
              return <OwnerHistoryCard ownerHistory={ownerHistory} />;
            })}
          </div>
        )}
        {!isLoadingOwnerHistory && ownerHistories.length === 0 && (
          <div className="flex justify-center text-primary-gray">
            No previous Owners
          </div>
        )}
      </div>
      {ownerHistories.length !== 0 && (
        <div className="relative bg-white z-10 w-full pt-[1rem] px-[2rem]">
          <LabelValueField
            label="Last Ownership Changed"
            value={formatTimestamp(property.updatedAt)}
          />

          <Pagination
            isStatic={false}
            currentPage={currentPage}
            totalCount={totalOwnerHistory}
            pageSize={rowsPerPage}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          />
          <Pagination
            isStatic={true}
            currentPage={rowsPerPage}
            onPageChange={(rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
            }}
          />
        </div>
      )}
    </div>
  );
}
