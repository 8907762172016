import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

import UploadIcon from "../../assets/svg/uploadIcon.svg";
import Button from "../Button/Button";

function Basic({ uploadDocument, isLoading }) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();

  useEffect(() => {
    uploadDocument(acceptedFiles[0]);
  }, [acceptedFiles]);

  return (
    <section className="container">
      <div {...getRootProps({ className: "react_dropzone" })}>
        <input {...getInputProps()} />
        <div className="h-[6.4rem] rounded-xl shadow-inputShadow border-[1px] border-offWhite p-1 flex flex-col items-center justify-between p-4  bg-offWhite">
          <img
            src={UploadIcon}
            className="h-[20px] w-[20px] object-contain"
            alt=""
          />
          <div className="flex flex-col items-center">
            <div className="text-primary-black text-[0.8rem]">
              Drag and Drop to Upload New Document Files
            </div>
            <span className="text-primary-black text-[0.8rem]">OR</span>
          </div>
          <div>
            <Button
              type="submit"
              innerText="Browse File"
              extraClass="shadow-button border-[0.5px] border-primary-white bg-primary-white rounded-full px-2 text-primary-teal text-sm"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Basic;
