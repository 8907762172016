import React from "react";

function CompanyIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M1.6 1C.716 1 0 1.696 0 2.556v10.888C0 14.304.716 15 1.6 15h12.8c.884 0 1.6-.696 1.6-1.556V5.667c0-.86-.716-1.556-1.6-1.556H8V2.556C8 1.696 7.284 1 6.4 1H1.6zm0 1.556h1.6V4.11H1.6V2.556zm3.2 0h1.6V4.11H4.8V2.556zm-3.2 3.11h1.6v1.556H1.6V5.667zm3.2 0h1.6v1.556H4.8V5.667zm3.2 0h6.4v7.778H8V11.89h1.6v-1.556H8V8.778h1.6V7.222H8V5.667zm3.2 1.556v1.556h1.6V7.222h-1.6zM1.6 8.778h1.6v1.555H1.6V8.778zm3.2 0h1.6v1.555H4.8V8.778zm6.4 1.555v1.556h1.6v-1.556h-1.6zM1.6 11.89h1.6v1.555H1.6V11.89zm3.2 0h1.6v1.555H4.8V11.89z"
      ></path>
    </svg>
  );
}

export default CompanyIcon;