import { Stack } from "@mui/material";
import Button from "../Button/Button";

function MarginModel({
  margins,
  onChange,
  setShowMarginModel,
  setMargins,
  letterTemplate,
  edit,
}) {
  const marginTypes = ["top", "bottom", "left", "right"];

  return (
    <div className=" absolute right-[1rem] z-10 flex flex-col gap-[1rem] rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg p-[1rem] w-fit">
      <span className="text-secondary-gray font-semibold">Margins</span>
      <Stack spacing={1}>
        {marginTypes.map((type) => (
          <Stack direction={"row"} key={type} alignItems={"center"}>
            <span className="text-primary-teal font-semibold w-[5rem]">{`${
              type[0].toUpperCase() + type.slice(1)
            }`}</span>
            <input
              type="number"
              name={type}
              value={margins[type]}
              onChange={onChange}
              variant="standard"
              style={{
                border: "none",
                outline: "none",
                width: "3rem",
              }}
            />
          </Stack>
        ))}
      </Stack>
      <div className="flex gap-[1rem]">
        <Button
          innerText={"Ok"}
          extraClass={`shadow-button bg-primary-teal w-fit px-4 border-[1px] border-primary-teal rounded-full py-1 text-[0.9rem] captilize text-primary-white`}
          onClick={() => {
            setShowMarginModel(false);
          }}
        />
        <Button
          type="submit"
          innerText="Cancel"
          extraClass="shadow-button bg-primary-white w-fit px-4 border-[1px] border-primary-red mr-4 rounded-full py-1 text-[0.9rem] captilize text-primary-red"
          onClick={() => {
            if (edit) setMargins(letterTemplate.margins);
            else
              setMargins({
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              });
            setShowMarginModel(false);
          }}
        />
      </div>
    </div>
  );
}

export default MarginModel;
