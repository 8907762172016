import toast from "react-hot-toast";
import axios from "./axios";
import axiosAdmin from "./adminAxios";
import { QueryStatus } from "@reduxjs/toolkit/query";

//company
export const getCompany = async ({
  sortParam,
  searchQuery = "",
  pageConfig = "",
  filterCompanies = "",
}) => {
  const { sortFields, sortOrder } = sortParam;
  const { filterFields, filterValue } = filterCompanies;
  const { pageNum, rowsPerPage } = pageConfig;
  try {
    const response = await axiosAdmin.get(
      `/admin/getCompanies?${pageNum ? `pageNum=${pageNum}` : ""}${
        rowsPerPage ? `&rowsPerPage=${rowsPerPage}` : ""
      }${sortParam ? `&sortFields=${sortFields}` : ""}${
        sortParam ? `&sortOrders=${sortOrder}` : ""
      }${filterFields ? `&filterFields=${filterFields}` : ""}${
        filterValue !== undefined ? `&filterValues=${filterValue}` : ""
      }${searchQuery ? `&searchQuery=${searchQuery}` : ""}`
    );
    if (response?.data?.success) {
      return response?.data;
    }
    console.log(response);
  } catch (err) {
    console.log(err);
    toast.error(
      err?.response?.data?.message
        ? err.response?.data?.message
        : "Something went wrong, please try again later"
    );
  }
};
export const getCompanyById = async ({ companyId }) => {
  try {
    const response = await axiosAdmin.get(
      `/admin/getCompanies?pageNum=1&rowsPerPage=30&sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${companyId}`
    );
    if (response?.data?.success) {
      return response?.data?.companies;
    }
  } catch (err) {
    console.log(err);
    toast.error(
      err?.response?.data?.message
        ? err.response?.data?.message
        : "Something went wrong, please try again later"
    );
  }
};

// users
export const getUser = async ({
  sortParam,
  filterUser = "",
  pageConfig = "",
  searchQuery = "",
}) => {
  const { sortFields, sortOrder } = sortParam;
  const { filterFields, filterValue } = filterUser;
  const { pageNum, rowsPerPage } = pageConfig;
  console.log(filterUser);
  try {
    const response = await axiosAdmin.get(
      `/admin/getUsers?${pageNum ? `pageNum=${pageNum}` : ""}${
        rowsPerPage ? `&rowsPerPage=${rowsPerPage}` : ""
      }${sortParam ? `&sortFields=${sortFields}` : ""}${
        sortParam ? `&sortOrders=${sortOrder}` : ""
      }${filterFields ? `&filterFields=${filterFields}` : ""}${
        filterValue ? `&filterValues=${filterValue}` : ""
      }${searchQuery ? `&searchQuery=${searchQuery}` : ""}`
    );
    console.log(response);
    if (response?.data?.success) {
      return response?.data;
    }
  } catch (err) {
    console.log(err);
    toast.error(
      err?.response?.data?.message
        ? err.response?.data?.message
        : "Something went wrong, please try again later"
    );
  }
};

// autherization activities

export const getAutherizationActivity = async () => {
  try {
    const response = await axios.get(`/admin/getAuthActivityGT`);
    if (response?.data?.success) {
      return response?.data.authGT;
    }
  } catch (err) {
    console.log(err);
    toast.error(
      err?.response?.data?.message
        ? err.response?.data?.message
        : "Something went wrong, please try again later"
    );
  }
};
