import { formatTimestamp } from "../../utils/helpers/dateFormat";

function ViolationCard({ violation }) {
  return (
    <div className="rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg p-[1.4rem] flex flex-col gap-[1rem] mx-[1rem]">
      <div className="text-primary-teal font-semibold">
        {violation.violationData?.title}
      </div>
      <div className="text-primary-black text-sm">
        {violation.violationData?.content}
      </div>
      <div className="text-primary-gray-light text-sm">
        {formatTimestamp(violation.openedOn)}
      </div>
    </div>
  );
}

export default ViolationCard;
