import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_dd_1202_7428)">
      <rect
        width={40}
        height={40}
        rx={20}
        transform="matrix(-1 0 0 1 45 3)"
        fill="#237078"
      />
      <rect
        x={-0.5}
        y={0.5}
        width={39}
        height={39}
        rx={19.5}
        transform="matrix(-1 0 0 1 44 3)"
        stroke="#F7F7F7"
      />
      <path
        d="M29 27.2252L26.9455 29L20 23L26.9455 17L29 18.7748L24.109 23L29 27.2252Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_1202_7428"
        x={0}
        y={0}
        width={50}
        height={50}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={2.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.235294 0 0 0 0 0.258824 0 0 0 0 0.341176 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1202_7428"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1202_7428"
          result="effect2_dropShadow_1202_7428"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1202_7428"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SVGComponent;
