import Input from "../Input/Input";

export default function LabelValueField({
  extraClass,
  labelClass,
  valueClass,
  label,
  value,
  isEditable,
  disabled,
  ...rest
}) {
  return (
    <div className={`flex items-center ${extraClass}`}>
      <div className={`text-sm text-primary-teal font-semibold ${labelClass}`}>
        {label} :
      </div>
      <div className={`px-4 flex-1 text-secondary-gray ${valueClass}`}>
        {isEditable ? (
          <div>
            <Input
              extraClass="shadow-none border-none hover:shadow-none"
              inputFieldClass=" py-[0rem]"
              value={value}
              disabled={disabled}
              {...rest}
            />
          </div>
        ) : (
          <div className="text-sm">{value}</div>
        )}
      </div>
    </div>
  );
}
