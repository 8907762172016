import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CustomButtonGroup from "./CustomButtonGroud";

function ReactMultiCarousal({ children }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3.6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      arrows={false}
      renderButtonGroupOutside={true}
      responsive={responsive}
      customButtonGroup={<CustomButtonGroup />}
    >
      {children}
    </Carousel>
  );
}

export default ReactMultiCarousal;
