import axios from "axios";
import { api } from "./url";

const createAxiosInstance = () => {
  function appendErrorToURL(errorCode) {
    if (window.location.search.indexOf("error=") === -1) {
      window.location.href +=
        (window.location.search ? "&" : "?") + "error=" + errorCode;
    }
  }

  let token = JSON.parse(localStorage.getItem("userData"))?.token;

  const axiosInstance = axios.create({
    baseURL: api,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  axiosInstance.interceptors.request.use((req) => {
    token = JSON.parse(localStorage.getItem("userData"))?.token;
    if (token) req.headers.Authorization = `Bearer ${token}`;

    return req;
  });

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (error.response && error.response.status === 403)
        appendErrorToURL(403);

      if (error.response && error.response.status === 401)
        appendErrorToURL(401);

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance();
