import { useContext, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Input from "../../components/Input/Input";
import Pagination from "../../components/Pagination/Pagination";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Table from "../../components/Table/Table";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { letterLibraryCommunityColumn } from "../../constants/TableColumns/Columns";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";

import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import ArchiveIcon from "../../assets/svg/archiveIconWhite";
import ImportIcon from "../../assets/svg/importIcon";

export default function LetterLibraryCommunity() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const [searchParams] = useSearchParams();
  const importFromCompanyAdmin = searchParams.get("importFromCompanyAdmin");
  const dispatch = useDispatch();

  const [letterLibraries, setletterLbraries] = useState([]);
  const [totalLetterLibrary, setTotalLetterLibrary] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLetterLibraries, setSelectedLetterLibraries] = useState([]);
  const [letterLevels, setLetterLevels] = useState([]);
  const [community, setCommunity] = useState({});
  const [filterFields, setFilterFields] = useState({
    filterField: "archieved",
    filterValue: "no",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [isLoadingArchieved, setIsLoadingArchieved] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectHandler = (e, row) => {
    if (e.target.checked)
      setSelectedLetterLibraries((prev) => [
        ...prev,
        {
          id: row._id,
          title: row.title,
          tags: row.tags,
          content: row.content,
          margins: row.margins,
        },
      ]);
    else {
      setSelectedLetterLibraries(
        selectedLetterLibraries.filter((item) => item.id !== row._id)
      );
    }
  };

  const onEditHandler = (row) => {
    navigate(`/community/${id}/letter-template/edit/${row._id}`);
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLetterLevels = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/community/letterLevel?communityId=${id}&archieved=no`
      );

      if (data.success) {
        setLetterLevels(data.letterLevels);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLetterLibrary = async (param) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/api/company/letterTemplate/${param}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }&${
          filterFields.filterField
            ? "filterFields=" +
              filterFields.filterField +
              "&filterValues=" +
              filterFields.filterValue +
              "&"
            : ""
        }`
      );

      if (data.success) {
        setTotalLetterLibrary(data.matchedLetterTemplatesCount);
        setletterLbraries(data.letterTemplates);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addLetterTemplate = async () => {
    try {
      setIsLoadingImport(true);

      const { data } = await axios.post("/api/community/letterTemplate", {
        communityId: id,
        letterTemplates: selectedLetterLibraries.map((letterLibraries) => ({
          title: letterLibraries.title,
          content: letterLibraries.content,
          margins: letterLibraries.margins,
        })),
      });

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/letter-library/${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingImport(false);
    }
  };

  const archieveLetterTemplate = async () => {
    try {
      setIsLoadingArchieved(true);

      const selectedLetterLibrariesId = selectedLetterLibraries.map(
        (letterLibraries) => letterLibraries.id
      );

      const { data } = await axios.delete(
        `/api/community/letterTemplate?letterTemplateIds=${selectedLetterLibrariesId.join(
          ","
        )}&communityId=${id}`
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedLetterLibraries([]);
        getLetterLibrary(`community?communityId=${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchieved(false);
    }
  };

  const unarchievedLetterTemplate = async () => {
    try {
      setIsLoadingArchieved(true);

      const selectedLetterLibrariesId = selectedLetterLibraries.map(
        (letterLibraries) => letterLibraries.id
      );

      const { data } = await axios.patch(
        `/api/community/letterTemplate/unarchieve?letterTemplateIds=${selectedLetterLibrariesId.join(
          ","
        )}&communityId=${id}`,
        {
          archieved: "no",
        }
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedLetterLibraries([]);
        getLetterLibrary(`community?communityId=${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchieved(false);
    }
  };

  useEffect(() => {
    if (importFromCompanyAdmin) getLetterLibrary("company?");
    else getLetterLibrary(`community?communityId=${id}`);
  }, [currentPage, rowsPerPage, importFromCompanyAdmin, filterFields]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      if (importFromCompanyAdmin) getLetterLibrary("company?");
      else getLetterLibrary(`community?communityId=${id}`);
    }
    if (searchQuery.length > 2) {
      if (importFromCompanyAdmin) getLetterLibrary("company?");
      else getLetterLibrary(`community?communityId=${id}`);
    }
  }, [searchQuery, importFromCompanyAdmin]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (id) {
      getCommunity();
      getLetterLevels();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header
        title={
          importFromCompanyAdmin
            ? "Company Master Letter Library"
            : "Letter Library"
        }
        buttonText={community.legalName}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="flex gap-4">
          <div className="grow">
            <Input
              iconClass="absolute  left-4 text-secondary-gray 3xl:w-[24px] 3xl:h-[24px]"
              Icon={SearchIcon}
              placeholder="Search Letter"
              inputFieldClass="placeholder:text-primary-gray-light"
              extraClass="relative w-full py-[0.1rem] rounded-full px-12 border-gray border-[1px]"
              onChange={(e) => onChange(e)}
            />
          </div>
          {!importFromCompanyAdmin && (
            <div className="min-w-fit">
              <Link to={`/community/letter-template/${id}`}>
                <Button
                  Icon={AddIcon}
                  iconClass=" w-[15px] h-[15px]"
                  extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                  innerText="Add New Letter"
                />
              </Link>
            </div>
          )}
        </div>

        <div className="overflow-auto grow px-2 min-h-[6rem] flex flex-col items-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && letterLibraries.length > 0 && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={letterLibraryCommunityColumn(
                selectedLetterLibraries,
                onSelectHandler,
                onEditHandler,
                letterLevels,
                importFromCompanyAdmin,
                letterLibraries,
                setSelectedLetterLibraries
              )}
              tableData={letterLibraries}
            />
          )}
        </div>

        <div>
          {importFromCompanyAdmin ? (
            <Button
              Icon={ImportIcon}
              iconClass=" w-[20px] h-[20px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
              innerText="Import Selected"
              spinnerLight={true}
              isLoading={isLoadingImport}
              onClick={addLetterTemplate}
              disabled={isLoadingImport || selectedLetterLibraries.length === 0}
            />
          ) : (
            <div className="flex gap-[1rem]">
              <Button
                Icon={ArchiveIcon}
                iconClass=" w-[15px] h-[15px]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-xs rounded-full mb-4"
                innerText={
                  filterFields.filterValue === "no"
                    ? "Archive Selected"
                    : "Unarchive Selected"
                }
                spinnerLight={true}
                disabled={
                  isLoadingArchieved || selectedLetterLibraries.length === 0
                }
                isLoading={isLoadingArchieved}
                onClick={() => {
                  if (filterFields.filterValue === "no")
                    archieveLetterTemplate();
                  else if (filterFields.filterValue === "yes")
                    unarchievedLetterTemplate();
                }}
              />
              <div className="flex items-center px-2 pb-4">
                <SwitchInputv2
                  isChecked={filterFields.filterValue === "yes" ? true : false}
                  onChange={(id, value) => {
                    if (value === "add")
                      setFilterFields({
                        filterField: "archieved",
                        filterValue: "yes",
                      });
                    if (value === "remove")
                      setFilterFields({
                        filterField: "archieved",
                        filterValue: "no",
                      });
                  }}
                />
                <div className="text-primary-gray">Show Archive</div>
              </div>
            </div>
          )}
        </div>
        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalLetterLibrary}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
