import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-responsive-modal/styles.css";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import { Formik } from "formik";
import { ClipLoader } from "react-spinners";

import Input from "../Input/Input";
import Button from "../Button/Button";
import axios from "../../utils/helpers/adminAxios";
import Select from "../Input/SelectInput";
import { subscriptionTypeDropdown } from "../../data/dropdownList";
import DatePickerInput from "../Input/DatepickerInput";
import SwitchInput from "../Input/SwitchInput";
import Header from "../Header/Header";
import { formatDateToReadableString } from "../../utils/helpers/dateFormat";
import CropEasy from "../Crop/CropEasy";

import CompanyIcon from "../../assets/svg/companyIcon";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";

export default function AddCompany() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [company, setCompany] = useState();
  const [file, setFile] = useState();
  const [filePreview, setFilePreview] = useState();
  const [cropImagePreview, setCropImagePreview] = useState("");
  const [mediaId, setMediaId] = useState();
  const [open, setOpen] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingUpdateBtn, setIsLoadingUpdateBtn] = useState(false);

  function handleChange(e) {
    if (e.target.files[0]) {
      setFilePreview(URL.createObjectURL(e.target.files[0]));
      setOpenCrop(true);
    }
  }

  const addCompanyAPI = async (values) => {
    const {
      companyName,
      numCommunities,
      subscriptionType,
      adminEmail,
      subscriptionStartDate,
      subscriptionEndDate,
      active,
      sendNewCompanyEmail,
    } = values;

    setIsLoading(true);
    try {
      const payload = {
        companyId: id,
        adminFirstName: values.adminFirstName,
        adminLastName: values.adminLastName,
        companyName: companyName,
        maxNumCommunities: numCommunities,
        subscriptionType: subscriptionType.value,
        subscriptionStartDate: subscriptionStartDate,
        active: `${active}`,
        adminEmail: adminEmail,
        logo: mediaId,
        sendNewCompanyEmail: sendNewCompanyEmail || false,
      };

      if (subscriptionType.value === "annual") {
        payload.subscriptionEndDate = subscriptionEndDate;
      }

      const response = await axios.post("/admin/addCompany", payload);
      if (response?.data?.success) {
        if (mediaId) {
          const ackMediaResponse = await axios.post("/admin/ackMediaUpload", {
            mediaId: mediaId,
            company: response?.data?.company,
          });
          if (ackMediaResponse?.data?.success) {
            if (ackMediaResponse?.data?.message) {
              toast.success(ackMediaResponse?.data?.message);
            }
          }
        }
        toast.success(response.data.message);
        setIsLoading(false);
        navigate("/companies");
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.message
          ? err.response?.data?.message
          : "Something went wrong, please try again later"
      );
      setIsLoading(false);
    }
  };

  const updateCompanyAPI = async (values) => {
    const {
      companyName,
      numCommunities,
      subscriptionType,
      active,
      subscriptionStartDate,
      subscriptionEndDate,
    } = values;

    setIsLoadingUpdateBtn(true);

    try {
      const payload = {
        companyId: id,
        adminFirstName: values.adminFirstName,
        adminLastName: values.adminLastName,
        companyName: companyName,
        maxNumCommunities: numCommunities,
        subscriptionType: subscriptionType.value,
        subscriptionStartDate: subscriptionStartDate,
        active: `${active}`,
        logo: mediaId,
      };

      if (subscriptionType.value === "annual") {
        payload.subscriptionEndDate = subscriptionEndDate;
      }

      const response = await axios.patch("/admin/updateCompany", payload);
      if (response?.data?.success) {
        if (response?.data?.message) {
          toast.success(response?.data?.message);
        }

        if (mediaId) {
          const ackMediaResponse = await axios.post("/admin/ackMediaUpload", {
            mediaId: mediaId,
            company: id,
          });

          if (ackMediaResponse?.data?.success) {
            if (ackMediaResponse?.data?.message) {
              toast.success(ackMediaResponse?.data?.message);
            }
          }
        }

        navigate("/companies");
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err.response?.data?.message
          : "Something went wrong, please try again later"
      );
    } finally {
      setIsLoadingUpdateBtn(false);
    }
  };

  const mediaUploadAPI = async () => {
    try {
      const { data } = await axios.post(`/admin/uploadURL/image`, {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        setMediaId(data.mediaId);
        put(data.s3PostPayload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function put(url) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => {})
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  const deleteCompanyAPI = async (companyId) => {
    setIsLoading(true);

    try {
      const response = await axios.delete(
        `/admin/deleteCompany?companyId=${companyId}`
      );
      if (response?.data?.success) {
        navigate("/companies");

        if (response?.data?.message) {
          toast.success(response?.data?.message);
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message
          ? err.response?.data?.message
          : "Something went wrong, please try again later"
      );
      setIsLoading(false);
      navigate("/companies");
    }
  };

  const getMediaURL = async (media_id) => {
    const { data } = await axios.get(`/admin/mediaURL?mediaId=${media_id}`, {
      mediaId: media_id,
    });
    if (data.success) {
      setCropImagePreview(data.media.s3PresignedURL);
    }
  };

  useEffect(() => {
    if (location.pathname === `/companies/edit/${id}`) {
      const getCompany = async () => {
        setIsLoadingUpdate(true);
        try {
          console.log(id);
          const response = await axios.get(
            `/admin/getCompanies?pageNum=1&rowsPerPage=1&sortFields=communitiesCount,updatedAt&sortOrders=-1,1&filterFields=_id&filterValues=${id}`
          );
          if (response?.data?.success) {
            console.log(response.data.companies[0]);
            setCompany(response.data.companies[0]);

            if (response.data.companies[0].logo) {
              getMediaURL(response.data.companies[0].logo);
            }
          }
        } catch (err) {
          console.log(err);
          toast.error(
            err?.response?.data?.message
              ? err.response?.data?.message
              : "Something went wrong, please try again later"
          );
        } finally {
          setIsLoadingUpdate(false);
        }
      };
      getCompany();
    }
  }, [id]);

  useEffect(() => {
    if (file) {
      mediaUploadAPI();
    }
  }, [file]);

  return (
    <div className="w-full">
      {openCrop && (
        <CropEasy
          {...{
            photoURL: filePreview,
            setPhotoURL: setCropImagePreview,
            openCrop: openCrop,
            setOpenCrop: setOpenCrop,
            setFile: setFile,
            aspect: 3.7,
          }}
        />
      )}
      <Header Icon={CompanyIcon} title={`${id ? "Edit" : "Add"} Company`} />
      <div className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4">
        {isLoadingUpdate && (
          <div className="flex justify-center items-center w-full">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoadingUpdate && (
          <Formik
            initialValues={{
              companyName: `${company ? company.companyName : ""}`,
              numCommunities: `${company ? company.maxNumCommunities : ""}`,
              adminEmail: `${company ? company.adminEmail : ""}`,
              subscriptionType: company
                ? { value: company.subscriptionType }
                : "",
              subscriptionStartDate: `${
                company
                  ? formatDateToReadableString(company.subscriptionStartDate)
                  : ""
              }`,
              subscriptionEndDate: `${
                company?.subscriptionEndDate
                  ? formatDateToReadableString(company.subscriptionEndDate)
                  : ""
              }`,
              companyLogo: `${company ? "" : ""}`,
              active: `${company ? company.active : true}`,
              sendNewCompanyEmail: true,
            }}
            onSubmit={(values) => {
              company ? updateCompanyAPI(values) : addCompanyAPI(values);
            }}
          >
            {(formik) => {
              return (
                <form
                  onSubmit={formik.handleSubmit}
                  className="px-2 w-full h-full flex flex-col gap-4 grow"
                >
                  <div className="flex w-full justify-between space-x-4">
                    <Input
                      label="Company Name"
                      labelClass="text-primary-teal"
                      placeholder="Enter Company Name"
                      inputFieldClass="px-4"
                      required={true}
                      outerClass="w-full"
                      extraClass="border-gray border-[1px] rounded-full"
                      {...formik.getFieldProps("companyName")}
                    />
                    <Input
                      label="# Of Communities"
                      type="number"
                      placeholder="Enter No. of Communities"
                      info="Enter the number of communities for which this company has subscribed. Users will only be permitted to add the number of communities entered and will receive an error when exceeding this number."
                      labelClass="text-primary-teal"
                      required={true}
                      inputFieldClass="px-4"
                      outerClass="w-full"
                      extraClass="border-gray border-[1px] rounded-full"
                      {...formik.getFieldProps("numCommunities")}
                    />
                  </div>
                  {!id && (
                    <div className="flex gap-x-4">
                      <Input
                        label="Company Administrator First Name"
                        labelClass="text-primary-teal"
                        placeholder="Enter Company Administrator First Name"
                        inputFieldClass="px-4"
                        required={true}
                        outerClass="w-full"
                        extraClass="border-gray border-[1px] rounded-full"
                        {...formik.getFieldProps("adminFirstName")}
                      />
                      <Input
                        label="Company Administrator Last Name"
                        labelClass="text-primary-teal"
                        placeholder="Enter Company Administrator Last Name"
                        inputFieldClass="px-4"
                        required={true}
                        outerClass="w-full"
                        extraClass="border-gray border-[1px] rounded-full"
                        {...formik.getFieldProps("adminLastName")}
                      />
                      <Input
                        label="Company Administrator Email"
                        labelClass="text-primary-teal"
                        type="email"
                        required={true}
                        error={formik.touched.email && formik.errors.email}
                        placeholder="Enter Company Administrator Email"
                        inputFieldClass="px-4"
                        outerClass="w-full"
                        extraClass="border-gray border-[1px] focus:shadow-inputShadowActive rounded-full"
                        {...formik.getFieldProps("adminEmail")}
                        info="This user will be the first user granted access to their company dashboard. Additional admins may be added by this user."
                      />
                    </div>
                  )}
                  <div className="flex w-full space-x-4">
                    <Input
                      label="Company Logo"
                      labelClass="text-primary-teal"
                      type="file"
                      inputFieldClass="px-4 placeholder-red-300 file:border-none file:bg-transparent file:text-primary-teal file:cursor-pointer "
                      outerClass="w-full"
                      extraClass="border-gray border-[1px] rounded-full"
                      onChange={handleChange}
                      info="info"
                    />
                    <Select
                      isStatic={true}
                      label="Subscription Type"
                      labelClass="text-primary-teal"
                      type="select"
                      required={true}
                      placeholder="Select"
                      dropdownData={subscriptionTypeDropdown}
                      inputFieldClass="px-4"
                      outerClass="w-full"
                      extraClass="border-gray border-[1px] rounded-full"
                      id="subscriptionType"
                      fieldName="subscriptionType"
                      value={formik.values["subscriptionType"].value}
                      handleChange={formik.setFieldValue}
                      info={`Subscription types.
1. Annual: For Annual subscribers, their account will only be active for a one-year period. A start and end date must be entered. These company accounts are required to pre-pay. When the end date comes due, the account will automatically be marked “Inactive” and no users will be able to gain access until the date is changed and the account re-activated. When inactive, all data will be retained, so the account can be easily reinstated.

2. Monthly: Monthly subscribers will not have an end date, as their payment will be recurring. These company accounts must be manually mark “Inactive”, if/when necessary.

3. Management: Management subscribers will not have an end date, as the account is included with their management contract. These company accounts must be manually mark “Inactive”, if/when necessary.`}
                    />

                    <DatePickerInput
                      label="Subscription Start"
                      labelClass="text-primary-teal"
                      placeholder="Start Date"
                      required={true}
                      type="text"
                      handleChange={formik.setFieldValue}
                      value={formik.values["subscriptionStartDate"]}
                      inputFieldClass="px-4"
                      id="subscriptionStartDate"
                      outerClass="w-full"
                      extraClass="border-gray border-[1px] rounded-full"
                    />
                    {formik.values.subscriptionType?.value === "annual" && (
                      <DatePickerInput
                        label="Subscription End"
                        labelClass="text-primary-teal"
                        placeholder="End Date"
                        required={true}
                        type="text"
                        handleChange={formik.setFieldValue}
                        value={formik.values["subscriptionEndDate"]}
                        inputFieldClass="px-4"
                        id="subscriptionEndDate"
                        outerClass="w-full"
                        extraClass="border-gray border-[1px] rounded-full"
                      />
                    )}
                  </div>
                  <div className="flex flex-col justify-between space-y-8">
                    <div className="space-y-2">
                      <div className="text-secondary-gray-light">
                        Preview Image
                      </div>
                      <div className="w-[28rem] h-[8rem] border-[1px] border-gray rounded-xl shadow-inputShadow">
                        <img
                          src={cropImagePreview || CompanyDashboardDefault}
                          className="w-full h-full object-fit rounded-xl"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full gap-8">
                    <SwitchInput
                      labelClass="text-primary-green"
                      label="Active"
                      description="Company Status"
                      id="active"
                      handleChange={formik.setFieldValue}
                      value={formik.values["active"] === "false" ? false : true}
                    />
                    {!id && (
                      <SwitchInput
                        label="Send New Company Email to Company Administrator"
                        description="To prevent sending an email, toggle OFF."
                        id="sendNewCompanyEmail"
                        handleChange={formik.setFieldValue}
                        value={formik.values["sendNewCompanyEmail"]}
                      />
                    )}
                  </div>

                  <div className="flex mt-auto text-sm">
                    <div className="w-full">
                      {company && (
                        <Button
                          onClick={() => {
                            setOpen(true);
                          }}
                          innerText="Delete Company"
                          isLoading={isLoading}
                          disabled={isLoading}
                          extraClass="shadow-button border-[1px] border-primary-red bg-primary-white rounded-full px-4 py-2 uppercase text-primary-red"
                        />
                      )}
                    </div>
                    <div className="flex justify-end space-x-4">
                      <Button
                        innerText={`Cancel`}
                        extraClass={`shadow-button bg-primary-white border-[1px] border-primary-red py-2 px-4 rounded-full text-sm text-primary-red`}
                        onClick={() => navigate("/companies")}
                      />
                      {company ? (
                        <Button
                          type="submit"
                          innerText={"Save Changes"}
                          isLoading={isLoadingUpdateBtn}
                          disabled={isLoadingUpdateBtn}
                          extraClass="shadow-button bg-primary-teal rounded-full text-sm uppercase text-primary-white py-2 px-4 min-w-fit"
                          spinnerLight={true}
                        />
                      ) : (
                        <Button
                          type="submit"
                          innerText={"Add Company"}
                          isLoading={isLoading}
                          disabled={isLoading}
                          extraClass="shadow-button bg-primary-teal rounded-full text-sm uppercase text-primary-white py-2 px-4 min-w-fit"
                          spinnerLight={true}
                        />
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
        <Modal
          closeOnOverlayClick={true}
          showCloseIcon={false}
          blockScroll={true}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          center
        >
          <div className="shadow-inputShadow rounded-xl p-4 flex flex-col">
            <div className="text-primary-teal text-lg">
              Are you sure you wish to delete this company? Deleting a company
              will permanently delete all users, communities and properties
              under the company. If that is not the intention, please mark
              'Inactive' to archive instead.
            </div>
            <div className="flex justify-end w-full text-sm gap-4">
              <Button
                onClick={() => setOpen(false)}
                innerText="Cancel"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-white rounded-full py-2 px-4 text-primary-teal"
              />
              <Button
                onClick={() => {
                  deleteCompanyAPI(id);
                }}
                isLoading={isLoading}
                disabled={isLoading}
                innerText="Delete Company"
                extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
