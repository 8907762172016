import React from "react";

function PropertiesIcon({className,...rest}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 24 24"
      className={`${className}`}
      {...rest}
    >
      <path
        fill="currentColor"
        d="M4.81 18h2.714v-6h5.428v6h2.715V9l-5.429-4.5L4.81 9v9zM3 20V8l7.238-6 7.238 6v12h-6.333v-6h-1.81v6H3z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.523 20v2H22V10L14.76 4l-1.055.875L17.476 8v.75L20.19 11v9H7.523z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default PropertiesIcon;